import { createAction, props } from '@ngrx/store';

import { OrdinationProperty } from '../../models/objects/ordination-property.model';
import { BillOrdinationTemplateRequest } from '../../models/requests/bills-ordination-request';

export const loadPropertyRequest = createAction(
  '[Bills Ordination] Load Property Request',
  props<{ propery_id: number }>(),
);
export const loadPropertySuccess = createAction(
  '[Bills Ordination] Load Property Success',
  props<{ property: OrdinationProperty }>(),
);
export const loadPropertyFailure = createAction(
  '[Bills Ordination] Load Property Failure',
  props<{ error: any }>(),
);

export const saveOrdinationRequest = createAction(
  '[Bills Ordination] Save Ordination Request',
  props<{
    reservationAccommodationId: number;
    bill_id: number[];
    ordinationMedia: File;
    onSuccess?: () => void;
  }>(),
);
export const saveOrdinationSuccess = createAction(
  '[Bills Ordination] Save Ordination Success',
);
export const saveOrdinationFailure = createAction(
  '[Bills Ordination] Save Ordination Failure',
  props<{ error: any }>(),
);

export const loadTemplateRequest = createAction(
  '[Bills Ordination] Load Template Request',
  props<{ payload: BillOrdinationTemplateRequest }>(),
);

export const loadTemplateSuccess = createAction(
  '[Bills Ordination] Load Template Success',
  props<{ template: string }>(),
);

export const loadTemplateFailure = createAction(
  '[Bills Ordination] Load Template Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Bills Ordination] Reset State');
