import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICoreState,
  selectAllGroups,
  selectAllProperties,
  selectSelectedGroup,
  selectSelectedProperty,
} from '@app/core/+state/core.reducer';
import { Store } from '@ngrx/store';
import { SubSink } from 'subsink';

@Component({
  selector: 'by-after-login-modal',
  templateUrl: './after-login-modal.component.html',
  styleUrls: ['./after-login-modal.component.scss'],
})
export class AfterLoginModalComponent implements OnInit, OnDestroy {
  allGroups$ = this._store.select(selectAllGroups);
  selectedGroup$ = this._store.select(selectSelectedGroup);
  allProperties$ = this._store.select(selectAllProperties);
  selectedProperty$ = this._store.select(selectSelectedProperty);

  groupId: number = null;

  propertyId: number = null;

  private subs = new SubSink();

  constructor(private _store: Store<ICoreState>) {}

  ngOnInit() {
    this.subs.add(
      this.selectedProperty$.subscribe(
        (property) => (this.propertyId = property && property.id),
      ),
    );

    this.subs.add(
      this.selectedGroup$.subscribe(
        (group) => (this.groupId = group && group.id),
      ),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
