<div
  nz-row
  [nzGutter]="[12, 24]"
  nzJustify="center"
  class="by-pb-20 by-pl-20 by-pr-20"
>
  <ng-container *ngFor="let language of languages">
    <div
      nz-col
      nzXs="8"
      *ngIf="['it', 'en'] | firstOrDefault: language.iso_code"
      class="by-flex by-flex-direction--column by-align-items--center"
      [class.not-selected-lang]="language.iso_code !== currentSelectLanguage"
      [class.locked]="language.iso_code === currentSelectLanguage"
      (click)="changeLanguage.emit(language.iso_code)"
    >
      <span
        class="flag-icon flag-icon-{{
          language.iso_3361_country_code
        }} by-fs-40 flag-icon-squared language-btn"
      ></span>
    </div>
  </ng-container>
</div>
