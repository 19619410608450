import { get } from 'lodash';
import moment from 'moment';

import { PriceQuotation } from '../../models';

import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

const buildOptionAccommodation = (
  accommodations,
): { accommodations; totalOption } => {
  let totalOption = 0;
  const newAccommodations = accommodations.map((accommodation) => {
    const { accommodation: _accommodation } = accommodation;
    const { arrival_date, departure_date, price, addons } = _accommodation;

    const addons_total = (addons || []).reduce(
      (totalAddons, { total }) => (totalAddons += total),
      0,
    );

    totalOption = totalOption + price + addons_total;

    return {
      ...accommodation,
      accommodation: {
        ..._accommodation,
        addons_total,
        number_nights: moment(departure_date, 'YYYY-MM-DD')
          .startOf('day')
          .diff(
            moment(arrival_date, 'YYYY-MM-DD').startOf('day'),
            'day',
            false,
          ),
      },
    };
  });
  return { totalOption, accommodations: newAccommodations };
};
export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.CONFIRM_OPTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.CONFIRM_OPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case ActionTypes.CONFIRM_OPTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case ActionTypes.LOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.LOAD_SUCCESS:
      const { item } = action.payload;
      const { options: _option } = item;

      const priceQuotation: PriceQuotation = {
        ...item,
        ...get(item, 'notes').reduce(
          (notes, { related_subtype, note }) =>
            (notes = { ...notes, [related_subtype]: note }),
          {},
        ),
        options: _option.map((option) => {
          const { accommodations: oldAccommodations } = option;
          const { totalOption, accommodations } =
            buildOptionAccommodation(oldAccommodations);

          return {
            ...option,
            accommodations,
            totalOption,
          };
        }),
      };
      return {
        ...state,
        item: priceQuotation,
        isLoading: false,
        error: null,
      };

    case ActionTypes.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case ActionTypes.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case ActionTypes.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case ActionTypes.UPDATE_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.UPDATE_CART_SUCCESS:
      const updateOptions = state.item.options.map((option) =>
        option.id === action.payload.optionId
          ? { ...option, ...action.payload.cart }
          : option,
      );
      return {
        ...state,
        item: { ...state.item, options: updateOptions },
        isLoading: false,
        error: null,
      };

    case ActionTypes.UPDATE_CART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case ActionTypes.UPDATE_CART_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.UPDATE_CART_ITEM_SUCCESS: {
      const { cartId, cartItemId, cartItem } = action.payload;
      return {
        ...state,
        item: {
          ...state.item,
          options: state.item.options.map((option) => {
            if (option.id === cartId) {
              return {
                ...option,
                accommodations: option.accommodations.map((accommodation) => {
                  if (accommodation.id === cartItemId) {
                    return {
                      ...accommodation,
                      accommodation: { ...cartItem },
                    };
                  }
                  return accommodation;
                }),
              };
            }
            return option;
          }),
        },
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.UPDATE_CART_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case ActionTypes.DELETE_CART_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.DELETE_CART_ITEM_SUCCESS: {
      const { cartId, cartItemId } = action.payload;
      return {
        ...state,
        item: {
          ...state.item,
          options: state.item.options.map((option) => {
            if (option.id === cartId) {
              option = {
                ...option,
                accommodations: option.accommodations.filter(
                  ({ id }) => id !== cartItemId,
                ),
              };
            }
            return option;
          }),
        },
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.DELETE_CART_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case ActionTypes.ADD_CART_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.ADD_CART_ITEM_SUCCESS: {
      const { cartItems } = action.payload;

      const res = {
        ...state,
        item: {
          ...state.item,
          options: state.item.options.map((option) => {
            const newCartItemsOfCurrentCart = cartItems.filter(
              ({ reservation_quote_option_id }) =>
                +option.id === +reservation_quote_option_id,
            );

            return {
              ...option,
              accommodations: [
                ...option.accommodations,
                ...newCartItemsOfCurrentCart,
              ],
            };
          }),
        },
        isLoading: false,
        error: null,
      };
      return res;
    }

    case ActionTypes.ADD_CART_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case ActionTypes.ADD_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.ADD_CART_SUCCESS: {
      const { cart } = action.payload;
      const { id, label, number, reservation_quote_id } = cart;
      return {
        ...state,
        isLoading: false,
        error: null,
        item: {
          ...state.item,
          options: [
            ...state.item.options,
            {
              id,
              label,
              number,
              reservation_quote_id,
              accommodations: [],
            },
          ],
        },
      };
    }

    case ActionTypes.ADD_CART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case ActionTypes.DELETE_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.DELETE_CART_SUCCESS: {
      const { cartId } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        item: {
          ...state.item,
          options: state.item.options.filter(({ id }) => id !== cartId),
        },
      };
    }

    case ActionTypes.DELETE_CART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case ActionTypes.DELETE_MEDIA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.DELETE_MEDIA_SUCCESS: {
      const { attachmentId } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        item: {
          ...state.item,
          attachments: get(state, ['item', 'attachments'], []).filter(
            (media) => media.id !== attachmentId,
          ),
        },
      };
    }

    case ActionTypes.DELETE_MEDIA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case ActionTypes.UPLOAD_MEDIA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.UPLOAD_MEDIA_SUCCESS: {
      const { file } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        item: {
          ...state.item,
          attachments: [...get(state, ['item', 'attachments']), file],
        },
      };
    }

    case ActionTypes.UPLOAD_MEDIA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    default: {
      return state;
    }
  }
}
