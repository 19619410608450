import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { PropertySubscriptionInfo } from '../../../models';

@Component({
  selector: 'by-app-shell-properties-account-expired',
  templateUrl: './app-shell-properties-account-expired.component.html',
  styleUrls: ['./app-shell-properties-account-expired.component.scss'],
})
export class AppShellPropertiesAccountExpiredComponent {
  @Input() propertiesSubscriptionInfoWarnings: PropertySubscriptionInfo[] = [];

  showMoreWarnings = false;

  constructor(
    private modal: NzModalService,
    private translateService: TranslateService,
  ) {}

  onInfo(): void {
    this.modal.info({
      nzTitle: this.translateService.instant('how_to_renew'),
      nzContent: this.translateService.instant('how_to_renew_description'),
    });
  }
}
