import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';

import { UiModule } from '../../ui/ui.module';

import { PriceListPeriodFormComponent } from './price-list-period-form.component';

@NgModule({
  declarations: [PriceListPeriodFormComponent],
  imports: [
    CommonModule,
    UiModule,
    TranslateModule,
    ReactiveFormsModule,
    NgPipesModule,
  ],
  exports: [PriceListPeriodFormComponent],
})
export class PriceListPeriodFormModule {}
