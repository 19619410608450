<by-page-header [title]="addon?.name"></by-page-header>

<nz-alert
  class="by-mb-2"
  *ngFor="let warning of warnings"
  nzType="warning"
  [nzMessage]="warning"
  nzShowIcon
></nz-alert>

<span>{{ 'discount_message' | translate | upperfirst }}</span>
