import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@core/helpers/params-generator';
import { Observable } from 'rxjs';

import { IMoveReservationRequest } from '../models/api/reservation/move-reservation-request.model';
import { INewReservation } from '../models/api/reservation/reservaion.model';
import { IResponse } from '../models/response.model';

import { ISplitReservation } from './../models/api/reservation/reservaion.model';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  constructor(private _http: HttpClient) {}

  createNewReservation(data: INewReservation): Observable<IResponse> {
    return this._http.post('reservation', data) as Observable<IResponse>;
  }

  splitReservation({
    reservationsOnSwap,
    ...data
  }: ISplitReservation): Observable<IResponse> {
    return this._http.post('tableau/split_reservation_accommodation', {
      ...data,
      reservations_on_swap: reservationsOnSwap,
    }) as Observable<IResponse>;
  }

  moveReservation(data: IMoveReservationRequest): Observable<IResponse> {
    return this._http.post(
      `reservation/${data.reservation_id}/move_accommodation/${data.reservation_accommodation_id}`,
      data,
    ) as Observable<IResponse>;
  }

  loadReservations(
    propertiesIds: number[],
    // value: string,
    // field: 'customer' | 'guest' | 'room_label',
    date: string,
    noPagination = false,
    status?: string[],
  ): Observable<IResponse> {
    let url = `reservations?${generateParamArray(
      'property_id',
      propertiesIds,
    )}&with_details=1&arrival_date=${date}&arrival_date_operator=lte&departure_date=${date}&departure_date_operator=gte`;
    if (noPagination) {
      url += '&no_pagination=1';
    }
    if (status && status.length) {
      url += `&${generateParamArray('status', status)}`;
    }
    return this._http.get(url) as Observable<IResponse>;
  }

  loadReservationDetails(
    reservationAccommodationId: number,
  ): Observable<IResponse> {
    return this._http.get(
      `reservation/${reservationAccommodationId}`,
    ) as Observable<IResponse>;
  }

  // loadReservations(
  //   propertiesIds: number[],
  //   value: string,
  //   field: 'customer' | 'guest' | 'room_label',
  // ): Observable<IResponse> {
  //   const toDay = moment(new Date()).format('YYYY-MM-DD');
  //   return this._http.get(
  //     `reservations?${generateParamArray(
  //       'property_id',
  //       propertiesIds,
  //     )}&${field}=${value}&with_details=true`,
  //   ) as Observable<IResponse>;
  // }
}
