import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { UiModule } from '../../ui/ui.module';

import { ArrivalsDeparturesProgressComponent } from './arrivals-departures-progress.component';

@NgModule({
  declarations: [ArrivalsDeparturesProgressComponent],
  imports: [CommonModule, UiModule, TranslateModule, NgPipesModule],
  exports: [ArrivalsDeparturesProgressComponent],
})
export class ArrivalsDeparturesProgressModule {}
