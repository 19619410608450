import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IResponse } from '../models/response.model';

@Injectable({
  providedIn: 'root',
})
export class TemplateEmailService {
  constructor(private _http: HttpClient) {}

  loadEmailTemplateCustom(propertyId: number): Observable<IResponse> {
    return this._http.get(
      `property/${propertyId}/templates_email/custom`,
    ) as Observable<IResponse>;
  }
}
