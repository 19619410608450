import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { first, get, groupBy, omit } from 'lodash';
import moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import { requiredIfValidator } from '../../../../core/validators/required-if.validators';
import { BillFormHelpers } from '../../../../helpers/bill-form-helpers';
import {
  showChildrenRangesConditionMapConstant,
  showPeriodConditionMapConstant,
  showQuantityAndRateConditionMapConstant,
} from '../../../../helpers/bills-helpers';
import { floatRound } from '../../../../helpers/float-round';
import {
  AddBillModalAccommodation,
  AddonEstimate,
  BillDepartment,
  ChildrenRange,
  EstimateAddonPriceRequest,
  PlaceVatQuote,
} from '../../../../models';
import { AddBillModalAddon } from '../../../../models/objects/add-bill-modal-addon';

const OMITTED_FORM_FIELDS = [
  'customer_id',
  'customer_buyer_id',
  'children',
  'dates',
  'date',
];

@Component({
  selector: 'by-add-bill-modal-form',
  templateUrl: './add-bill-modal-form.component.html',
  styleUrls: ['./add-bill-modal-form.component.scss'],
})
export class AddBillModalFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  show = false;

  @Input()
  addon: AddBillModalAddon;

  @Input()
  selectedReservationAccommodation: AddBillModalAccommodation;

  @Input()
  estimate: AddonEstimate;

  @Input()
  currencySymbol: string;

  @Input()
  childrenRanges: ChildrenRange[] = [];

  @Input()
  vatQuotes: PlaceVatQuote[] = [];

  @Input()
  billsDepartments: Record<number, BillDepartment[]>;

  @Output()
  estimateAddon = new EventEmitter<EstimateAddonPriceRequest>();

  showPeriodConditionMapConstant = showPeriodConditionMapConstant;
  showQuantityAndRateConditionMapConstant =
    showQuantityAndRateConditionMapConstant;
  showChildrenRangesConditionMapConstant =
    showChildrenRangesConditionMapConstant;

  form = this.formBuilder.group({
    quantity: [null, [Validators.min(1)]],
    customer_id: [null, [Validators.required]],
    customer_buyer_id: [null, [Validators.required]],
    unit_price: [null, [Validators.required]],
    total: [null, [Validators.required]],
    discount_value: [null],
    discount_type_id: [null],
    vat_quote_id: [null, [Validators.required]],
    bill_department_id: [null],
    adults: [null],
    children: [null],
    children_ranges: this.formBuilder.array([]),
    dates: [[null, null]],
    date: [null],
    override: [null, requiredIfValidator(() => !this.addon?.addon_id)],
    deposit: [false],
    all_inclusive_tax_document: [false],
  });

  private subs = new SubSink();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dateFormatter: DateFormatterService,
  ) {}

  disableDates = (date: Date) => {
    return (
      moment(date).isBefore(
        this.selectedReservationAccommodation?.arrival_date,
        'days',
      ) ||
      moment(date).isAfter(
        moment(this.selectedReservationAccommodation?.departure_date),
        'days',
      )
    );
  };

  currencyFormatter = (value: string) =>
    `${this.currencySymbol || ''} ${floatRound(+value || 0)}`;

  customerIsInGuestsArray = (customerId: number) => {
    return !!(this.selectedReservationAccommodation?.guests || []).find(
      ({ id }) => id === customerId,
    );
  };

  customerOptionsClass = (customerId: number) => {
    return this.customerIsInGuestsArray(customerId)
      ? 'color--azure bolder'
      : '';
  };

  customerOptionsSort = ({ id: customerIdA }, { id: customerIdB }) => {
    const aIsInArray = this.customerIsInGuestsArray(customerIdA);
    const bIsInArray = this.customerIsInGuestsArray(customerIdB);

    if (aIsInArray && bIsInArray) {
      return 0;
    }

    if (!aIsInArray && !bIsInArray) {
      return 0;
    }

    if (aIsInArray && !bIsInArray) {
      return -1;
    }

    if (!aIsInArray && bIsInArray) {
      return 1;
    }
  };

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const { addon, estimate, show } = changes;

    if (addon && this.addon) {
      this.setForm();
    }

    if (estimate && this.estimate) {
      this.applyEstimate();
    }

    if (show && this.show) {
      this.listenFormChanges();
      this.listenChildrenNumberControlChanges();
      this.listenForEstimateRequest();
    }

    if (show && !this.show) {
      this.subs.unsubscribe();
      this.setForm();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  get childrenRangesControl(): UntypedFormArray {
    return this.form.get('children_ranges') as UntypedFormArray;
  }

  get adultsColSize(): number {
    return this.childrenRanges?.length ? 12 : 24;
  }

  get quantityIsDisabled(): boolean {
    return this.addon.price_type === 'xbook';
  }

  get isPercentageDiscount(): boolean {
    return this.form.value.discount_type_id === 5;
  }

  get totalPriceWithoutDiscount(): number {
    return floatRound(this.form.value.unit_price * this.form.value.quantity);
  }

  getEstimatePayload(formValue = this.form.value): EstimateAddonPriceRequest {
    const { quantity, adults, children_ranges, dates } = formValue;

    return {
      quantity: this.showQuantityAndRateConditionMapConstant[
        this.addon?.price_type
      ]
        ? quantity
        : 1,
      adults,
      children_ranges: children_ranges.map(
        ({ property_has_children_range_id, qty }) => ({
          property_has_children_range_id: property_has_children_range_id,
          quantity: qty,
        }),
      ),
      date_ranges: [
        {
          from: this.dateFormatter.toServerFormat(dates[0]),
          to: this.dateFormatter.toServerFormat(dates[1]),
        },
      ],
      addon_id: this.addon.addon_id,
      accommodation_id: this.selectedReservationAccommodation.accommodation_id,
    };
  }

  private setForm() {
    if (!this.addon) {
      return;
    }

    this.form.patchValue(
      {
        ...this.addon,
        dates: [this.addon.periods[0].date_from, this.addon.periods[0].date_to],
        date: this.addon.date,
        customer_id: this.addon.customer.id,
        customer_buyer_id: this.addon.buyer.id,
        children: this.addon.children_ranges.length,
      },
      { emitEvent: false },
    );

    this.childrenRangesControl.clear();
    this.addon.children_ranges.forEach(
      ({ property_has_children_range_id, qty }) => {
        const form = this.formBuilder.group({
          property_has_children_range_id,
          qty,
        });
        this.childrenRangesControl.push(form);
      },
    );
  }

  get value(): AddBillModalAddon {
    const formValue = this.form.value;

    const buyer = this.selectedReservationAccommodation.guests.find(
      ({ id }) => id === formValue.customer_buyer_id,
    );

    let periods = [
      { date_from: formValue.dates[0], date_to: formValue.dates[1] },
    ];
    if (!this.showPeriodConditionMapConstant[this.addon.price_type]) {
      periods = [{ date_from: formValue.date, date_to: formValue.date }];
    }

    const children_ranges = Object.values(
      groupBy(formValue.children_ranges, 'property_has_children_range_id'),
    ).map((childrenRangesWithSameId) => ({
      ...childrenRangesWithSameId[0],
      qty: childrenRangesWithSameId.length,
    }));

    return {
      ...this.addon,
      ...omit(formValue, ...OMITTED_FORM_FIELDS),
      total: this.totalPriceWithoutDiscount,
      children_ranges,
      periods,
      buyer: { id: buyer.id, name: buyer.name },
      customer: { id: formValue.customer_id, name: '' },
    };
  }

  private applyEstimate() {
    const { discount_value, discount_type_id } = this.form.value;
    this.form.patchValue(
      {
        ...this.estimate,
        total: BillFormHelpers.getTotalPrice(
          this.estimate.unit_price,
          this.estimate.quantity,
          discount_type_id,
          discount_value,
        ),
      },
      { emitEvent: false },
    );
  }

  private listenFormChanges() {
    this.subs.add(
      this.form.get('quantity').valueChanges.subscribe((quantity) => {
        const { unit_price, discount_value, discount_type_id } =
          this.form.value;

        this.form.patchValue(
          {
            total: BillFormHelpers.getTotalPrice(
              unit_price,
              quantity,
              discount_type_id,
              discount_value,
            ),
          },
          { emitEvent: false },
        );
      }),
    );

    this.subs.add(
      this.form.get('unit_price').valueChanges.subscribe((unitPrice) => {
        const { quantity, discount_value, discount_type_id } = this.form.value;

        this.form.patchValue(
          {
            total: BillFormHelpers.getTotalPrice(
              unitPrice,
              quantity,
              discount_type_id,
              discount_value,
            ),
          },
          { emitEvent: false },
        );
      }),
    );

    this.subs.add(
      this.form
        .get('discount_value')
        .valueChanges.subscribe((discountValue) => {
          const { quantity, unit_price, discount_type_id } = this.form.value;

          this.form.patchValue(
            {
              total: BillFormHelpers.getTotalPrice(
                unit_price,
                quantity,
                discount_type_id,
                discountValue,
              ),
            },
            { emitEvent: false },
          );
        }),
    );

    this.subs.add(
      this.form.get('discount_type_id').valueChanges.subscribe(() => {
        this.form.patchValue({ discount_value: 0 });
      }),
    );

    this.subs.add(
      this.form.get('total').valueChanges.subscribe((total) => {
        const { quantity, discount_value, discount_type_id } = this.form.value;
        this.form.patchValue(
          {
            unit_price: BillFormHelpers.getUnitPrice(
              total,
              quantity,
              discount_type_id,
              discount_value,
            ),
          },
          { emitEvent: false },
        );
      }),
    );

    this.subs.add(
      this.form.get('vat_quote_id').valueChanges.subscribe((vatQuoteId) => {
        const billsDepartments = get(this.billsDepartments, vatQuoteId);

        this.form.patchValue(
          {
            bill_department_id: first(billsDepartments)?.id || null,
          },
          { emitEvent: false },
        );
      }),
    );
  }

  private listenChildrenNumberControlChanges() {
    this.subs.add(
      this.form.get('children').valueChanges.subscribe((childrenNumber) => {
        while (childrenNumber < this.childrenRangesControl.length) {
          this.childrenRangesControl.removeAt(
            this.childrenRangesControl.length - 1,
          );
        }

        while (childrenNumber > this.childrenRangesControl.length) {
          this.childrenRangesControl.push(
            this.formBuilder.group({
              property_has_children_range_id: this.childrenRanges[0].id,
              qty: 1,
            }),
          );
        }
      }),
    );
  }

  private listenForEstimateRequest() {
    this.subs.add(
      this.form
        .get('adults')
        .valueChanges.subscribe((adults) =>
          this.estimateAddon.emit(
            this.getEstimatePayload({ ...this.form.value, adults }),
          ),
        ),
    );

    this.subs.add(
      this.childrenRangesControl.valueChanges
        .pipe(debounceTime(100))
        .subscribe((children_ranges) => {
          this.estimateAddon.emit(
            this.getEstimatePayload({ ...this.form.value, children_ranges }),
          );
        }),
    );

    this.subs.add(
      this.form
        .get('dates')
        .valueChanges.subscribe((dates) =>
          this.estimateAddon.emit(
            this.getEstimatePayload({ ...this.form.value, dates }),
          ),
        ),
    );
  }
}
