import { createAction, props } from '@ngrx/store';

import {
  AddBillModalAccommodation,
  AddBillModalAccommodationGuest,
  BillDepartment,
  ChildrenRange,
  Dictionary,
  IBillsCategory,
  PlaceVatQuote,
  Reservation,
  VatQuotesSettings,
} from '../../models';
import { AddBillModalAddon } from '../../models/objects/add-bill-modal-addon';
import { AddBillModalAvailableAddon } from '../../models/objects/add-bill-modal-available-addon';
import { AddBillModalConfig } from '../../models/objects/add-bill-modal.config';
import { EditBillsRequest } from '../../models/requests/edit-bills.request';
import { PayingCustomer } from '../../models/responses/paying-customer.response';

export const loadReservationsRequest = createAction(
  '[Add Bill Modal] Load Reservations Request',
);
export const loadReservationsSuccess = createAction(
  '[Add Bill Modal] Load Reservations Success',
  props<{ reservations: Reservation[] }>(),
);
export const loadReservationsFailure = createAction(
  '[Add Bill Modal] Load Reservations Failure',
  props<{ error: any }>(),
);

export const loadReservationRequest = createAction(
  '[Add Bill Modal] Load Reservation Request',
  props<{
    reservationId: number;
    autoSelectedReservationAccommodationId?: number;
  }>(),
);
export const loadReservationSuccess = createAction(
  '[Add Bill Modal] Load Reservation Success',
  props<{
    reservation: Reservation;
    autoSelectedReservationAccommodationId?: number;
  }>(),
);
export const loadReservationFailure = createAction(
  '[Add Bill Modal] Load Reservation Failure',
  props<{ error: any }>(),
);

export const loadAvailableAddonsRequest = createAction(
  '[Add Bill Modal] Load Available Addons Request',
  props<{
    reservationAccommodationId: number;
    customerId?: number;
  }>(),
);
export const loadAvailableAddonsSuccess = createAction(
  '[Add Bill Modal] Load Available Addons Success',
  props<{
    availableAddons: AddBillModalAvailableAddon[];
  }>(),
);
export const loadAvailableAddonsFailure = createAction(
  '[Add Bill Modal] Load Available Addons Failure',
  props<{ error: any }>(),
);

export const loadAccommodationBillsRequest = createAction(
  '[Add Bill Modal] Load Accommodation Bills Request',
  props<{
    reservationId: number;
    reservationAccommodationsId: number[];
  }>(),
);
export const loadAccommodationBillsSuccess = createAction(
  '[Add Bill Modal] Load Accommodation Bills Success',
  props<{
    accommodationBills: IBillsCategory;
  }>(),
);
export const loadAccommodationBillsFailure = createAction(
  '[Add Bill Modal] Load Accommodation Bills Failure',
  props<{ error: any }>(),
);

export const loadVatQuotesSettingsRequest = createAction(
  '[Add Bill Modal] Load Vat Quotes Settings Request',
  props<{ propertiesIds: number[] }>(),
);
export const loadVatQuotesSettingsSuccess = createAction(
  '[Add Bill Modal] Load Vat Quotes Settings Success',
  props<{ vatQuotesSettings: Dictionary<VatQuotesSettings> }>(),
);
export const loadVatQuotesSettingsFailure = createAction(
  '[Add Bill Modal] Load Vat Quotes Settings Failure',
  props<{ error: any }>(),
);

export const loadPlaceVatQuotesRequest = createAction(
  '[Add Bill Modal] Load Place Vat Quotes Request',
  props<{ place_id: number; pagination: boolean }>(),
);
export const loadPlaceVatQuotesSuccess = createAction(
  '[Add Bill Modal] Load Place Vat Quotes Success',
  props<{ placeVatQuotes: PlaceVatQuote[] }>(),
);
export const loadPlaceVatQuotesFailure = createAction(
  '[Add Bill Modal] Load Place Vat Quotes Failure',
  props<{ error: any }>(),
);

export const loadBillsDepartmentsRequest = createAction(
  '[Add Bill Modal] Load Bills Departments Request',
  props<{ propertiesIds: number[] }>(),
);
export const loadBillsDepartmentsSuccess = createAction(
  '[Add Bill Modal] Load Bills Departments Success',
  props<{ billsDepartments: BillDepartment[] }>(),
);
export const loadBillsDepartmentsFailure = createAction(
  '[Add Bill Modal] Load Bills Departments Failure',
  props<{ error: any }>(),
);

export const loadChildrenRangesRequest = createAction(
  '[Add Bill Modal] Load Children Ranges Request',
  props<{ propertiesIds: number[] }>(),
);
export const loadChildrenRangesSuccess = createAction(
  '[Add Bill Modal] Load Children Ranges Success',
  props<{ childrenRanges: ChildrenRange[] }>(),
);
export const loadChildrenRangesFailure = createAction(
  '[Add Bill Modal] Load Children Ranges Failure',
  props<{ error: any }>(),
);

export const verifyAddonRequest = createAction(
  '[Add Bill Modal] Verify Addon Request',
  props<{ addon: AddBillModalAddon }>(),
);
export const verifyAddonSuccess = createAction(
  '[Add Bill Modal] Verify Addon Success',
);
export const verifyAddonFailure = createAction(
  '[Add Bill Modal] Verify Addon Failure',
  props<{ error: any }>(),
);

export const createAddonRequest = createAction(
  '[Add Bill Modal] Create Addon Request',
  props<{ addon: AddBillModalAddon; onSuccess?: () => any }>(),
);
export const createAddonSuccess = createAction(
  '[Add Bill Modal] Create Addon Success',
  props<{ addonId: number; createdBillsId: number[] }>(),
);
export const createAddonFailure = createAction(
  '[Add Bill Modal] Create Addon Failure',
  props<{ addonId: number; error: any }>(),
);

export const updateAddonRequest = createAction(
  '[Add Bill Modal] Update Addon Request',
  props<{
    addon: EditBillsRequest;
    isCreationWithMerge?: boolean;
    onSuccess?: () => any;
  }>(),
);
export const updateAddonSuccess = createAction(
  '[Add Bill Modal] Update Addon Success',
);
export const updateAddonFailure = createAction(
  '[Add Bill Modal] Update Addon Failure',
  props<{ error: any }>(),
);

export const deleteBillsRequest = createAction(
  '[Add Bill Modal] Delete Addon Request',
  props<{ billsIds: number[] }>(),
);
export const deleteBillsSuccess = createAction(
  '[Add Bill Modal] Delete Bills Success',
  props<{ billsIds: number[] }>(),
);
export const deleteBillsFailure = createAction(
  '[Add Bill Modal] Delete Bills Failure',
  props<{ error: any }>(),
);

export const loadPayingCustomerForVariousCategoryRequest = createAction(
  '[Add Bill Modal] Load Paying Customer For Various Category Request',
  props<{
    reservationId: number;
    reservationAccommodationId: number;
  }>(),
);
export const loadPayingCustomerForVariousCategorySuccess = createAction(
  '[Add Bill Modal] Load Paying Customer For Various Category Success',
  props<{ payingCustomerForVariousCategory: PayingCustomer }>(),
);
export const loadPayingCustomerForVariousCategoryFailure = createAction(
  '[Add Bill Modal] Load Paying Customer For Various Category Failure',
  props<{ error: any }>(),
);

export const setSelectedReservationAccommodation = createAction(
  '[Add Bill Modal] Set Selected Reservation Accommodation',
  props<{ selectedReservationAccommodation: AddBillModalAccommodation }>(),
);

export const setSelectedGuest = createAction(
  '[Add Bill Modal] Set Selected Guest',
  props<{ selectedGuest: AddBillModalAccommodationGuest }>(),
);

export const setReferenceDate = createAction(
  '[Add Bill Modal] Set Reference Date',
  props<{ referenceDate: Date }>(),
);

export const setSomethingIsChanged = createAction(
  '[Add Bill Modal] Set Something Is Changed',
  props<{ somethingIsChanged: boolean }>(),
);

export const setConfig = createAction(
  '[Add Bill Modal] Set Config',
  props<{ config: AddBillModalConfig }>(),
);

export const resetState = createAction('[Add Bill Modal] Reset State');
