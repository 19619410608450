<ng-container *ngIf="propertiesSubscriptionInfoWarnings?.length">
  <nz-alert
    nzBanner
    [nzType]="'warning'"
    nzCloseable
    [nzAction]="actionTemplate"
    [nzMessage]="propertyTitleTemplate"
  ></nz-alert>

  <ng-template #propertyTitleTemplate>
    <ng-container
      *ngFor="
        let propertySubscriptionInfo of propertiesSubscriptionInfoWarnings;
        let index = index
      "
    >
      <div
        *ngIf="index <= 2 || showMoreWarnings"
        [innerHTML]="
          (propertySubscriptionInfo.status === 'Expired'
            ? 'property_expired'
            : 'property_expiring'
          )
            | translate
              : {
                  name: propertySubscriptionInfo.name,
                  data: propertySubscriptionInfo.expire_date | formatDate
                }
        "
      ></div>
    </ng-container>

    <div *ngIf="propertiesSubscriptionInfoWarnings.length > 3">
      <a (click)="showMoreWarnings = !showMoreWarnings">{{
        (showMoreWarnings
          ? 'hide_more_property_subscription_info'
          : 'show_more_property_subscription_info'
        )
          | translate
          | upperfirst
      }}</a>
    </div>
  </ng-template>
</ng-container>

<ng-template #actionTemplate>
  <button
    nz-button
    nzSize="small"
    class="color--azure by-mr-25"
    nzType="text"
    (click)="onInfo()"
  >
    {{ 'details' | translate | upperfirst }}
  </button>
</ng-template>
