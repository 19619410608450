import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';

import { State } from './state';

export const getError = (state: State) => state.error;

export const selectState = createFeatureSelector<State>('addBillModal');

export const selectError = createSelector(selectState, getError);

export const selectReservationAccommodations = createSelector(
  selectState,
  (state: State) => state.reservationAccommodations,
);

export const selectReservationAccommodationSelected = createSelector(
  selectState,
  (state: State) => state.selectedReservationAccommodation,
);

export const selectGuestSelected = createSelector(
  selectState,
  (state: State) => state.selectedGuest,
);

export const selectAvailableAddons = createSelector(
  selectState,
  (state: State) => state.availableAddons,
);

export const selectAccommodationBills = createSelector(
  selectState,
  (state: State) => state.accommodationBills || [],
);

export const selectVatQuotesSettings = createSelector(
  selectState,
  (state: State) => state.vatQuotesSettings,
);

export const selectPlaceVatQuotes = createSelector(
  selectState,
  (state: State) => state.placeVatQuotes,
);

export const selectBillsDepartmentsByVatCode = createSelector(
  selectState,
  (state: State) => groupBy(state.billsDepartments, 'vat_quote_id'),
);

export const selectChildrenRanges = createSelector(
  selectState,
  (state: State) => state.childrenRanges,
);

export const selectPayingCustomerForVariousCategory = createSelector(
  selectState,
  (state: State) => state.payingCustomerForVariousCategory,
);

export const selectReferenceDate = createSelector(
  selectState,
  (state: State) => state.referenceDate,
);

export const selectSomethingIsChanged = createSelector(
  selectState,
  (state: State) => state.somethingIsChanged,
);

export const selectConfig = createSelector(
  selectState,
  (state: State) => state.config,
);

export const selectCreatedBillsId = createSelector(
  selectState,
  (state: State) => state.createdBillsId,
);

/** ---------- LOADINGS -----------  */

export const selectIsLoading = createSelector(
  selectState,
  (state: State) => state.isLoading,
);

export const selectIsCreating = createSelector(
  selectState,
  (state: State) => state.createLoading,
);

export const selectAccommodationsLoading = createSelector(
  selectState,
  (state: State) => state.accommodationsLoading,
);
