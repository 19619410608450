import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';

import { AddBillModalConfig } from '../../../../models';

@Component({
  selector: 'by-add-bill-modal-date',
  templateUrl: './add-bill-modal-date.component.html',
  styleUrls: ['./add-bill-modal-date.component.scss'],
})
export class AddBillModalDateComponent implements OnInit {
  @Input() selectedDate: Date;

  @Input() config: AddBillModalConfig;

  @Output() changeDate = new EventEmitter<Date>();

  calendarIsOpen: boolean;

  constructor() {}

  ngOnInit() {}

  onToday() {
    this.updateReferenceDate(new Date());
  }

  onPrevDay() {
    this.updateReferenceDate(
      moment(this.selectedDate).subtract(1, 'days').toDate(),
    );
  }

  onNextDay() {
    this.updateReferenceDate(moment(this.selectedDate).add(1, 'days').toDate());
  }

  updateReferenceDate(date: Date) {
    this.calendarIsOpen = false;

    if (moment(date).isSame(moment(this.selectedDate), 'days')) {
      return;
    }

    this.changeDate.emit(date);
  }
}
