<ng-container *ngIf="selectedAccommodation; else empty">
  <div
    class="add-bill-modal__accommodation-bills"
    *ngIf="accommodationBills?.length; else empty"
  >
    <div
      *ngFor="let bill of accommodationBills"
      nz-row
      nzAlign="middle"
      class="add-bill-modal__accommodation-bill"
    >
      <div nz-col>
        <label
          nz-checkbox
          [ngModel]="checkedMap[bill.bill_id]"
          (ngModelChange)="onCheckBill(bill.bill_id, $event)"
        ></label>
      </div>

      <div nz-col nzFlex="1">
        <div nz-row nzJustify="space-between" nzAlign="middle">
          <div
            nz-col
            class="add-bill-modal__accommodation-bill-col"
            nzXs="24"
            nzSm="24"
            nzLg="7"
          >
            <div class="add-bill-modal__accommodation-bill-box">
              <div class="add-bill-modal__accommodation-bill-box-title">
                {{ bill.date | date: 'dd/MM/yyyy' }}
              </div>
              <div class="by-fs-15 pointer">
                <strong
                  class="color--azure"
                  (click)="bill.editable && editAddon.emit(bill.bill_id)"
                  >{{ bill.name }}</strong
                >
              </div>
            </div>
            <div class="add-bill-modal__accommodation-bill-box text-align--end">
              <a *ngIf="bill.media" target="_blank" [href]="bill.media.media">
                <i
                  class="fas fa-receipt by-fs-15 color--green by-mr-10"
                  nz-tooltip
                  [nzTooltipTitle]="
                    bill.media.label | replaceAll: '_':' ' | replaceAll: '-':'/'
                  "
                ></i>
              </a>

              <ng-container
                *ngTemplateOutlet="
                  paidIcon;
                  context: {
                    paid: bill.payments | arraySum: 'amount'
                  }
                "
              ></ng-container>
              <ng-template #paidIcon let-paid="paid">
                <i
                  nz-tooltip
                  nzTooltipTitle="{{ 'paid' | translate | upperfirst }}: {{
                    paid | toCurrency: currencySymbol
                  }}"
                  class="fa-check-circle by-fs-17 pointer"
                  [ngClass]="{
                    fas: paid > 0,
                    far: paid === 0,
                    'color--green': paid > 0,
                    'color--grey-border': paid === 0
                  }"
                ></i>
              </ng-template>
            </div>
          </div>
          <div
            nz-col
            class="add-bill-modal__accommodation-bill-col"
            nzXs="24"
            nzSm="24"
            nzLg="10"
          >
            <div
              nz-row
              nzJustify="space-between"
              nzAlign="middle"
              [nzGutter]="[5, 5]"
            >
              <div
                nz-col
                class="add-bill-modal__accommodation-bill-box"
                nzXs="12"
                nzSm="12"
                nzXl="6"
              >
                <div class="add-bill-modal__accommodation-bill-box-title">
                  {{ 'rate' | translate | upperfirst }}
                </div>
                <div>
                  {{
                    +bill.total_price / +bill.qty
                      | floatRound
                      | toCurrency: currencySymbol
                  }}
                </div>
              </div>
              <div
                nz-col
                class="add-bill-modal__accommodation-bill-box"
                nzXs="12"
                nzSm="12"
                nzXl="6"
              >
                <div class="add-bill-modal__accommodation-bill-box-title">
                  {{ 'quantity_abbreviated' | translate | upperfirst }}
                </div>
                <div>
                  {{ bill.qty }}
                </div>
              </div>
              <div
                nz-col
                class="add-bill-modal__accommodation-bill-box"
                nzXs="12"
                nzSm="12"
                nzXl="6"
              >
                <div class="add-bill-modal__accommodation-bill-box-title">
                  {{ 'discount' | translate | upperfirst }}
                </div>
                <div
                  *ngIf="+bill.discount_value && bill.discount_type_id === 5"
                >
                  {{ +bill.discount_value }}%
                </div>
                <div
                  *ngIf="+bill.discount_value && bill.discount_type_id === 4"
                >
                  {{ +bill.discount_value | toCurrency: currencySymbol }}
                </div>
                <div *ngIf="!+bill.discount_value">-</div>
              </div>
              <div
                nz-col
                class="add-bill-modal__accommodation-bill-box"
                nzXs="12"
                nzSm="12"
                nzXl="6"
              >
                <div class="add-bill-modal__accommodation-bill-box-title">
                  {{ 'total' | translate | upperfirst }}
                </div>
                <div *ngIf="!+bill.discount_value">
                  <strong>{{
                    +bill.total_price | toCurrency: currencySymbol
                  }}</strong>
                </div>
                <div *ngIf="+bill.discount_value">
                  <div>
                    <span class="old-total">{{
                      +bill.total_price | toCurrency: currencySymbol
                    }}</span>
                  </div>
                  <div>
                    <strong>{{
                      +bill.total_price - +bill.discounted_price
                        | toCurrency: currencySymbol
                    }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            nz-col
            class="add-bill-modal__accommodation-bill-col"
            nzXs="24"
            nzSm="24"
            nzLg="7"
          >
            <div class="add-bill-modal__accommodation-bill-box">
              <div class="add-bill-modal__accommodation-bill-box-title">
                {{ 'buyer' | translate | upperfirst }}
              </div>
              <div *ngIf="bill.company">
                {{ bill.company.name }}
              </div>
              <div *ngIf="!bill.company">
                {{ bill.customer?.name }} {{ bill.customer?.surname }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col>
        <a
          nz-dropdown
          [nzPlacement]="'bottomRight'"
          [nzTrigger]="'click'"
          [nzDropdownMenu]="menu"
        >
          <i class="fas fa-ellipsis-v color--azure"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li
              *ngIf="bill.editable"
              nz-menu-item
              (click)="editAddon.emit(bill.bill_id)"
            >
              <a>
                <i class="fas fa-pencil-alt color--azure"></i>
                {{ 'edit' | translate | upperfirst }}
              </a>
            </li>
            <li
              *ngIf="bill.invoiced_status !== 'invoiced' && bill.deletable"
              nz-menu-item
              nz-popconfirm
              nzPopconfirmTitle="{{
                'actions.delete' | translate: { param: 'bill' | translate }
              }}"
              (nzOnConfirm)="deleteBills.emit([bill.bill_id])"
            >
              <a>
                <i class="fas fa-trash-alt color--red"></i>
                {{ 'delete' | translate | upperfirst }}
              </a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>

    <div class="add-bill-modal__accommodation-bill-buttons-bar">
      <button
        nz-button
        nzType="primary"
        [disabled]="!someAddonsChecked"
        (click)="onCreateOrdination()"
        class="by-mr-5"
      >
        {{ 'create_ordination' | translate | upperfirst }}
      </button>

      <button
        nz-button
        nzType="primary"
        nzDanger
        [disabled]="!someAddonsChecked"
        (click)="onDeleteSelectedAddons()"
      >
        {{ 'delete_bills' | translate | upperfirst }}
      </button>
    </div>
  </div>
</ng-container>

<ng-template #empty>
  <div class="by-mt-80">
    <nz-empty
      [nzNotFoundContent]="
        (selectedAccommodation
          ? 'no_bills_for_selected_accommodation'
          : 'select_accommodation_to_view_bills'
        )
          | translate
          | upperfirst
      "
    >
    </nz-empty>
  </div>
</ng-template>
