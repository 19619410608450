import { PriceQuotation } from '@app/models';
import { CrmCartItem } from '@commons/crm/models/crm-cart-item';
import { Action } from '@ngrx/store';
import { CrmCart } from '../../features/commons/crm/models/crm-cart';

export enum ActionTypes {
  LOAD_REQUEST = '[Price Quotation] Load Request',
  LOAD_FAILURE = '[Price Quotation] Load Failure',
  LOAD_SUCCESS = '[Price Quotation] Load Success',

  UPDATE_REQUEST = '[Price Quotation] Update Request',
  UPDATE_FAILURE = '[Price Quotation] Update Failure',
  UPDATE_SUCCESS = '[Price Quotation] Update Success',

  ADD_MANDATORY_ADDONS_REQUEST = '[Price Quotation] Add Mandatory Addons Request',
  ADD_MANDATORY_ADDONS_SUCCESS = '[Price Quotation] Add Mandatory Addons Success',
  ADD_MANDATORY_ADDONS_FAILURE = '[Price Quotation] Add Mandatory Addons Failure',

  DELETE_MANDATORY_ADDONS_REQUEST = '[Price Quotation] Delete Mandatory Addons Request',
  DELETE_MANDATORY_ADDONS_SUCCESS = '[Price Quotation] Delete Mandatory Addons Success',
  DELETE_MANDATORY_ADDONS_FAILURE = '[Price Quotation] Delete Mandatory Addons Failure',

  SEND_EMAIL_REQUEST = '[Price Quotation] Send Email Request',
  SEND_EMAIL_FAILURE = '[Price Quotation] Send Email Failure',
  SEND_EMAIL_SUCCESS = '[Price Quotation] Send Email Success',

  ADD_CART_REQUEST = '[Price Quotation] Add Cart Request',
  ADD_CART_FAILURE = '[Price Quotation] Add Cart Success',
  ADD_CART_SUCCESS = '[Price Quotation] Add Cart Failure',

  ADD_CART_WITH_ITEM_REQUEST = '[Price Quotation] Add Cart With Item Request',

  DELETE_CART_REQUEST = '[Price Quotation] Delete Cart Request',
  DELETE_CART_FAILURE = '[Price Quotation] Delete Cart Success',
  DELETE_CART_SUCCESS = '[Price Quotation] Delete Cart Failure',

  ADD_CART_ITEM_REQUEST = '[Price Quotation] Add Cart Item Request',
  ADD_CART_ITEM_SUCCESS = '[Price Quotation] Add Cart Item Success',
  ADD_CART_ITEM_FAILURE = '[Price Quotation] Add Cart Item Failure',

  UPDATE_CART_ITEM_REQUEST = '[Price Quotation] Update Cart Item Request',
  UPDATE_CART_ITEM_SUCCESS = '[Price Quotation] Update Cart Item Success',
  UPDATE_CART_ITEM_FAILURE = '[Price Quotation] Update Cart Item Failure',

  UPDATE_CART_REQUEST = '[Price Quotation] Update Cart Request',
  UPDATE_CART_SUCCESS = '[Price Quotation] Update Cart Success',
  UPDATE_CART_FAILURE = '[Price Quotation] Update Cart Failure',

  DELETE_CART_ITEM_REQUEST = '[Price Quotation] Delete Cart Item Request',
  DELETE_CART_ITEM_SUCCESS = '[Price Quotation] Delete Cart Item Success',
  DELETE_CART_ITEM_FAILURE = '[Price Quotation] Delete Cart Item Failure',

  CONFIRM_OPTION_REQUEST = '[Price Quotation] Confirm Option Request',
  CONFIRM_OPTION_SUCCESS = '[Price Quotation] Confirm Option Success',
  CONFIRM_OPTION_FAILURE = '[Price Quotation] Confirm Option Failure',

  UPLOAD_MEDIA_REQUEST = '[Price Quotation] Upload  Media Request',
  UPLOAD_MEDIA_SUCCESS = '[Price Quotation] Upload  Media Success',
  UPLOAD_MEDIA_FAILURE = '[Price Quotation] Upload  Media Failure',

  DELETE_MEDIA_REQUEST = '[Price Quotation] Delete  Media Request',
  DELETE_MEDIA_SUCCESS = '[Price Quotation] Delete  Media Success',
  DELETE_MEDIA_FAILURE = '[Price Quotation] Delete  Media Failure',

  RESET_STATE = '[Price Quotation] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { priceQuotationId: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { item: PriceQuotation }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      priceQuotationId: number;
      data: any;
      customer?: any;
      company?: any;
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(
    public payload: {
      depositPrice?: number;
      customer?: any;
      company?: any;
    },
  ) {}
}

export class UpdateCartRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_CART_REQUEST;
  constructor(
    public payload: {
      priceQuotationId: number;
      optionId: number;
      cart: Partial<CrmCart>;
    },
  ) {}
}

export class UpdateCartFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_CART_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateCartSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_CART_SUCCESS;
  constructor(public payload: { optionId: number; cart: Partial<CrmCart> }) {}
}

export class SendEmailRequestAction implements Action {
  readonly type = ActionTypes.SEND_EMAIL_REQUEST;
  constructor(
    public payload: { priceQuotationId: number; email_addresses?: string[] },
  ) {}
}

export class SendEmailFailureAction implements Action {
  readonly type = ActionTypes.SEND_EMAIL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SendEmailSuccessAction implements Action {
  readonly type = ActionTypes.SEND_EMAIL_SUCCESS;
}

export class UpdateCartItemRequest implements Action {
  readonly type = ActionTypes.UPDATE_CART_ITEM_REQUEST;

  constructor(
    public payload: {
      priceQuotationId: number;
      cartId: number;
      cartItemId: number;
      cartItem: CrmCartItem;
    },
  ) {}
}

export class UpdateCartItemFailure implements Action {
  readonly type = ActionTypes.UPDATE_CART_ITEM_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateCartItemSuccess implements Action {
  readonly type = ActionTypes.UPDATE_CART_ITEM_SUCCESS;

  constructor(
    public payload: {
      cartId: number;
      cartItemId: number;
      cartItem: CrmCartItem;
      priceQuotationId: number;
    },
  ) {}
}

export class AddCartItemRequest implements Action {
  readonly type = ActionTypes.ADD_CART_ITEM_REQUEST;

  constructor(
    public payload: {
      priceQuotationId: number;
      cartId: number;
      cartItem: CrmCartItem;
      disableNotify?: boolean;
      onClickSuccessNotification?: () => void;
    },
  ) {}
}

export class AddCartItemFailure implements Action {
  readonly type = ActionTypes.ADD_CART_ITEM_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class AddCartItemSuccess implements Action {
  readonly type = ActionTypes.ADD_CART_ITEM_SUCCESS;

  constructor(
    public payload: {
      cartItems: Array<{
        id: number;
        reservation_quote_option_id: number;
        accommodation: CrmCartItem;
      }>;
    },
  ) {}
}

export class DeleteCartItemRequest implements Action {
  readonly type = ActionTypes.DELETE_CART_ITEM_REQUEST;
  constructor(
    public payload: {
      priceQuotationId: number;
      cartId: number;
      cartItemId: number;
    },
  ) {}
}

export class DeleteCartItemFailure implements Action {
  readonly type = ActionTypes.DELETE_CART_ITEM_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteCartItemSuccess implements Action {
  readonly type = ActionTypes.DELETE_CART_ITEM_SUCCESS;
  constructor(
    public payload: {
      cartId: number;
      cartItemId: number;
    },
  ) {}
}

export class AddCartRequest implements Action {
  readonly type = ActionTypes.ADD_CART_REQUEST;
  constructor(public payload: { priceQuotationId: number }) {}
}

export class AddCartFailure implements Action {
  readonly type = ActionTypes.ADD_CART_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class AddCartSuccess implements Action {
  readonly type = ActionTypes.ADD_CART_SUCCESS;
  constructor(public payload: { cart: any }) {}
}

export class AddCartWithItemRequest implements Action {
  readonly type = ActionTypes.ADD_CART_WITH_ITEM_REQUEST;
  constructor(
    public payload: {
      priceQuotationId: number;
      cartItem: CrmCartItem;
    },
  ) {}
}

export class AddMandatoryAddonsRequest implements Action {
  readonly type = ActionTypes.ADD_MANDATORY_ADDONS_REQUEST;
  constructor(
    public payload: {
      priceQuotationId: number;
      cartId: number;
      cartItemId?: number;
    },
  ) {}
}
export class AddMandatoryAddonsSuccess implements Action {
  readonly type = ActionTypes.ADD_MANDATORY_ADDONS_SUCCESS;
}
export class AddMandatoryAddonsFailure implements Action {
  readonly type = ActionTypes.ADD_MANDATORY_ADDONS_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class DeleteMandatoryAddonsRequest implements Action {
  readonly type = ActionTypes.DELETE_MANDATORY_ADDONS_REQUEST;
  constructor(
    public payload: {
      priceQuotationId: number;
      cartId: number;
    },
  ) {}
}
export class DeleteMandatoryAddonsSuccess implements Action {
  readonly type = ActionTypes.DELETE_MANDATORY_ADDONS_SUCCESS;
}
export class DeleteMandatoryAddonsFailure implements Action {
  readonly type = ActionTypes.DELETE_MANDATORY_ADDONS_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class DeleteCartRequest implements Action {
  readonly type = ActionTypes.DELETE_CART_REQUEST;
  constructor(public payload: { priceQuotationId: number; cartId: number }) {}
}

export class DeleteCartFailure implements Action {
  readonly type = ActionTypes.DELETE_CART_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteCartSuccess implements Action {
  readonly type = ActionTypes.DELETE_CART_SUCCESS;
  constructor(public payload: { cartId: number }) {}
}

export class ConfirmOptionRequest implements Action {
  readonly type = ActionTypes.CONFIRM_OPTION_REQUEST;
  constructor(
    public payload: {
      priceQuotationId: number;
      cartId: number;
      force_operation?: boolean;
    },
  ) {}
}

export class ConfirmOptionFailure implements Action {
  readonly type = ActionTypes.CONFIRM_OPTION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ConfirmOptionSuccess implements Action {
  readonly type = ActionTypes.CONFIRM_OPTION_SUCCESS;
}

export class UploadMediaRequest implements Action {
  readonly type = ActionTypes.UPLOAD_MEDIA_REQUEST;
  constructor(
    public payload: {
      file: any;
      priceQuotationId?: number;
    },
  ) {}
}

export class UploadMediaFailure implements Action {
  readonly type = ActionTypes.UPLOAD_MEDIA_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UploadMediaSuccess implements Action {
  readonly type = ActionTypes.UPLOAD_MEDIA_SUCCESS;
  constructor(
    public payload: {
      file: {
        created_at: string;
        id: number;
        label: string;
        path: string;
        property_id: number;
        reservation_quote_id: number;
        updated_at: string;
      };
      priceQuotationId?: number;
    },
  ) {}
}
export class DeleteMediaRequest implements Action {
  readonly type = ActionTypes.DELETE_MEDIA_REQUEST;
  constructor(
    public payload: {
      attachmentId: number;
      priceQuotationId?: number;
    },
  ) {}
}

export class DeleteMediaFailure implements Action {
  readonly type = ActionTypes.DELETE_MEDIA_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteMediaSuccess implements Action {
  readonly type = ActionTypes.DELETE_MEDIA_SUCCESS;
  constructor(
    public payload: {
      attachmentId: any;
      priceQuotationId?: number;
    },
  ) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | UpdateRequestAction
  | UpdateFailureAction
  | UpdateSuccessAction
  | UpdateCartItemRequest
  | UpdateCartItemFailure
  | UpdateCartItemSuccess
  | DeleteCartItemRequest
  | DeleteCartItemFailure
  | DeleteCartItemSuccess
  | AddCartWithItemRequest
  | AddCartItemRequest
  | AddCartItemFailure
  | AddCartItemSuccess
  | AddCartRequest
  | AddCartFailure
  | AddCartSuccess
  | UpdateCartRequestAction
  | UpdateCartFailureAction
  | UpdateCartSuccessAction
  | DeleteCartRequest
  | DeleteCartFailure
  | DeleteCartSuccess
  | ResetSuccessAction
  | ConfirmOptionRequest
  | ConfirmOptionSuccess
  | ConfirmOptionFailure
  | DeleteMediaRequest
  | DeleteMediaSuccess
  | DeleteMediaFailure
  | AddMandatoryAddonsRequest
  | AddMandatoryAddonsSuccess
  | AddMandatoryAddonsFailure
  | DeleteMandatoryAddonsRequest
  | DeleteMandatoryAddonsSuccess
  | DeleteMandatoryAddonsFailure
  | UploadMediaRequest
  | UploadMediaSuccess
  | UploadMediaFailure;
