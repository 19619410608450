import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IResponse } from './../models/response.model';

@Injectable({
  providedIn: 'root',
})
export class PropertySelectedService {
  constructor(private _http: HttpClient) {}

  public loadPropertySelected(id: number): Observable<IResponse> {
    return this._http.get(`property/${id}`) as Observable<IResponse>;
  }

  public loadAllUserProperties(id: number): Observable<IResponse> {
    return this._http.get(`user/${id}/properties`) as Observable<IResponse>;
  }

  public loadUserProperties(id: number): Observable<IResponse> {
    return this._http.get(
      `user/${id}/groups/properties`,
    ) as Observable<IResponse>;
  }
}
