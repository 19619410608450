<form
  *ngIf="availableAddons && availableAddons.length"
  class="by-mt-2"
  nz-form
  nzLayout="vertical"
  [formGroup]="filterForm"
>
  <nz-form-item>
    <nz-form-label>
      {{ 'search_addons' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control>
      <nz-select
        class="by-w-100"
        formControlName="category_id"
        nzShowSearch
        nzAllowClear
        [nzPlaceHolder]="'select_category' | translate | upperfirst"
      >
        <ng-container *ngFor="let category of addonsCategories">
          <nz-option [nzLabel]="category.name" [nzValue]="category.id">
          </nz-option>
        </ng-container>
      </nz-select>
    </nz-form-control>
    <nz-input-group class="by-mt-10" [nzSuffix]="searchIcon">
      <input
        nz-input
        formControlName="addon_name"
        [placeholder]="'search_by_name' | translate | upperfirst"
      />
    </nz-input-group>
  </nz-form-item>
</form>

<div class="add-bill-modal__available-addons">
  <ng-container *ngFor="let addon of availableAddons">
    <ng-container
      *ngIf="
        (!filterForm.value.category_id ||
          filterForm.value.category_id === addon.category_id) &&
        (!filterForm.value.addon_name || addon.name
          | filter: filterForm.value.addon_name)
      "
    >
      <by-add-bill-modal-available-addon
        [addon]="addon"
        [currencySymbol]="currencySymbol"
        [estimate]="estimates && estimates[addon.addon_id]"
        [selectedReservationAccommodation]="selectedReservationAccommodation"
        [vatQuotes]="vatQuotes"
        [childrenRanges]="childrenRanges"
        [creatingLoading]="creatingLoading"
        (estimateAddon)="estimateAddon.emit($event)"
        (addAddon)="addAddon.emit($event)"
        (expandStatusChange)="onExpandStatusChange($event)"
      ></by-add-bill-modal-available-addon>
    </ng-container>
  </ng-container>
</div>

<div class="add-bill-modal__available-addon">
  <nz-collapse nzGhost *ngIf="selectedReservationAccommodation">
    <nz-collapse-panel
      [(nzActive)]="creationFormIsVisible"
      (nzActiveChange)="
        onCreationFormExpandedChange($event);
        onExpandStatusChange({ status: $event, addon_id: null })
      "
      [nzHeader]="collapseHeader"
      [nzShowArrow]="false"
    >
      <nz-card class="card-with-shadow">
        <by-add-bill-modal-form
          #creationForm
          [addon]="fakeCreationAddon"
          [show]="true"
          [selectedReservationAccommodation]="selectedReservationAccommodation"
          [vatQuotes]="vatQuotes"
          [billsDepartments]="billsDepartments"
          [childrenRanges]="childrenRanges"
          [currencySymbol]="currencySymbol"
        ></by-add-bill-modal-form>
        <div nz-row [nzGutter]="20">
          <div nz-col [nzXs]="24" [nzSm]="24">
            <button
              class="by-w-100"
              nz-button
              type="button"
              nzType="primary"
              nzSize="small"
              [disabled]="creationForm.form.invalid"
              nz-dropdown
              [nzDropdownMenu]="menu"
              [nzLoading]="!!(creatingLoading | get: 'new')"
            >
              {{ 'save' | translate | upperfirst }}
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li
                  nz-menu-item
                  (click)="onCreateAddon(true, creationForm.value)"
                >
                  <a>
                    {{ 'save_book_only' | translate | upperfirst }}
                  </a>
                </li>
                <li
                  nz-menu-item
                  (click)="onCreateAddon(false, creationForm.value)"
                >
                  <a>
                    {{ 'save_and-add' | translate | upperfirst }}
                  </a>
                </li>
              </ul>
            </nz-dropdown-menu>
          </div>
        </div>
      </nz-card>
    </nz-collapse-panel>
    <ng-template #collapseHeader>
      <button
        class="by-w-100"
        nz-button
        type="button"
        nzType="primary"
        [nzLoading]="!vatQuotesSettings || !payingCustomerForVariousCategory"
      >
        <i class="fal fa-plus-circle"></i>
        {{ 'create_new_extra' | translate | upperfirst }}
      </button>
    </ng-template>
  </nz-collapse>
</div>

<ng-template #searchIcon>
  <i class="fal fa-search color--grey-dark"></i>
</ng-template>
