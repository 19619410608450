<a
  nzTrigger="click"
  nz-dropdown
  [nzDropdownMenu]="menu"
  class="by-flex by-flex-middle"
>
  <i class="far fa-question-circle"></i>
  <i class="far fa-angle-down by-ml-5"></i>
</a>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu nzMode="inline" class="dropdowWidth">
    <ng-container *ngFor="let section of sectionsToView">
      <ng-container *ngFor="let link of allSections | get: section:[]">
        <li (click)="openInNewTab(link?.link)" nz-menu-item>
          <span style="white-space: pre-line">
            {{
              'show_guide'
                | translate: { param: (link?.label | translate | titlecase) }
            }}
          </span>
        </li>
      </ng-container>
    </ng-container>
    <li (click)="openInNewTab('https://help.beddy.io/home/')" nz-menu-item>
      <strong>
        {{ 'show_all_online_guide' | translate | upperfirst }}
      </strong>
    </li>
    <li
      (click)="
        openInNewTab('https://support.beddy.io/index.php?/Tickets/Submit')
      "
      nz-menu-item
      [ngClass]="{ 'menu-item-border': sectionsToView?.length }"
    >
      <span>
        {{ 'open_ticket' | translate | upperfirst }}
      </span>
    </li>
  </ul>
</nz-dropdown-menu>
