import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IVatQuoteHolderRequest } from '../models/api/generics/vat-quote-holder/vat-quote-holder.model';
import { IResponse } from '../models/response.model';

@Injectable({
  providedIn: 'root',
})
export class VatQuoteHolderService {
  constructor(private _http: HttpClient) {}

  public searchVatQuoteHolder(
    data: IVatQuoteHolderRequest,
  ): Observable<IResponse> {
    return this._http.get(
      `general/utility/vat_quote_holder?country_code=${data.country_code}&vat_quote_number=${data.vat_quote_number}&search_internal_codes=${data.search_internal_codes}`,
    ) as Observable<IResponse>;
  }
}
