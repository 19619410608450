<ng-container *ngIf="userMe && !userMe.auth_verification_methods.length">
  <nz-alert
    nzBanner
    [nzType]="'error'"
    nzCloseable
    [nzAction]="actionTemplate"
    [nzMessage]="'user_2fa_warning' | translate | upperfirst"
  ></nz-alert>

  <ng-template #actionTemplate>
    <button nz-button nzSize="small" class="color--azure" nzType="text">
      <a href="me" target="_blank">{{
        'configure' | translate | upperfirst
      }}</a>
    </button>
    <button
      nz-tooltip
      [nzTooltipTitle]="'online_guide' | translate | capitalize"
      nz-button
      nzSize="small"
      nzType="text"
    >
      <a
        [href]="GUIDE_URL + 'bacheca/#configurare-autenticazione-a-due-fattori'"
        target="_blank"
        ><i class="far fa-question-circle color--orange"></i
      ></a>
    </button>
  </ng-template>
</ng-container>
