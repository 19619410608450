<ng-container *ngIf="signatureOptions">
  <div class="signature-pad__options-list">
    <ng-container
      *ngFor="
        let signatureOption of signatureOptions;
        trackBy: trackByFn;
        let index = index
      "
    >
      <div class="signature-pad__options-item">
        <ng-container *ngIf="signatureOption.type === 'radio'">
          <nz-radio-group
            [ngModel]="signatureOption.value"
            (ngModelChange)="updateSignatureOption(index, $event)"
            nzName="radiogroup"
          >
            <ng-container *ngFor="let option of signatureOption.options">
              <label nz-radio [nzValue]="option.value">{{
                option.label
              }}</label>
            </ng-container>
          </nz-radio-group>
        </ng-container>
        <label>{{ signatureOption.label }}</label>
      </div>
    </ng-container>
  </div>
</ng-container>

<div #signaturePadContainer class="signature-pad__canvas-container">
  <canvas #signaturePad class="signature-pad__canvas"></canvas>
</div>
