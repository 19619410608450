import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { SubSink } from 'subsink';

import { replaceAll } from '../../helpers/replace-all';
import { OrdinationAccommodation } from '../../models/objects/ordination-accommodation.model';
import { OrdinationBill } from '../../models/objects/ordination-bill.model';
import { OrdinationProperty } from '../../models/objects/ordination-property.model';
import {
  BillsOrdinationStoreActions,
  BillsOrdinationStoreSelectors,
} from '../../root-store/bills-ordination';
import { RootState } from '../../root-store/root-state';
import { ExportService } from '../../services/export.service';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';

const EXPORT_LINK =
  'bills/ordination/export?orientation=landscape&splittedScreen=1';

@Component({
  selector: 'by-bills-ordination',
  templateUrl: './bills-ordination.component.html',
  styleUrls: ['./bills-ordination.component.scss'],
  providers: [DatePipe],
})
export class BillsOrdinationComponent implements OnInit, OnDestroy {
  readonly nzDataModal = inject<BillsOrdinationComponent>(NZ_MODAL_DATA);

  @Input()
  bills: OrdinationBill[] = this.nzDataModal?.bills || [];

  @Input()
  property: OrdinationProperty = this.nzDataModal?.property || null;

  @Input()
  accommodation: OrdinationAccommodation =
    this.nzDataModal?.accommodation || null;

  @Input()
  currencySymbol: string = this.nzDataModal?.currencySymbol || '';

  today = new Date();

  signatureBase64: string;

  loading: 'printing' | 'saving';

  templateHtml$ = this.store.select(
    BillsOrdinationStoreSelectors.selectTemplate,
  );

  loading$ = this.store.select(BillsOrdinationStoreSelectors.selectIsLoading);

  private subs = new SubSink();

  constructor(
    private modalService: NzModalService,
    private modalRef: NzModalRef,
    private translate: TranslateService,
    private exportService: ExportService,
    private datePipe: DatePipe,
    private store: Store<RootState>,
  ) {}

  ngOnInit() {
    this.store.dispatch(
      BillsOrdinationStoreActions.loadTemplateRequest({
        payload: this.exportPayload,
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSign() {
    const modal = this.modalService.create({
      nzContent: SignaturePadComponent,
      nzWidth: 1000,
      nzClosable: false,
      nzFooter: [
        {
          label: upperFirst(this.translate.instant('cancel')),
          type: 'default',
          onClick: () => modal.close(),
        },
        {
          type: 'primary',
          label: upperFirst(this.translate.instant('sign')),
          onClick: (component) => {
            this.signatureBase64 = component.getSignature();
            this.store.dispatch(
              BillsOrdinationStoreActions.loadTemplateRequest({
                payload: this.exportPayload,
              }),
            );
            modal.close();
          },
        },
      ],
    });
  }

  onPrint() {
    this.loading = 'printing';
    this.subs.add(
      this.exportService
        .blobExport({
          link: EXPORT_LINK,
          payload: this.exportPayload,
        })
        .subscribe(() => {
          this.loading = null;
        }),
    );
  }

  onSave() {
    this.loading = 'saving';

    this.subs.add(
      this.exportService
        .blobExport(
          {
            link: EXPORT_LINK,
            payload: this.exportPayload,
          },
          null,
          false,
        )
        .subscribe((blob: Blob) => {
          let fileName = this.translate.instant('ordination_of_date', {
            ordinationDateTime: this.datePipe.transform(
              this.today,
              'dd-MM-yyyy_HH:mm',
            ),
          });

          fileName = replaceAll(fileName, ' ', '_');

          const file = new File([blob], fileName, { type: blob.type });

          this.store.dispatch(
            BillsOrdinationStoreActions.saveOrdinationRequest({
              ordinationMedia: file,
              bill_id: this.bills.map(({ id }) => id),
              reservationAccommodationId:
                this.accommodation.reservation_accommodation_id,
              onSuccess: () => {
                this.loading = null;
                this.modalRef.close();
              },
            }),
          );
        }),
    );
  }

  private get exportPayload() {
    return {
      bills: this.bills,
      property_id: this.property.id,
      property: this.property,
      accommodation: this.accommodation,
      currency_symbol: this.currencySymbol,
      today: this.datePipe.transform(this.today, 'dd/MM/yyyy - HH:mm'),
      signature: this.signatureBase64 || null,
    };
  }
}
