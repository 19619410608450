<form [formGroup]="form" nz-form nzLayout="vertical" *ngIf="show">
  <div nz-row [nzGutter]="20" *ngIf="!addon?.addon_id">
    <div nz-col [nzXs]="24" [nzSm]="24">
      <nz-form-item>
        <nz-form-label nzRequired>{{
          'name' | translate | capitalize
        }}</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="override"
            [placeholder]="'addon_name' | translate | upperfirst"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="20">
    <div nz-col [nzXs]="24" [nzSm]="24">
      <nz-form-item>
        <nz-form-label>{{ 'date' | translate | upperfirst }}</nz-form-label>
        <nz-form-control>
          <ng-container
            *ngIf="
              (showPeriodConditionMapConstant | get: [addon?.price_type]) &&
                !addon?.bill_id;
              else singleDate
            "
          >
            <nz-range-picker
              byRangePicker
              byDisabledDateFixer
              style="width: 100%"
              [ngStyle]="{ width: '100%' }"
              nzFormat="dd/MM/yyyy"
              formControlName="dates"
              [nzDisabledDate]="disableDates"
              [nzSeparator]="'~'"
              [nzAllowClear]="false"
            ></nz-range-picker>
          </ng-container>

          <ng-template #singleDate>
            <nz-date-picker
              byDisabledMobileKeyboard
              byDisabledDateFixer
              style="width: 100%"
              [nzAllowClear]="false"
              [ngStyle]="{ width: '100%' }"
              formControlName="date"
              nzFormat="dd/MM/yyyy"
              [nzDisabledDate]="disableDates"
            >
            </nz-date-picker>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <ng-container
    *ngIf="showQuantityAndRateConditionMapConstant | get: [addon?.price_type]"
  >
    <div nz-row [nzGutter]="20">
      <div nz-col [nzXs]="12" [nzSm]="12">
        <nz-form-item>
          <nz-form-label>{{ 'price' | translate | capitalize }}</nz-form-label>
          <nz-form-control>
            <nz-input-number
              formControlName="unit_price"
              byDecimalOnly
              [nzMin]="0"
              [nzFormatter]="currencyFormatter"
              [nzStep]="1"
              class="by-w-100"
            ></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12">
        <nz-form-item>
          <nz-form-label>{{
            'quantity' | translate | capitalize
          }}</nz-form-label>
          <nz-form-control>
            <nz-input-number
              formControlName="quantity"
              [nzMin]="1"
              [nzStep]="1"
              [nzDisabled]="quantityIsDisabled"
              class="by-w-100"
            ></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="showChildrenRangesConditionMapConstant | get: [addon?.price_type]"
  >
    <div nz-row [nzGutter]="20">
      <div nz-col [nzXs]="adultsColSize" [nzSm]="adultsColSize">
        <nz-form-item>
          <nz-form-label
            >{{ 'adults' | translate | capitalize }} (max
            {{ selectedReservationAccommodation?.max_adults }})</nz-form-label
          >
          <nz-form-control>
            <nz-input-number
              formControlName="adults"
              [nzMin]="1"
              [nzStep]="1"
              class="by-w-100"
            ></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" *ngIf="childrenRanges?.length">
        <nz-form-item>
          <nz-form-label
            >{{ 'children' | translate | capitalize }}
            (max
            {{ selectedReservationAccommodation?.max_children }})</nz-form-label
          >
          <nz-form-control>
            <nz-input-number
              formControlName="children"
              [nzMin]="0"
              [nzStep]="1"
              class="by-w-100"
            ></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <ng-container *ngIf="childrenRangesControl.controls">
      <div nz-row [nzGutter]="20">
        <ng-container
          formArrayName="children_ranges"
          *ngFor="
            let childForm of childrenRangesControl.controls;
            let childIndex = index
          "
        >
          <ng-container [formGroupName]="childIndex">
            <div nz-col [nzXs]="12" [nzSm]="12">
              <nz-form-item>
                <nz-form-label>
                  {{
                    ('age' | translate | upperfirst) +
                      ' ' +
                      ('child' | translate) +
                      ' ' +
                      (childIndex + 1)
                  }}
                </nz-form-label>
                <nz-form-control>
                  <nz-select
                    class="by-w-100"
                    [nzDropdownMatchSelectWidth]="false"
                    nzPlaceHolder="{{
                      'placeholder.select'
                        | translate: { param: 'child' | translate }
                    }}"
                    formControlName="property_has_children_range_id"
                  >
                    <ng-container>
                      <nz-option
                        *ngFor="let range of childrenRanges"
                        nzLabel="{{ range.combination?.from }} - {{
                          range.combination?.to
                        }}"
                        [nzValue]="range.id"
                      ></nz-option>
                    </ng-container>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <div nz-row [nzGutter]="20">
    <div nz-col [nzXs]="12" [nzSm]="12">
      <nz-form-item>
        <nz-form-label>{{ 'discount' | translate | capitalize }}</nz-form-label>
        <nz-form-control>
          <nz-input-group nzCompact class="discount-input-group">
            <nz-select formControlName="discount_type_id" [nzShowArrow]="false">
              <nz-option [nzValue]="4" [nzLabel]="currencySymbol"></nz-option>
              <nz-option [nzValue]="5" nzLabel="%"></nz-option>
            </nz-select>
            <nz-input-number
              byDecimalOnly
              formControlName="discount_value"
              [nzMin]="0"
              [nzMax]="
                form.value.discount_type_id === 4
                  ? totalPriceWithoutDiscount
                  : 100
              "
              [nzStep]="1"
            ></nz-input-number>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="12" [nzSm]="12">
      <nz-form-item>
        <nz-form-label>{{ 'total' | translate | capitalize }}</nz-form-label>
        <nz-form-control>
          <nz-input-number
            formControlName="total"
            byDecimalOnly
            [nzStep]="1"
            class="by-w-100"
            [nzMin]="0"
            [nzFormatter]="currencyFormatter"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="20">
    <div nz-col [nzXs]="24" [nzSm]="24">
      <nz-form-item>
        <nz-form-label>{{ 'aliquot' | translate | capitalize }}</nz-form-label>
        <nz-form-control>
          <nz-select
            class="by-w-100"
            formControlName="vat_quote_id"
            [nzPlaceHolder]="'aliquot' | translate | capitalize"
            [nzDropdownMatchSelectWidth]="false"
          >
            <ng-container *ngFor="let vatQuote of vatQuotes">
              <nz-option
                [nzLabel]="'[' + vatQuote.value + '%] ' + vatQuote.description"
                [nzValue]="vatQuote.id"
              ></nz-option>
            </ng-container>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div
    *ngIf="(billsDepartments | get: form.value.vat_quote_id : [])?.length > 1"
    nz-row
    [nzGutter]="20"
  >
    <div nz-col [nzXs]="24" [nzSm]="24">
      <nz-form-item>
        <nz-form-label>{{
          'department' | translate | capitalize
        }}</nz-form-label>
        <nz-form-control>
          <by-bills-departments-select
            formControlName="bill_department_id"
            [allowClear]="true"
            [billsDepartments]="
              billsDepartments | get: form.value.vat_quote_id : []
            "
          ></by-bills-departments-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSm="24">
      <nz-form-item>
        <nz-form-label>
          {{ 'buyer' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <by-customers-lookup
            [searchIn]="['customers', 'companies']"
            formControlName="customer_id"
            [optionClassFn]="customerOptionsClass"
            [optionsSortFn]="customerOptionsSort"
          ></by-customers-lookup>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row *ngIf="addon?.bill_id">
    <div nz-col nzSm="24">
      <nz-form-item>
        <nz-form-label>
          {{ 'guest' | translate | upperfirst }}
        </nz-form-label>
        <nz-form-control>
          <nz-select
            class="by-w-100"
            formControlName="customer_buyer_id"
            nzShowSearch
            [nzPlaceHolder]="'guest' | translate | upperfirst"
          >
            <ng-container
              *ngFor="
                let guest of selectedReservationAccommodation?.guests || []
              "
            >
              <nz-option [nzLabel]="guest.name" [nzValue]="guest.id">
              </nz-option>
            </ng-container>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div
    nz-row
    nzAlign="middle"
    nzJustify="space-between"
    class="by-mb-15"
    *ngIf="!(showPeriodConditionMapConstant | get: [addon?.price_type])"
  >
    <div nz-col>{{ 'marks_as_paid' | translate | upperfirst }}</div>
    <div nz-col>
      <nz-switch formControlName="deposit" nzSize="small"></nz-switch>
    </div>
  </div>

  <div
    nz-row
    nzAlign="middle"
    nzJustify="space-between"
    class="by-mb-15"
    *ngIf="!addon?.addon_id"
  >
    <div nz-col>
      {{ 'enable_all_inclusive_tax_document' | translate | upperfirst }}
    </div>
    <div nz-col>
      <nz-switch
        formControlName="all_inclusive_tax_document"
        nzSize="small"
      ></nz-switch>
    </div>
  </div>
</form>
