import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ILanguage } from '../../../models/api/generics/languages/language.model';
import { NgFirstOrDefaultPipeModule } from '@z-trippete/angular-pipes';
import { NzGridModule } from 'ng-zorro-antd/grid';

@Component({
  selector: 'by-language-mobile-bar',
  standalone: true,
  imports: [CommonModule, NgFirstOrDefaultPipeModule, NzGridModule],
  templateUrl: './language-mobile-bar.component.html',
  styleUrls: ['./language-mobile-bar.component.scss'],
})
export class LanguageMobileBarComponent {
  @Input() languages: ILanguage[];

  @Input() currentSelectLanguage: string;

  @Output() changeLanguage = new EventEmitter<ILanguage['iso_code']>();
}
