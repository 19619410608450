import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HtmlLanguageService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setLanguage(lang: string): void {
    const htmlElement = this.renderer.selectRootElement('html', true);
    this.renderer.setAttribute(htmlElement, 'lang', lang);
  }
}
