import { PriceQuotation } from '@app/models';

export interface State {
  item: PriceQuotation | null;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  item: null,
  isLoading: false,
  error: null,
};
