import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { DateFormatterService } from '../../core/services/date-formatter.service';
import { AddBillModalAddonWarningsComponent } from '../../features/commons/add-bill-modal/add-bill-modal-addon-warnings/add-bill-modal-addon-warnings.component';
import { LOAD_RESERVATIONS_STATIC_PARAMS } from '../../features/commons/add-bill-modal/resources/load-reservations-static-param';
import {
  BillDepartment,
  ChildrenRange,
  IBillsCategory,
  PlaceVatQuote,
  Reservation,
  VatQuotesSettings,
} from '../../models';
import { AddBillModalAvailableAddon } from '../../models/objects/add-bill-modal-available-addon';
import { PayingCustomer } from '../../models/responses/paying-customer.response';
import { AppStore } from '../../models/types/app-store';
import { BillsDepartmentsService, PlaceVatQuoteService } from '../../services';
import { AddBillModalApiService } from '../../services/add-bill-modal.api.service';
import { ChildrenRangeRootService } from '../../services/children-range.service';
import { ExportService } from '../../services/export.service';
import { VatQuoteService } from '../../services/vat-quote-settings.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class AddBillModalStoreEffects {
  constructor(
    private addBillModalApiService: AddBillModalApiService,
    private vatQuotesSettingsService: VatQuoteService,
    private placeVatQuoteService: PlaceVatQuoteService,
    private childrenRangesService: ChildrenRangeRootService,
    private billsDepartmentsService: BillsDepartmentsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private appStore: Store<AppStore>,
    private dateFormatter: DateFormatterService,
    private modalService: NzModalService,
    private translate: TranslateService,
    private notifications: NotificationService,
    private exportService: ExportService,
  ) {}

  loadReservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadReservationsRequest),
      withLatestFrom(this.appStore),
      switchMap(([_, { core, addBillModal }]) => {
        const referenceDate = this.dateFormatter.toServerFormat(
          addBillModal.referenceDate,
        );

        return this.addBillModalApiService
          .loadReservations({
            ...LOAD_RESERVATIONS_STATIC_PARAMS,
            property_id: core.allProperties.map(({ id }) => id),
            arrival_date: referenceDate,
            departure_date: referenceDate,
          })
          .pipe(
            map(({ data }: IResponseSuccess<Reservation[]>) =>
              fromActions.loadReservationsSuccess({ reservations: data }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadReservationsFailure(error));
            }),
          );
      }),
    ),
  );

  loadReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadReservationRequest),
      switchMap(({ reservationId, autoSelectedReservationAccommodationId }) => {
        return this.addBillModalApiService.loadReservation(reservationId).pipe(
          map(({ data }: IResponseSuccess<Reservation[]>) =>
            fromActions.loadReservationSuccess({
              reservation: data[0],
              autoSelectedReservationAccommodationId,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadReservationFailure(error));
          }),
        );
      }),
    ),
  );

  loadAvailableAddons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAvailableAddonsRequest),
      withLatestFrom(this.appStore),
      switchMap(
        ([{ reservationAccommodationId, customerId }, { addBillModal }]) => {
          return this.addBillModalApiService
            .loadAvailableAddons(
              reservationAccommodationId,
              this.dateFormatter.toServerFormat(addBillModal.referenceDate),
              customerId,
            )
            .pipe(
              map(({ data }: IResponseSuccess<AddBillModalAvailableAddon[]>) =>
                fromActions.loadAvailableAddonsSuccess({
                  availableAddons: data,
                }),
              ),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.loadAvailableAddonsFailure(error));
              }),
            );
        },
      ),
    ),
  );

  loadAccommodationBills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAccommodationBillsRequest),
      switchMap(({ reservationId, reservationAccommodationsId }) => {
        return this.addBillModalApiService
          .loadAccommodationBills(reservationId, reservationAccommodationsId)
          .pipe(
            map(({ data }: IResponseSuccess<IBillsCategory>) =>
              fromActions.loadAccommodationBillsSuccess({
                accommodationBills: data,
              }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadAccommodationBillsFailure(error));
            }),
          );
      }),
    ),
  );

  verifyAddon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.verifyAddonRequest),
      withLatestFrom(this.appStore),
      mergeMap(([{ addon }, { addBillModal }]) => {
        return this.addBillModalApiService
          .verifyAddon(
            addBillModal.selectedReservationAccommodation
              .reservation_accommodation_id,
            addon,
          )
          .pipe(
            map(({ data }: IResponseSuccess<{ warnings?: string[] }>) => {
              if (!data.warnings?.length) {
                this.appStore.dispatch(
                  fromActions.createAddonRequest({ addon }),
                );
              } else {
                const modal = this.modalService.create<
                  AddBillModalAddonWarningsComponent,
                  Partial<AddBillModalAddonWarningsComponent>
                >({
                  nzContent: AddBillModalAddonWarningsComponent,
                  nzData: {
                    addon,
                    warnings: data.warnings,
                  },
                  nzBodyStyle: { 'padding-top': '0px' },
                  nzFooter: [
                    {
                      label: upperFirst(this.translate.instant('cancel')),
                      type: 'default',
                      onClick: () => modal.close(),
                    },
                    {
                      label: upperFirst(this.translate.instant('confirm')),
                      type: 'primary',
                      onClick: () => {
                        this.appStore.dispatch(
                          fromActions.createAddonRequest({ addon }),
                        );
                        modal.close();
                      },
                    },
                  ],
                });
              }

              return fromActions.verifyAddonSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.verifyAddonFailure(error));
            }),
          );
      }),
    ),
  );

  createAddon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createAddonRequest),
      withLatestFrom(this.appStore),
      mergeMap(
        ([
          { addon, onSuccess },
          {
            addBillModal: { selectedReservationAccommodation },
          },
        ]) => {
          return this.addBillModalApiService
            .createAddon(addon, selectedReservationAccommodation)
            .pipe(
              mergeMap(({ data }: IResponseSuccess) => {
                this.notifications.createSuccess();

                if (onSuccess) {
                  onSuccess();
                }

                if (data?.payments_created?.length) {
                  data?.payments_created.forEach((paymentId) => {
                    this.exportService.directExport(
                      `payment/${paymentId}/print?type=single_copy`,
                    );
                  });
                }

                return [
                  fromActions.createAddonSuccess({
                    addonId: addon.addon_id,
                    createdBillsId: data?.bills_created || [],
                  }),
                  fromActions.loadAccommodationBillsRequest({
                    reservationId:
                      selectedReservationAccommodation.reservation_id,
                    reservationAccommodationsId:
                      selectedReservationAccommodation.all_reservation_accommodations_id,
                  }),
                ];
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(
                  fromActions.createAddonFailure({
                    ...error,
                    addonId: addon.addon_id,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );

  updateAddon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAddonRequest),
      withLatestFrom(this.appStore),
      switchMap(
        ([
          { addon, isCreationWithMerge, onSuccess },
          {
            addBillModal: { selectedReservationAccommodation },
          },
        ]) => {
          return this.addBillModalApiService
            .updateAddon(addon, selectedReservationAccommodation)
            .pipe(
              mergeMap((response: IResponseSuccess) => {
                if (!this.errorHandler.handleWarnings(response)) {
                  isCreationWithMerge
                    ? this.notifications.createSuccess()
                    : this.notifications.updateSuccess();
                }

                if (onSuccess) {
                  onSuccess();
                }

                return [
                  fromActions.updateAddonSuccess(),
                  fromActions.loadAccommodationBillsRequest({
                    reservationId:
                      selectedReservationAccommodation.reservation_id,
                    reservationAccommodationsId:
                      selectedReservationAccommodation.all_reservation_accommodations_id,
                  }),
                ];
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(
                  fromActions.updateAddonFailure({
                    ...error,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );

  deleteBills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteBillsRequest),
      withLatestFrom(this.appStore),
      switchMap(
        ([
          { billsIds },
          {
            addBillModal: { selectedReservationAccommodation },
          },
        ]) => {
          return this.addBillModalApiService
            .deleteBills(billsIds, selectedReservationAccommodation)
            .pipe(
              mergeMap(() => {
                this.notifications.updateSuccess();
                return [
                  fromActions.deleteBillsSuccess({ billsIds }),
                  fromActions.loadAccommodationBillsRequest({
                    reservationId:
                      selectedReservationAccommodation.reservation_id,
                    reservationAccommodationsId:
                      selectedReservationAccommodation.all_reservation_accommodations_id,
                  }),
                ];
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.deleteBillsFailure(error));
              }),
            );
        },
      ),
    ),
  );

  loadVatQuotesSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadVatQuotesSettingsRequest),
      switchMap(({ propertiesIds }) => {
        return this.vatQuotesSettingsService.load(propertiesIds).pipe(
          map(({ data }: IResponseSuccess<Dictionary<VatQuotesSettings>>) => {
            return fromActions.loadVatQuotesSettingsSuccess({
              vatQuotesSettings: data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadVatQuotesSettingsFailure(error));
          }),
        );
      }),
    ),
  );

  loadPlaceVatQuotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPlaceVatQuotesRequest),
      switchMap(({ place_id, pagination }) => {
        return this.placeVatQuoteService.load(place_id, pagination).pipe(
          map(({ data }: IResponseSuccess<PlaceVatQuote[]>) => {
            return fromActions.loadPlaceVatQuotesSuccess({
              placeVatQuotes: data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadPlaceVatQuotesFailure(error));
          }),
        );
      }),
    ),
  );

  loadBillsDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadBillsDepartmentsRequest),
      switchMap(({ propertiesIds }) => {
        return this.billsDepartmentsService
          .read(propertiesIds, { production_type: 'extra' })
          .pipe(
            map(({ data }: IResponseSuccess<BillDepartment[]>) => {
              return fromActions.loadBillsDepartmentsSuccess({
                billsDepartments: data,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadBillsDepartmentsFailure(error));
            }),
          );
      }),
    ),
  );

  loadChildrenRanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadChildrenRangesRequest),
      switchMap(({ propertiesIds }) => {
        return this.childrenRangesService.load(propertiesIds).pipe(
          map(({ data }: IResponseSuccess<ChildrenRange[]>) => {
            return fromActions.loadChildrenRangesSuccess({
              childrenRanges: data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadChildrenRangesFailure(error));
          }),
        );
      }),
    ),
  );

  loadPayingCustomerForVariusCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPayingCustomerForVariousCategoryRequest),
      switchMap(({ reservationId, reservationAccommodationId }) => {
        return this.addBillModalApiService
          .loadPayingCustomerForVariousCategory(
            reservationId,
            reservationAccommodationId,
          )
          .pipe(
            map(({ data }: IResponseSuccess<PayingCustomer[]>) => {
              return fromActions.loadPayingCustomerForVariousCategorySuccess({
                payingCustomerForVariousCategory: data[0],
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                fromActions.loadPayingCustomerForVariousCategoryFailure(error),
              );
            }),
          );
      }),
    ),
  );
}
