import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import moment from 'moment';

import { ICheckinOut } from '../../core/models/api/generics/checkin-out/checkin-out.model';

@Component({
  selector: 'by-arrivals-departures-progress',
  templateUrl: './arrivals-departures-progress.component.html',
  styleUrls: ['./arrivals-departures-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrivalsDeparturesProgressComponent implements OnInit, OnChanges {
  @Input()
  checkInOut: ICheckinOut;

  arrivals = 0;

  checkIns = 0;

  departures = 0;

  checkOuts = 0;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const { checkInOut } = changes;

    if (!checkInOut || !this.checkInOut) {
      return;
    }

    ({
      accommodation_arrival: this.arrivals,
      accommodation_arrival_checkin: this.checkIns,
      accommodation_departure: this.departures,
      accommodation_departure_checkout: this.checkOuts,
    } = this.checkInOut[moment().format('YYYY-MM-DD')]);
  }

  get percentageOfArrivals() {
    return this.arrivals > 0 ? (this.checkIns / this.arrivals) * 100 : 100;
  }

  get percentageOfDepartures() {
    return this.departures > 0 ? (this.checkOuts / this.departures) * 100 : 100;
  }
}
