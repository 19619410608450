export interface State {
  data: {
    guests: any[];
    rooms: any[];
  };
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  data: {
    guests: [],
    rooms: [],
  },
  isLoading: false,
  error: null,
};
