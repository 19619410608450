<div class="by-p-20">
  <div>
    <by-page-header
      [byStyle]="{ 'padding-top': 0 }"
      [title]="'custom_menu_link' | translate | upperfirst"
    >
      <div
        (click)="openModalEditCustomLink()"
        nz-button
        nzType="link"
        class="color--azure by-mt-5"
      >
        {{ 'edit' | translate | upperfirst }}
      </div></by-page-header
    >
    <ng-container *ngIf="(customLinks$ | async).length > 0; else noCustomLink">
      <by-custom-link-mobile-bar
        [customLinks]="customLinks$ | async"
        (closeBottomSheet)="closeBottomSheet.emit()"
      ></by-custom-link-mobile-bar>
    </ng-container>
    <ng-template #noCustomLink>
      <div class="by-flex by-justify-content--center">
        <span class="color--grey-light">
          {{ 'no_link_custom_set' | translate | upperfirst }}
        </span>
      </div>
    </ng-template>
  </div>

  <div *ngIf="(bookingEngineLinks$ | async).length > 0">
    <by-page-header
      [title]="'bol' | translate | upperfirst"
      [byStyle]="{ 'padding-top': 10 }"
    ></by-page-header>
    <by-booking-engine-mobile-bar
      [bookingEngineLinks]="bookingEngineLinks$ | async"
      (goToDns)="goToBookingEngine($event)"
    ></by-booking-engine-mobile-bar>
  </div>
  <div>
    <div class="by-pl-40 by-pr-40 by-pt-10">
      <nz-divider></nz-divider>
    </div>

    <by-language-mobile-bar
      [languages]="languages"
      [currentSelectLanguage]="currentSelectLanguage$ | async"
      (changeLanguage)="changeLanguage.emit($event)"
    ></by-language-mobile-bar>
  </div>
</div>
