import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { SafeHtmlPipe } from '../safe-html/safe-html.pipe';

@Pipe({
  name: 'htmlSanitizer',
})
export class HtmlSanitizerPipe implements PipeTransform {
  private safeHtmlPipe: SafeHtmlPipe;

  constructor(private sanitizer: DomSanitizer) {
    this.safeHtmlPipe = new SafeHtmlPipe(this.sanitizer);
  }

  transform(value: string, byPass: boolean = false) {
    if (byPass) {
      return this.safeHtmlPipe.transform(value);
    }

    return this.sanitizer.sanitize(SecurityContext.HTML, value);
  }
}
