<div *nzModalTitle>
  <div class="mobile-header">
    <by-add-bill-modal-date
      [selectedDate]="referenceDate$ | async"
      [config]="config$ | async"
      (changeDate)="onChangeReferenceDate($event)"
    ></by-add-bill-modal-date>
  </div>
</div>
<div class="add-bill-modal">
  <div class="add-bill-modal__left-sidebar">
    <by-add-bill-modal-date
      class="desktop-item"
      [selectedDate]="referenceDate$ | async"
      [config]="config$ | async"
      (changeDate)="onChangeReferenceDate($event)"
    ></by-add-bill-modal-date>

    <by-add-bill-modal-accommodations
      [reservationAccommodations]="reservationAccommodations$ | async"
      [selectedReservationAccommodation]="selectedReservationAccommodation"
      [selectedGuest]="selectedGuest$ | async"
      (selectReservationAccommodation)="
        onSelectReservationAccommodation($event)
      "
      [config]="config$ | async"
      [loading]="accommodationsLoading$ | async"
      (selectGuest)="onSelectGuest($event)"
    ></by-add-bill-modal-accommodations>

    <by-add-bill-modal-available-addons
      [availableAddons]="availableAddons$ | async"
      [currencySymbol]="currencySymbol"
      [estimates]="estimates$ | async"
      [selectedReservationAccommodation]="selectedReservationAccommodation"
      [vatQuotes]="vatQuotes$ | async"
      [childrenRanges]="childrenRanges$ | async"
      [payingCustomerForVariousCategory]="
        payingCustomerForVariousCategory$ | async
      "
      [selectedGuest]="selectedGuest$ | async"
      [referenceDate]="referenceDate$ | async"
      [creatingLoading]="creatingLoading$ | async"
      [vatQuotesSettings]="
        vatQuotesSettings$
          | async
          | get: selectedReservationAccommodation?.property_id
      "
      [billsDepartments]="billsDepartments$ | async"
      (estimateAddon)="onEstimateAddon($event)"
      (addAddon)="onAddAddon($event)"
      (createAddon)="onCreateAddon($event)"
    ></by-add-bill-modal-available-addons>
  </div>
  <div class="add-bill-modal__content">
    <div
      class="add-bill-modal__content-header"
      [ngStyle]="{ width: contentWidth + 'px' }"
    >
      <by-page-header
        title="{{ 'bills' | translate | upperfirst }} - {{
          'accommodation' | translate | upperfirst
        }} {{ selectedReservationAccommodation?.tableau_label }}"
        [byStyle]="{ marginBottom: 0 }"
      ></by-page-header>
      <span class="add-bill-modal__close-button" (click)="onCloseModal()">
        <i class="far fa-times"></i>
      </span>
    </div>

    <by-spin [nzSpinning]="loading$ | async">
      <by-add-bill-modal-accommodation-bills
        [accommodationBills]="accommodationBills"
        [selectedAccommodation]="selectedReservationAccommodation"
        [currencySymbol]="currencySymbol"
        [createdBillsId]="createdBillsId$ | async"
        (deleteBills)="onDeleteBills($event)"
        (editAddon)="onOpenEditingDrawer($event)"
        (createOrdination)="onCreateOrdination($event)"
      ></by-add-bill-modal-accommodation-bills>
    </by-spin>
  </div>

  <by-add-bill-modal-editing-drawer
    [selectedReservationAccommodation]="selectedReservationAccommodation"
    [vatQuotes]="vatQuotes$ | async"
    [childrenRanges]="childrenRanges$ | async"
    [currencySymbol]="currencySymbol"
    (editAddon)="onEditAddon($event)"
  ></by-add-bill-modal-editing-drawer>
</div>
