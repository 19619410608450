import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_REQUEST = '[Global Search] Load Request',
  LOAD_FAILURE = '[Global Search] Load Failure',
  LOAD_SUCCESS = '[Global Search] Load Success',

  RESET_STATE = '[Global Search] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(
    public payload: { value: string; now: string; propertiesIds: number[] },
  ) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { data: any }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | ResetSuccessAction;
