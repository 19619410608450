import { calcDiscount, removeDiscount } from './calc-discount';

export class BillFormHelpers {
  static getUnitPrice(
    total: number,
    qty: number,
    discountTypeId: number = 4,
    discountValue: number = 0,
  ): number {
    const originalTotal =
      discountTypeId === 4
        ? total + discountValue
        : removeDiscount(total, discountValue);

    return originalTotal / (qty || 1);
  }

  static getTotalPrice(
    unitPrice: number,
    qty: number,
    discountTypeId: number = 4,
    discountValue: number = 0,
  ): number {
    const totalWithoutDiscount = unitPrice * (qty || 1);

    return discountTypeId === 4
      ? totalWithoutDiscount - discountValue
      : totalWithoutDiscount -
          calcDiscount(totalWithoutDiscount, discountValue);
  }

  static getDiscountedValue(
    originalPrice: number,
    discountTypeId: number = 4,
    discountValue: number = 0,
  ): number {
    return discountTypeId === 4
      ? originalPrice - discountValue
      : originalPrice - calcDiscount(originalPrice, discountValue);
  }
}
