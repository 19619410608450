import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  AddBillModalAccommodation,
  AddBillModalAccommodationGuest,
  AddBillModalConfig,
} from '../../../../models';

@Component({
  selector: 'by-add-bill-modal-accommodations',
  templateUrl: './add-bill-modal-accommodations.component.html',
  styleUrls: ['./add-bill-modal-accommodations.component.scss'],
})
export class AddBillModalAccommodationsComponent implements OnInit {
  @Input() reservationAccommodations: AddBillModalAccommodation[];

  @Input() selectedReservationAccommodation: AddBillModalAccommodation;

  @Input() selectedGuest: AddBillModalAccommodationGuest;

  @Input() config: AddBillModalConfig;

  @Input() loading: boolean;

  @Output() selectReservationAccommodation = new EventEmitter<
    AddBillModalAccommodation
  >();

  @Output() selectGuest = new EventEmitter<AddBillModalAccommodationGuest>();

  searchValue: string;

  constructor() {}

  ngOnInit() {}

  onSelectReservationAccommodation(reservationAccommodationId: number) {
    const selectedReservationAccommodation = this.reservationAccommodations.find(
      ({ reservation_accommodation_id }) =>
        reservation_accommodation_id === reservationAccommodationId,
    );

    this.selectReservationAccommodation.emit(selectedReservationAccommodation);
  }

  onSelectGuest(customerId: number) {
    const selectedGuest = this.selectedReservationAccommodation.guests.find(
      ({ id }) => customerId === id,
    );

    this.selectGuest.emit(selectedGuest);
  }
}
