import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { GlobalSearchService } from '../../services';

import * as featureActions from './actions';

@Injectable()
export class GlobalSearchStoreEffects {
  constructor(
    private dataService: GlobalSearchService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) => {
        const { value, now, propertiesIds } = action.payload;
        return this.dataService.load(value, now, propertiesIds).pipe(
          map(
            ({ data }: any) => new featureActions.LoadSuccessAction({ data }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        );
      }),
    ),
  );
}
