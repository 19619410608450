import { Component, Input, signal, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingWidget } from '../../../../models';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NzGridModule } from 'ng-zorro-antd/grid';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'by-booking-engine-mobile-bar',
  standalone: true,
  imports: [
    CommonModule,
    NzTagModule,
    RouterModule,
    NzButtonModule,
    NgUpperFirstPipeModule,
    TranslateModule,
    NzGridModule,
  ],
  templateUrl: './booking-engine-mobile-bar.component.html',
  styleUrls: ['./booking-engine-mobile-bar.component.scss'],
})
export class BookingEngineMobileBarComponent {
  @Input() bookingEngineLinks: BookingWidget[];

  @Output() goToDns = new EventEmitter<string>();

  showAll = false;

  toggleShowAll() {
    this.showAll = !this.showAll;
  }
}
