<div class="menu-container">
  <div class="info-group-data mobile">
    <span class="logo-hotel-immage mobile">
      <div class="logo-container mobile">
        <div class="logo mobile">
          <ng-container
            *ngIf="
              (allGroups?.length > 1 && allProperties?.length > 1) ||
              allProperties?.length > 1
                ? groupLogo?.media
                : logoProperty as logoPrint;
              else noLogo
            "
          >
            <div
              class="image-logo mobile"
              [ngStyle]="{
                'background-image': 'url(' + logoPrint + ')'
              }"
            ></div>
          </ng-container>
          <ng-template #noLogo>
            <span class="logo-hotel mobile">
              <span class="logo-text mobile">{{
                ((allGroups?.length > 1 && allProperties?.length > 1) ||
                allProperties?.length > 1
                  ? selectedGroup?.name
                  : selectedProperty?.name)
                  | truncate: 1
                  | upperfirst
              }}</span>
            </span></ng-template
          >
        </div>
      </div>
    </span>
    <div class="info-container">
      <span class="property-name"> {{ groupPropertyName }} </span>
      <span class="user-name"> {{ userFullName }} </span>
    </div>
  </div>

  <ng-container *ngIf="allGroups?.length > 1; else propertySelect">
    <div class="select-container">
      <nz-select
        [nzDisabled]="isGroupSelectDisabled"
        (ngModelChange)="onChangeGroupSelected($event)"
        nzSize="small"
        style="width: 90%;"
        class="by-mt-2"
        [ngModel]="selectedGroup?.id"
      >
        <nz-option
          *ngFor="let group of allGroups"
          [nzValue]="group.id"
          [nzLabel]="group.name"
        >
        </nz-option>
      </nz-select>
    </div>
  </ng-container>

  <ng-template #propertySelect>
    <ng-container *ngIf="allProperties?.length > 1">
      <nz-select
        (ngModelChange)="onChangePropertySelected($event)"
        nzSize="small"
        style="width: 90%;"
        class="by-mt-2"
        [ngModel]="selectedProperty?.id"
      >
        <nz-option
          *ngFor="let property of allProperties"
          [nzValue]="property.id"
          [nzLabel]="property.name"
        >
        </nz-option>
      </nz-select>
    </ng-container>
  </ng-template>
</div>
