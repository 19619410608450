import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageMobileBarComponent } from '../language-mobile-bar/language-mobile-bar.component';

import { BookingEngineMobileBarComponent } from '../booking-engine-mobile-bar/booking-engine-mobile-bar.component';
import { PageHeaderModule } from '../../../../components/page-header/page-header.module';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { ILanguage } from '../../../models/api/generics/languages/language.model';
import { RootState } from '../../../../root-store/root-state';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, map } from 'rxjs';
import { UserMeStoreSelectors } from '../../../../root-store/user-me-store';
import { UserPreferencesStoreSelectors } from '../../../../root-store/user-preferences-store';
import { isNil, omitBy, sortBy, values } from 'lodash';
import { CustomLink } from '../../../../models/objects/custom-link';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { CustomMenuFormService } from '../../../services/custom-menu-form.service';
import { CustomLinkMobileBarComponent } from '../custom-link-mobile-bar/custom-link-mobile-bar.component';
import { BookingWidgetsLinksStoreSelectors } from '../../../../root-store/booking-widgets-links-store';
import { environment } from '../../../../../environments/environment';
import { openInNewTab } from '../../../../helpers';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'by-mobile-bar-container',
  standalone: true,
  imports: [
    CommonModule,
    LanguageMobileBarComponent,
    CustomLinkMobileBarComponent,
    BookingEngineMobileBarComponent,
    PageHeaderModule,
    NgUpperFirstPipeModule,
    TranslateModule,
    NzButtonModule,
    NzDividerModule,
  ],
  templateUrl: './mobile-bar-container.component.html',
  styleUrls: ['./mobile-bar-container.component.scss'],
})
export class MobileBarContainerComponent implements OnInit, OnDestroy {
  @Input() languages: ILanguage[];

  @Output() changeLanguage = new EventEmitter<ILanguage['iso_code']>();

  @Output() closeBottomSheet = new EventEmitter();

  currentSelectLanguage$ = this.store.select(
    UserMeStoreSelectors.selectLanguage,
  );

  customLinks$: Observable<CustomLink[]> = this.store.pipe(
    select(
      UserPreferencesStoreSelectors.selectUserPreferencesDataByCategory(
        'customLinkMenu',
      ),
    ),
    map((response) => sortBy(values(omitBy(response, isNil)), 'order')),
  );

  bookingEngineLinks$ = this.store.select(
    BookingWidgetsLinksStoreSelectors.selectAllItems,
  );

  currentSelectLanguage: string = '';

  subs = new Subscription();

  constructor(
    private store: Store<RootState>,
    private customLinkModal: CustomMenuFormService,
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.currentSelectLanguage$.subscribe(
        (value) => (this.currentSelectLanguage = value),
      ),
    );
  }

  openModalEditCustomLink() {
    this.customLinkModal.createComponentModal();
  }

  goToBookingEngine(dns: string) {
    const url = `https://${dns}.beddy.${
      environment.production ? 'io' : 'dev'
    }?lang=${this.currentSelectLanguage}`;

    openInNewTab(url, '_blank');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
