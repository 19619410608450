<form nz-form class="bill-form__horizontal" [formGroup]="form">
  <div nz-row>
    <div nz-col nzSm="24">
      <nz-form-item>
        <nz-form-label>
          {{ 'guest' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-select formControlName="customer_buyer_id" class="by-w-100">
            <nz-option
              *ngFor="let guest of guests || []"
              [nzValue]="guest.id"
              [nzLabel]="guest.name"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row>
    <div nz-col nzSm="24">
      <nz-form-item>
        <nz-form-label>
          {{ 'buyer' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <by-customers-lookup
            [searchIn]="['customers', 'companies']"
            formControlName="customer_id"
            [optionClassFn]="customerOptionsClass"
            [optionsSortFn]="customerOptionsSort"
          ></by-customers-lookup>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<form
  nz-form
  class="bill-form__vertical"
  nzLayout="vertical"
  [formGroup]="form"
>
  <div nz-row nzGutter="10">
    <div nz-col nzSm="12">
      <nz-form-item>
        <nz-form-label>
          {{ 'date' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-date-picker
            byDisabledMobileKeyboard
            byDisabledDateFixer
            class="by-w-100"
            [ngStyle]="{ width: '100%' }"
            formControlName="date"
            nzFormat="dd/MM/yyyy"
            [nzDisabled]="dateAndQuantityAreDisabled"
            [nzDisabledDate]="disabledDate"
            [nzAllowClear]="false"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSm="12" *ngIf="showQuantityAndUnitPrice">
      <nz-form-item>
        <nz-form-label>
          {{ 'quantity' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-input-number
            class="by-w-100"
            [nzStep]="1"
            [nzMin]="1"
            [nzDisabled]="dateAndQuantityAreDisabled"
            byDecimalOnly
            formControlName="qty"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSm="12" *ngIf="showQuantityAndUnitPrice">
      <nz-form-item>
        <nz-form-label>
          {{ 'rate' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-input-number
            class="by-w-100"
            [nzStep]="1"
            [nzMin]="1"
            [nzFormatter]="currencyFormatter"
            byDecimalOnly
            formControlName="base_price"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSm="12" *ngIf="showAdultsAndChildren">
      <nz-form-item>
        <nz-form-label>
          {{ 'adults' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-input-number
            class="by-w-100"
            [nzStep]="1"
            [nzMin]="1"
            byDecimalOnly
            formControlName="adults"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSm="12" *ngIf="showAdultsAndChildren">
      <nz-form-item>
        <nz-form-label>
          {{ 'children' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-input-number
            class="by-w-100"
            [nzStep]="1"
            [nzMin]="0"
            byDecimalOnly
            formControlName="children_number"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>

    <ng-container
      *ngIf="showAdultsAndChildren && childrenControl.controls?.length"
    >
      <ng-container
        formArrayName="children"
        *ngFor="
          let childForm of childrenControl.controls;
          let childIndex = index
        "
      >
        <ng-container [formGroupName]="childIndex">
          <div nz-col nzSm="12">
            <nz-form-item>
              <nz-form-label>
                {{
                  ('age' | translate | upperfirst) +
                    ' ' +
                    ('child' | translate) +
                    ' ' +
                    (childIndex + 1)
                }}
              </nz-form-label>
              <nz-form-control>
                <nz-select
                  class="by-w-100"
                  [nzDropdownMatchSelectWidth]="false"
                  nzPlaceHolder="{{
                    'placeholder.select'
                      | translate: { param: 'child' | translate }
                  }}"
                  formControlName="property_children_range_id"
                >
                  <ng-container>
                    <nz-option
                      *ngFor="let range of childrenRanges"
                      nzLabel="{{ range.combination?.from }} - {{
                        range.combination?.to
                      }}"
                      [nzValue]="range.id"
                    ></nz-option>
                  </ng-container>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <div nz-col nzSm="12">
      <nz-form-item>
        <nz-form-label>
          {{ 'discount' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-input-group nzCompact class="discount-input-group">
            <nz-select formControlName="discount_type_id" [nzShowArrow]="false">
              <nz-option [nzValue]="4" [nzLabel]="currencySymbol"></nz-option>
              <nz-option [nzValue]="5" nzLabel="%"></nz-option>
            </nz-select>
            <nz-input-number
              byDecimalOnly
              formControlName="discount_value"
              [nzMin]="0"
              [nzMax]="
                form.value.discount_type_id === 4 ? priceWithoutDiscount : 100
              "
              [nzStep]="1"
            ></nz-input-number>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSm="12">
      <nz-form-item>
        <nz-form-label>
          {{ 'vat' | translate | uppercase }}
        </nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="vat_quote_id"
            class="by-w-100"
            [nzDropdownMatchSelectWidth]="false"
          >
            <nz-option
              *ngFor="let vatQuote of vatQuotes"
              [nzValue]="vatQuote.id"
              nzLabel="[{{ vatQuote.value }}] {{ vatQuote.description }}"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSm="12">
      <by-spin [nzSpinning]="loadingPrice$ | async">
        <nz-form-item>
          <nz-form-label>
            {{ 'total' | translate | capitalize }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="controlErrorsTip">
            <nz-input-number
              class="by-w-100"
              [nzStep]="1"
              [nzMin]="0"
              [nzFormatter]="currencyFormatter"
              byDecimalOnly
              formControlName="price"
            ></nz-input-number>
          </nz-form-control>
          <ng-template #controlErrorsTip let-control>
            <ng-container *ngIf="control.hasError('min')">
              {{ 'total_less_than_the_amount' | translate | upperfirst }}
            </ng-container>
          </ng-template>
        </nz-form-item>
      </by-spin>
    </div>
    <div
      nz-col
      nzSm="12"
      *ngIf="
        bill?.price_type &&
        !(showPeriodConditionMapConstant | get: [bill?.price_type])
      "
    >
      <nz-form-item>
        <nz-form-label>
          {{ 'paid' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-input-number
            class="by-w-100"
            [nzStep]="1"
            [nzMin]="0"
            [nzFormatter]="currencyFormatter"
            byDecimalOnly
            formControlName="deposit"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
