import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  generateParamArray,
  generateSearchQuery,
} from '../core/helpers/params-generator';
import { DateFormatterService } from '../core/services/date-formatter.service';
import { AddBillModalAccommodation } from '../models';
import { AddBillModalAddon } from '../models/objects/add-bill-modal-addon';
import { SearchParams } from '../models/objects/search-params';
import { EditBillsRequest } from '../models/requests/edit-bills.request';
import { getPayloadFromAddons } from '../root-store/add-bill-modal-store/helpers';

@Injectable({
  providedIn: 'root',
})
export class AddBillModalApiService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  loadReservations(searchParams: SearchParams) {
    return this.http.get('reservations?' + generateSearchQuery(searchParams));
  }

  loadReservation(reservationId: number) {
    return this.http.get(`reservation/${reservationId}`);
  }

  loadAvailableAddons(
    reservationAccommodationId: number,
    reference_date: string,
    customer_id?: number,
  ) {
    return this.http.get(
      `reservation/accommodation/${reservationAccommodationId}/available_addons?${generateSearchQuery(
        {
          reference_date,
          customer_id,
        },
      )}`,
    );
  }

  loadAccommodationBills(
    reservationId: number,
    reservation_accommodation_id: number[],
  ) {
    return this.http.get(
      `reservation/${reservationId}/bills?${generateSearchQuery({
        reservation_accommodation_id,
        production_type: ['extra'],
        type: 'default',
      })}`,
      { headers: { accept: 'application/x.bdy.v1.1+json' } },
    );
  }

  verifyAddon(reservation_accommodation_id: number, addon: AddBillModalAddon) {
    return this.http.post(`addon/${addon.addon_id}/verify`, {
      date_from: this.dateFormatter.toServerFormat(addon.periods[0].date_from),
      date_to: this.dateFormatter.toServerFormat(addon.periods[0].date_to),
      now: this.dateFormatter.toServerFormat(new Date()),
      reservation_accommodation_id,
    });
  }

  createAddon(
    addon: AddBillModalAddon,
    selectedAccommodation: AddBillModalAccommodation,
  ) {
    return this.http.post(
      `reservation/${selectedAccommodation.reservation_id}/bills`,
      {
        bills: getPayloadFromAddons([addon], selectedAccommodation),
      },
    );
  }

  updateAddon(
    bills: EditBillsRequest,
    selectedAccommodation: AddBillModalAccommodation,
  ) {
    return this.http.put(
      `reservation/${selectedAccommodation.reservation_id}/bills`,
      {
        bills,
      },
    );
  }

  deleteBills(
    billsIds: number[],
    selectedAccommodation: AddBillModalAccommodation,
  ) {
    return this.http.delete(
      `reservation/${
        selectedAccommodation.reservation_id
      }/bills?${generateParamArray('reservation_bill_ids', billsIds)}`,
    );
  }

  loadPayingCustomerForVariousCategory(
    reservation_id: number,
    reservation_accommodation_id: number,
  ) {
    return this.http.get(
      `reservation/${reservation_id}/reservation_accommodation/${reservation_accommodation_id}/customer_payment_agreement?addon_category_id=1`,
    );
  }
}
