<div class="add-bill-modal__available-addon">
  <nz-collapse nzGhost>
    <nz-collapse-panel
      [(nzActive)]="expanded"
      [nzHeader]="collapseHeader"
      [nzShowArrow]="false"
      (nzActiveChange)="
        onOpenStatusChange($event);
        expandStatusChange.emit({ status: $event, addon_id: addon.addon_id })
      "
    >
      <nz-card class="card-with-shadow">
        <by-add-bill-modal-form
          [addon]="addon"
          [show]="expanded"
          [selectedReservationAccommodation]="selectedReservationAccommodation"
          [estimate]="estimate"
          [vatQuotes]="vatQuotes"
          [childrenRanges]="childrenRanges"
          [currencySymbol]="currencySymbol"
          (estimateAddon)="estimateAddon.emit($event)"
        ></by-add-bill-modal-form>
        <div nz-row [nzGutter]="20">
          <div nz-col [nzXs]="24" [nzSm]="24">
            <button
              class="by-w-100"
              nz-button
              type="button"
              nzType="primary"
              nzSize="small"
              (click)="onAddAddon()"
              [nzLoading]="!!(creatingLoading | get : addon?.addon_id)"
            >
              <i class="far fa-plus"></i>
              {{ 'add_to_cart' | translate | upperfirst }}
            </button>
          </div>
        </div>
      </nz-card>
    </nz-collapse-panel>
  </nz-collapse>
</div>

<ng-template #collapseHeader>
  <div
    class="pointer by-w-100"
    nz-row
    nzJustify="space-between"
    nzAlign="middle"
  >
    <div nz-col nzSm="12" nzXs="12">
      <div>{{ addon?.name }}</div>
      <div class="italic by-fs-12">
        <i class="fas fa-user"></i>
        {{ addon?.customer?.name }}
      </div>
      <div class="color--azure">
        {{ addon?.category_name }}
        <!-- ({{
          addon?.price_type | translate | upperfirst
        }}) -->
      </div>
    </div>
    <div nz-col nzSm="12" nzXs="12" class="text-align--end">
      <strong
        *ngIf="showQuantityAndRateConditionMapConstant[addon?.price_type]"
        >{{
          formComponent?.form.value.total | toCurrency : currencySymbol
        }}</strong
      >
      <i
        class="fal fa-pencil-alt by-fs-16 by-ml-10 color--grey-dark pointer"
      ></i>
      <button
        *ngIf="showQuantityAndRateConditionMapConstant[addon?.price_type]"
        class="by-ml-5 ant-btn-icon-only"
        nz-button
        type="button"
        nzType="primary"
        nzSize="small"
        nzShape="circle"
        [ngClass]="{
          locked: !!(creatingLoading | get : addon?.addon_id)
        }"
        (click)="$event.stopPropagation(); onAddAddon()"
      >
        <i class="far fa-plus"></i>
      </button>
    </div>
  </div>
</ng-template>
