import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { NotificationsListComponent } from '../../../components/notifications-list/notifications-list.component';
import { TokenService } from '../../services/token.service';
import { APP_ADMIN_ROUTES, ICommonRoute } from '../app.routes';
import { ILanguage } from '../../models/api/generics/languages/language.model';

@Component({
  selector: 'by-app-shell-menu',
  templateUrl: './app-shell-menu.component.html',
  styleUrls: ['./app-shell-menu.component.scss'],
})
export class AppShellMenuComponent implements OnInit {
  @Input() data: any;
  @Input() isShowHeaderRow = false;
  @Input() isShowLinks = false;
  @Input() isCollapsed = false;
  @Input() isAdmin = false;
  @Input() isShowColorLine = false;
  @Input() groupPropertyName: string;
  @Input() userFullName: string;
  @Input() isGroupSelectDisabled: boolean;
  @Input() allGroups: Array<{ id: number; name: string }>;
  @Input() allProperties: Array<{ id: number; name: string }>;
  @Input() groupLogo: any;
  @Input() selectedProperty: { id: number; name: string };
  @Input() selectedGroup: { id: number; name: string };
  @Input() logoProperty: any;
  @Input() notificationsCounter: number;
  @Input() languages: ILanguage[];

  @Output() logOutEvent: EventEmitter<{}> = new EventEmitter();
  @Output() onRemoveImpersonateEvent: EventEmitter<void> = new EventEmitter();
  @Output() changePropertyEvent: EventEmitter<number> = new EventEmitter();
  @Output() changeGroupEvent: EventEmitter<number> = new EventEmitter();
  @Output() openAddonsModal: EventEmitter<void> = new EventEmitter();
  @Output() changeLanguage = new EventEmitter<ILanguage['iso_code']>();

  @Input() commonRoutes: ICommonRoute[] = [];

  public adminRoutes = APP_ADMIN_ROUTES;

  constructor(
    private tokenService: TokenService,
    private modalService: NzModalService,
  ) {}

  ngOnInit() {}

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  public get showAdminRoutes() {
    return window.location.href.match('admin');
  }

  public get token() {
    return this.tokenService.get();
  }

  onOpenNotificationsModal() {
    this.modalService.create({
      nzContent: NotificationsListComponent,
      nzBodyStyle: { padding: '15px 0' },
      nzFooter: [],
    });
  }
}
