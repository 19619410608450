import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { PriceQuotationService, TableauRowsService } from '@app/services';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get, omitBy, uniq, upperFirst } from 'lodash';
import { isNil } from 'ng-zorro-antd/core/util';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import {
  catchError,
  exhaustMap,
  flatMap,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { IResponse } from '../../core/models/response.model';
import { CrmCartItem } from '../../features/commons/crm/models/crm-cart-item';
import { PriceQuotation } from '../../models';
import { WarningConfirmModalService } from '../../services/warning-confirm-modal.service';

import * as featureActions from './actions';

@Injectable()
export class PriceQuotationStoreEffects {
  constructor(
    private dataService: PriceQuotationService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
    private router: Router,
    private warningModalService: WarningConfirmModalService,
    private tableauRowService: TableauRowsService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService.load(action.payload.priceQuotationId).pipe(
          map(({ data }: any) => {
            let quote: PriceQuotation = data[0];
            if (quote) {
              quote = {
                ...quote,
                label: this.tableauRowService.getQuoteLabel({
                  booker_name: quote.customer.name,
                  booker_surname: quote.customer.surname,
                  company: quote.company,
                }),
              };
            }
            return new featureActions.LoadSuccessAction({
              item: quote,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap((action: featureActions.UpdateRequestAction) => {
        const { priceQuotationId, data, customer, company } = action.payload;
        return this.dataService.update(priceQuotationId, data).pipe(
          map((response: IResponseSuccess) => {
            const shouldConfirm = get(response, 'meta.confirm_required');
            const warnings = get(response, 'meta.warnings');
            const accommodationsIds = Object.keys(warnings || {});

            const availabilityWarning = accommodationsIds.find((id) => {
              const warning = warnings[id];
              return (
                !warning.all_right && (!warning.availability || warning.closed)
              );
            });

            if (shouldConfirm) {
              if (availabilityWarning) {
                this.warningModalService.open({
                  message: this.translate.instant(
                    'price_quotation_confirm_warning',
                  ),
                  action: new featureActions.UpdateRequestAction({
                    ...action.payload,
                    data: {
                      ...data,
                      force_operation: true,
                    },
                  }),
                });
                return new featureActions.UpdateFailureAction({
                  error: null,
                });
              }
              return new featureActions.UpdateRequestAction({
                ...action.payload,
                data: {
                  ...data,
                  force_operation: true,
                },
              });
            }
            this.notifications.push({
              title: upperFirst(this.translate.instant('done')),
              content: upperFirst(
                this.translate.instant('notifications.update_success', {
                  param: this.translate.instant('price_quotation'),
                }),
              ),
              type: 'success',
            });
            this.router.navigate([`price-quotation/${priceQuotationId}`]);
            return new featureActions.UpdateSuccessAction({
              customer,
              company,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.UpdateFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  sendEmailRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SendEmailRequestAction>(
        featureActions.ActionTypes.SEND_EMAIL_REQUEST,
      ),
      switchMap((action: featureActions.SendEmailRequestAction) =>
        this.dataService
          .sendEmail(
            action.payload.priceQuotationId,
            action.payload.email_addresses,
          )
          .pipe(
            map(() => {
              this.notifications.push({
                title: upperFirst(this.translate.instant('done')),
                content: upperFirst(this.translate.instant('email_sended')),
                type: 'success',
              });
              return new featureActions.SendEmailSuccessAction();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.SendEmailFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  updateCartItemRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateCartItemRequest>(
        featureActions.ActionTypes.UPDATE_CART_ITEM_REQUEST,
      ),
      mergeMap((action: featureActions.UpdateCartItemRequest) => {
        const { priceQuotationId, cartId, cartItemId, cartItem } =
          action.payload;
        return this.dataService
          .updateCartItem(
            priceQuotationId,
            cartId,
            cartItemId,
            omitBy(cartItem, (value) => isNil(value)) as CrmCartItem,
          )
          .pipe(
            map((response: IResponseSuccess<CrmCartItem>) => {
              const newCartItem = get(
                response,
                ['data', 0, 'accommodation'],
                {},
              );
              this.notifications.push({
                title: upperFirst(this.translate.instant('done')),
                content: upperFirst(
                  this.translate.instant('notifications.update_success', {
                    param: this.translate.instant('price_quotation'),
                  }),
                ),
                type: 'success',
              });
              return new featureActions.UpdateCartItemSuccess({
                priceQuotationId,
                cartId,
                cartItemId,
                cartItem: newCartItem,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.UpdateCartItemFailure({ error }));
            }),
          );
      }),
    ),
  );

  addRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.AddCartItemRequest>(
        featureActions.ActionTypes.ADD_CART_ITEM_REQUEST,
      ),
      flatMap((action: featureActions.AddCartItemRequest) => {
        const {
          priceQuotationId,
          cartId,
          cartItem,
          disableNotify,
          onClickSuccessNotification,
        } = action.payload;
        return this.dataService
          .addCartItem(priceQuotationId, cartId, cartItem)
          .pipe(
            map(({ data, meta }: any) => {
              if (!disableNotify && !meta.warnings?.length) {
                this.notifications.push({
                  title: upperFirst(this.translate.instant('done')),
                  content: upperFirst(
                    this.translate.instant('add_item_to_cart'),
                  ),
                  type: 'success',
                  onClick: onClickSuccessNotification,
                });
              }

              if (meta.warnings?.length) {
                uniq<string>(meta.warnings).forEach((warning) =>
                  this.notifications.push({
                    title: upperFirst(
                      this.translate.instant('notifications.generic_success'),
                    ),
                    content: upperFirst(warning),
                    type: 'warning',
                  }),
                );
              }

              return new featureActions.AddCartItemSuccess({
                cartItems: data,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.AddCartItemFailure({ error }));
            }),
          );
      }),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteCartItemRequest>(
        featureActions.ActionTypes.DELETE_CART_ITEM_REQUEST,
      ),
      flatMap((action: featureActions.DeleteCartItemRequest) => {
        const { priceQuotationId, cartId, cartItemId } = action.payload;
        return this.dataService
          .deleteCartItem(priceQuotationId, cartId, cartItemId)
          .pipe(
            map(() => {
              this.notifications.push({
                title: upperFirst(this.translate.instant('done')),
                content: upperFirst(
                  this.translate.instant('notifications.update_success', {
                    param: this.translate.instant('price_quotation'),
                  }),
                ),
                type: 'success',
              });
              return new featureActions.DeleteCartItemSuccess({
                cartId,
                cartItemId,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteCartItemFailure({ error }));
            }),
          );
      }),
    ),
  );

  addCartRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.AddCartRequest>(
        featureActions.ActionTypes.ADD_CART_REQUEST,
      ),
      switchMap((action: featureActions.AddCartRequest) => {
        const { priceQuotationId } = action.payload;
        return this.dataService.addCart(priceQuotationId).pipe(
          map(({ data }: any) => {
            this.notifications.push({
              title: upperFirst(this.translate.instant('done')),
              content: upperFirst(
                this.translate.instant('notifications.update_success', {
                  param: this.translate.instant('price_quotation'),
                }),
              ),
              type: 'success',
            });
            return new featureActions.AddCartSuccess({ cart: data[0] });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.AddCartFailure({ error }));
          }),
        );
      }),
    ),
  );

  deleteCartRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteCartRequest>(
        featureActions.ActionTypes.DELETE_CART_REQUEST,
      ),
      flatMap((action: featureActions.DeleteCartRequest) => {
        const { priceQuotationId, cartId } = action.payload;
        return this.dataService.deleteCart(priceQuotationId, cartId).pipe(
          map(() => {
            this.notifications.push({
              title: upperFirst(this.translate.instant('done')),
              content: upperFirst(
                this.translate.instant('notifications.update_success', {
                  param: this.translate.instant('price_quotation'),
                }),
              ),
              type: 'success',
            });
            return new featureActions.DeleteCartSuccess({ cartId });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.DeleteCartFailure({ error }));
          }),
        );
      }),
    ),
  );

  confirmOptionRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.ConfirmOptionRequest>(
        featureActions.ActionTypes.CONFIRM_OPTION_REQUEST,
      ),
      flatMap((action: featureActions.ConfirmOptionRequest) => {
        const { priceQuotationId, cartId, force_operation } = action.payload;
        return this.dataService
          .confirmOption(priceQuotationId, cartId, force_operation)
          .pipe(
            map((response: IResponse) => {
              const reservationId = get(response, [
                'data',
                0,
                'accommodations',
                0,
                'reservation_id',
              ]);
              const shouldConfirm = get(response, 'meta.confirm_required');
              const warnings = get(response, 'meta.warnings');
              const accommodationsIds = Object.keys(warnings || {});

              const availabilityWarning = accommodationsIds.find((id) => {
                const warning = warnings[id];
                return (
                  !warning.all_right &&
                  (!warning.availability || warning.closed)
                );
              });

              if (shouldConfirm && availabilityWarning) {
                this.warningModalService.open({
                  message: this.translate.instant(
                    'price_quotation_confirm_warning',
                  ),
                  action: new featureActions.ConfirmOptionRequest({
                    ...action.payload,
                    force_operation: true,
                  }),
                });

                return new featureActions.ConfirmOptionFailure({
                  error: null,
                });
              } else {
                this.notifications.push({
                  title: upperFirst(this.translate.instant('done')),
                  content: upperFirst(
                    this.translate.instant('notifications.update_success', {
                      param: this.translate.instant('price_quotation'),
                    }),
                  ),
                  type: 'success',
                });
                if (reservationId) {
                  this.router.navigate([`reservation/${reservationId}`]);
                } else {
                  this.router.navigate(['/price-quotations']);
                }
                return new featureActions.ConfirmOptionSuccess();
              }
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.ConfirmOptionFailure({ error }));
            }),
          );
      }),
    ),
  );

  createCartAndAddItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.AddCartWithItemRequest>(
        featureActions.ActionTypes.ADD_CART_WITH_ITEM_REQUEST,
      ),
      exhaustMap((action: featureActions.AddCartWithItemRequest) => {
        const { cartItem, priceQuotationId } = action.payload;
        return this.dataService.addCart(priceQuotationId).pipe(
          mergeMap((response: IResponseSuccess) => {
            const { id: cartId } = response.data[0];

            this.notifications.push({
              title: upperFirst(this.translate.instant('done')),
              content: upperFirst(
                this.translate.instant('notifications.update_success', {
                  param: this.translate.instant('price_quotation'),
                }),
              ),
              type: 'success',
            });

            return [
              new featureActions.AddCartSuccess({
                cart: response.data[0],
              }),
              new featureActions.AddCartItemRequest({
                priceQuotationId,
                cartId,
                cartItem,
                disableNotify: true,
              }),
            ];
          }),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(new featureActions.AddCartFailure({ error }));
          }),
        );
      }),
    ),
  );

  deletePriceQuotationMedia$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteMediaRequest>(
        featureActions.ActionTypes.DELETE_MEDIA_REQUEST,
      ),
      flatMap((action: featureActions.DeleteMediaRequest) => {
        const { priceQuotationId, attachmentId } = action.payload;
        return this.dataService
          .deletePriceQuotationMedia(attachmentId, +priceQuotationId)
          .pipe(
            map(() => {
              this.notifications.push({
                title: upperFirst(this.translate.instant('done')),
                content: upperFirst(
                  this.translate.instant('notifications.delete_success', {
                    param: this.translate.instant('attachment'),
                  }),
                ),
                type: 'success',
              });
              return new featureActions.DeleteMediaSuccess({
                priceQuotationId,
                attachmentId,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteMediaFailure({ error }));
            }),
          );
      }),
    ),
  );

  uploadPriceQuotationMedia$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UploadMediaRequest>(
        featureActions.ActionTypes.UPLOAD_MEDIA_REQUEST,
      ),
      flatMap((action: featureActions.UploadMediaRequest) => {
        const { priceQuotationId, file } = action.payload;
        return this.dataService
          .uploadPriceQuotationMedia(file, +priceQuotationId)
          .pipe(
            map((response: IResponseSuccess) => {
              this.notifications.push({
                title: upperFirst(this.translate.instant('done')),
                content: upperFirst(
                  this.translate.instant('notifications.create_success', {
                    param: this.translate.instant('attachment'),
                  }),
                ),
                type: 'success',
              });
              return new featureActions.UploadMediaSuccess({
                priceQuotationId,
                file: response.data[0],
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.UploadMediaFailure({ error }));
            }),
          );
      }),
    ),
  );

  addMandatoryAddons$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.AddMandatoryAddonsRequest>(
        featureActions.ActionTypes.ADD_MANDATORY_ADDONS_REQUEST,
      ),
      flatMap((action: featureActions.AddMandatoryAddonsRequest) => {
        const { priceQuotationId, cartId, cartItemId } = action.payload;
        return this.dataService
          .addMandatoryAddons(priceQuotationId, cartId, cartItemId)
          .pipe(
            map(() => {
              this.notifications.push({
                title: upperFirst(this.translate.instant('done')),
                content: upperFirst(
                  this.translate.instant('notifications.update_success', {
                    param: this.translate.instant('addons'),
                  }),
                ),
                type: 'success',
              });
              return new featureActions.AddMandatoryAddonsSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.AddMandatoryAddonsFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  updateCart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateCartRequestAction>(
        featureActions.ActionTypes.UPDATE_CART_REQUEST,
      ),
      switchMap((action: featureActions.UpdateCartRequestAction) => {
        const { priceQuotationId, optionId, cart } = action.payload;
        return this.dataService
          .updateCart(priceQuotationId, optionId, cart)
          .pipe(
            map(() => {
              this.notifications.push({
                title: upperFirst(this.translate.instant('done')),
                content: upperFirst(
                  this.translate.instant('notifications.update_success', {
                    param: this.translate.instant('cart'),
                  }),
                ),
                type: 'success',
              });
              return new featureActions.UpdateCartSuccessAction({
                optionId,
                cart,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.UpdateCartFailureAction({ error }));
            }),
          );
      }),
    ),
  );

  deleteMandatoryAddons$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteMandatoryAddonsRequest>(
        featureActions.ActionTypes.DELETE_MANDATORY_ADDONS_REQUEST,
      ),
      flatMap((action: featureActions.DeleteMandatoryAddonsRequest) => {
        const { priceQuotationId, cartId } = action.payload;
        return this.dataService
          .deleteMandatoryAddons(priceQuotationId, cartId)
          .pipe(
            map(() => {
              this.notifications.push({
                title: upperFirst(this.translate.instant('done')),
                content: upperFirst(
                  this.translate.instant('notifications.delete_success', {
                    param: this.translate.instant('addons'),
                  }),
                ),
                type: 'success',
              });
              return new featureActions.DeleteMandatoryAddonsSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.DeleteMandatoryAddonsFailure({ error }),
              );
            }),
          );
      }),
    ),
  );
}
