<div class="arrivals-departures">
  <a routerLink="/reservations/arrivals">
    <div class="arrivals">
      <span class="text-characters">
        {{ 'arrivals' | translate | capitalize }}
      </span>
      <span class="text-numbers">
        {{ checkIns || 0 }}/{{ arrivals || 0 }}
      </span>
      <nz-progress
        [nzPercent]="percentageOfArrivals"
        nzSize="small"
        [nzShowInfo]="false"
      ></nz-progress>
    </div>
  </a>
  <a routerLink="/reservations/departures">
    <div class="departures">
      <span class="text-characters">
        {{ 'departures' | translate | capitalize }}
      </span>
      <span class="text-numbers">
        {{ checkOuts || 0 }}/{{ departures || 0 }}
      </span>
      <nz-progress
        [nzPercent]="percentageOfDepartures"
        nzSize="small"
        [nzShowInfo]="false"
      ></nz-progress>
    </div>
  </a>
</div>
