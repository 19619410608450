<by-spin [nzSpinning]="loading">
  <div class="add-bill-modal__accommodations">
    <div
      class="add-bill-modal__accommodations-header"
      *ngIf="!config?.autoselected"
    >
      <i class="far fa-search by-mr-5"></i>
      {{ 'search' | translate | upperfirst }}
    </div>
    <div
      class="add-bill-modal__accommodations-header"
      *ngIf="config?.autoselected"
    >
      {{ 'accommodation' | translate | upperfirst }}
    </div>
    <div class="add-bill-modal__accommodations-body">
      <nz-select
        class="add-bill-modal__accommodations-select"
        [ngStyle]="{
          'pointer-events': config?.autoselected ? 'none' : 'initial'
        }"
        [ngModel]="
          selectedReservationAccommodation?.reservation_accommodation_id
        "
        (ngModelChange)="onSelectReservationAccommodation($event)"
        nzBorderless
        nzShowSearch
        [nzShowArrow]="false"
        [nzPlaceHolder]="'click_for_search_room' | translate | upperfirst"
        [nzOptionHeightPx]="100"
        [nzOptionOverflowSize]="3"
        [nzCustomTemplate]="customSelectRender"
        nzDropdownClassName="add-bill-modal__accommodations-select-dropdown"
      >
        <ng-container
          *ngFor="let reservationAccommodation of reservationAccommodations"
        >
          <nz-option
            [nzLabel]="reservationAccommodation?.searching_field"
            [nzValue]="reservationAccommodation.reservation_accommodation_id"
            nzCustomContent
          >
            <ng-container
              *ngTemplateOutlet="
                resAccTemplate;
                context: { resAcc: reservationAccommodation }
              "
            ></ng-container>
          </nz-option>
        </ng-container>
      </nz-select>
    </div>
  </div>
</by-spin>

<div class="by-mt-2" nz-form nzLayout="vertical">
  <nz-form-item>
    <nz-form-label nzRequired>
      {{ 'select_guest' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control>
      <nz-select
        class="by-w-100"
        [ngModel]="selectedGuest?.id"
        (ngModelChange)="onSelectGuest($event)"
        nzShowSearch
        [nzPlaceHolder]="'select_guest' | translate | upperfirst"
      >
        <ng-container
          *ngFor="let guest of selectedReservationAccommodation?.guests || []"
        >
          <nz-option [nzLabel]="guest.name" [nzValue]="guest.id"> </nz-option>
        </ng-container>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</div>

<ng-template #customSelectRender>
  <ng-container
    *ngTemplateOutlet="
      resAccTemplate;
      context: { resAcc: selectedReservationAccommodation }
    "
  ></ng-container>
</ng-template>

<ng-template #resAccTemplate let-resAcc="resAcc">
  <div class="reservation-accommodation-option">
    <div>#{{ resAcc?.tableau_label }}</div>
    <div>
      <strong>{{ resAcc?.main_guest?.name }}</strong>
    </div>
    <div class="color--grey-dark">
      {{ resAcc?.arrival_date | toDate | date: 'dd/MM/yyyy' }} -
      {{ resAcc?.departure_date | toDate | date: 'dd/MM/yyyy' }}
    </div>
  </div>
</ng-template>
