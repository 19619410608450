import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { UserMe } from '../../../models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'by-app-shell-user-2fa-warning',
  templateUrl: './app-shell-user-2fa-warning.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppShellUser2faWarningComponent implements OnInit {
  @Input() userMe: UserMe;

  readonly GUIDE_URL = environment.guideUrl;

  ngOnInit() {}
}
