import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { uniqBy } from 'lodash';

import {
  AddBillModalAccommodation,
  AddBillModalAccommodationGuest,
  AddonsEstimateTotals,
  BillDepartment,
  ChildrenRange,
  EstimateAddonPriceRequest,
  PlaceVatQuote,
  VatQuotesSettings,
} from '../../../../models';
import { AddBillModalAddon } from '../../../../models/objects/add-bill-modal-addon';
import { PayingCustomer } from '../../../../models/responses/paying-customer.response';
import { AddBillModalAvailableAddonComponent } from '../add-bill-modal-available-addon/add-bill-modal-available-addon.component';

@Component({
  selector: 'by-add-bill-modal-available-addons',
  templateUrl: './add-bill-modal-available-addons.component.html',
  styleUrls: ['./add-bill-modal-available-addons.component.scss'],
})
export class AddBillModalAvailableAddonsComponent implements OnInit, OnChanges {
  @ViewChildren(AddBillModalAvailableAddonComponent)
  availableAddonsComponents: AddBillModalAvailableAddonComponent[];

  @Input() availableAddons: AddBillModalAddon[];

  @Input() currencySymbol: string;

  @Input() estimates: AddonsEstimateTotals;

  @Input() selectedReservationAccommodation: AddBillModalAccommodation;

  @Input() selectedGuest: AddBillModalAccommodationGuest;

  @Input() vatQuotes: PlaceVatQuote[] = [];

  @Input() childrenRanges: ChildrenRange[] = [];

  @Input() referenceDate: Date;

  @Input() vatQuotesSettings: VatQuotesSettings;

  @Input() payingCustomerForVariousCategory: PayingCustomer;

  @Input() creatingLoading: { [addonId: number]: boolean };

  @Input() billsDepartments: Record<number, BillDepartment[]>;

  @Output() estimateAddon = new EventEmitter<EstimateAddonPriceRequest>();

  @Output() addAddon = new EventEmitter<AddBillModalAddon>();

  @Output() createAddon = new EventEmitter<AddBillModalAddon>();

  addonsCategories: Array<{ id: number; name: string }>;

  filterForm = this.formBuilder.group({
    addon_name: [null],
    category_id: [null],
  });

  creationFormIsVisible = false;
  fakeCreationAddon: AddBillModalAddon;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const {
      availableAddons,
      selectedReservationAccommodation,
      referenceDate,
    } = changes;

    if (availableAddons && this.availableAddons) {
      this.setAddonsCategories();
    }

    if (
      selectedReservationAccommodation &&
      this.selectedReservationAccommodation
    ) {
      this.creationFormIsVisible = false;
    }

    if (referenceDate && this.referenceDate) {
      this.creationFormIsVisible = false;
    }
  }

  onCreateAddon(book_only: boolean, addon: AddBillModalAddon) {
    this.createAddon.emit({ ...addon, book_only });
    this.creationFormIsVisible = false;
  }

  setFakeCreationAddon() {
    const {
      adults,
      children_ranges,
      arrival_date,
      departure_date,
    } = this.selectedReservationAccommodation;

    const buyer = {
      id: this.selectedGuest?.id,
      name: this.selectedGuest?.name,
    };

    const {
      id: customerId,
      name: customerName,
      surname: customerSurname,
      is_company,
    } = this.payingCustomerForVariousCategory;

    const customer = {
      id: customerId,
      name: is_company ? customerName : `${customerName} ${customerSurname}`,
    };

    this.fakeCreationAddon = {
      override: null,
      addon_id: null,
      customer,
      buyer,
      adults,
      children_ranges,
      name: null,
      total: 0,
      unit_price: 0,
      price_type: 'xunit',
      vat_quote_id: this.vatQuotesSettings?.ReservationAddon,
      category_id: 1,
      periods: [
        {
          date_from: new Date(arrival_date),
          date_to: new Date(departure_date),
        },
      ],
      date: this.referenceDate,
      quantity: 1,
      discount_type_id: 5,
      discount_value: 0,
      discounted_total: 0,
      deposit: false,
      all_inclusive_tax_document: false,
    };
  }

  onCreationFormExpandedChange(status: boolean) {
    if (status) {
      this.setFakeCreationAddon();
    }
  }

  onExpandStatusChange(event: { status: boolean; addon_id: number }) {
    if (!event.status) {
      return;
    }

    if (event.addon_id) {
      this.creationFormIsVisible = false;
    }

    this.availableAddonsComponents
      .filter(({ addon }) => addon.addon_id !== event.addon_id)
      .forEach((component) => {
        component.expanded = false;
      });
  }

  private setAddonsCategories() {
    this.addonsCategories = uniqBy(
      this.availableAddons.map((addon) => ({
        id: addon.category_id,
        name: addon.category_name,
      })),
      'id',
    );
  }
}
