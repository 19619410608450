import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { get } from 'lodash';
import moment from 'moment';
import { SubSink } from 'subsink';

import {
  datapickerdisabledEndDateFormArray,
  datapickerDisabledStartDateFormArray,
} from '../../core/datapickers/datapicker-disabled-date';
import { DiscountType, PriceList } from '../../models';

export interface PriceListPeriodFormValue {
  price_list_id: number;
  date_from: Date;
  date_to: Date;
  discount_type_id: number;
  discount_value: number;
  flag_season: boolean;
}

@Component({
  selector: 'by-price-list-period-form',
  templateUrl: './price-list-period-form.component.html',
  styleUrls: ['./price-list-period-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PriceListPeriodFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PriceListPeriodFormComponent),
      multi: true,
    },
  ],
})
export class PriceListPeriodFormComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  @Input() priceLists: PriceList[] = [];

  @Input() formsPeriods = [];

  @Input() discountTypes: DiscountType[] = [];

  @Input() currencySymbol = '€';

  dateFormats = {
    default: 'dd/MM/yyyy',
    seasonal: 'dd/MM/----',
  };

  form = this.formBuilder.group({
    price_list_id: [null, [Validators.required]],
    date_from: [null, [Validators.required]],
    date_to: [null, [Validators.required]],
    discount_type_id: [5],
    discount_value: [0],
    flag_season: [false, [Validators.required]],
  });

  private subs = new SubSink();

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.subs.add(
      this.form.valueChanges.subscribe((value) => {
        this.onChange(value);
      }),
    );
    this.subs.add(
      this.form.get('date_from').valueChanges.subscribe((date_from) => {
        const { date_to } = this.form.value;
        const dateFrom = moment(date_from).startOf('day');
        const dateTo = moment(date_to).startOf('day');
        if (dateFrom.isAfter(dateTo)) {
          this.form.patchValue({
            date_to: null,
          });
        }
      }),
    );
    this.subs.add(
      this.form.get('flag_season').valueChanges.subscribe(() => {
        this.updateDate();
      }),
    );
  }

  updateDate() {
    setTimeout(() => {
      const { date_to, date_from } = this.form.value;
      if (date_to) {
        this.form.get('date_to').reset(new Date(date_to));
      }
      if (date_from) {
        this.form.get('date_from').reset(new Date(date_from));
      }
    }, 100);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  get dateFormat() {
    const { flag_season } = this.form.value;

    return this.dateFormats[flag_season ? 'seasonal' : 'default'];
  }

  get isPercent() {
    const { discount_type_id } = this.form.value;
    return discount_type_id === 3 || discount_type_id === 5;
  }

  disabledStartDate = (current: Date) => {
    const { flag_season } = this.form.value;
    const formPeriodsFiltered = (this.formsPeriods || []).filter(
      (form) => get(form, 'flag_season') === flag_season,
    );
    return datapickerDisabledStartDateFormArray(
      formPeriodsFiltered,
      current,
      'date_to',
      'date_from',
    );
    //return moment(current).isSameOrAfter(moment(this.form.value.date_to));
  };

  disabledEndDate = (current: Date) => {
    const { flag_season, date_from } = this.form.value;
    if (!date_from) {
      return false;
    }
    const formPeriodsFiltered = (this.formsPeriods || []).filter(
      (form) => get(form, 'flag_season') === flag_season,
    );
    return datapickerdisabledEndDateFormArray(
      formPeriodsFiltered,
      current,
      date_from,
      'date_to',
      'date_from',
    );
    //return moment(current).isSameOrBefore(moment(this.form.value.date_from));
  };

  currencyFormatter = (value: number) => {
    return `${this.currencySymbol} ${+value}`;
  };
  currencyParser = (value: string) => {
    return value.replace(`${this.currencySymbol}`, '');
  };

  percentageFormatter = (value: number) => {
    return `% ${+value === 0 ? '' : +value}`;
  };
  percentageParser = (value: string) => {
    return value.replace('%', '');
  };

  onTouched: any = () => {};
  onChange: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.form.disable() : this.form.enable();
  }

  writeValue(value) {
    if (value) {
      this.form.setValue(value, { emitEvent: false });
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.touched) {
      this.form.markAllAsTouched();
    }

    if (this.form.valid) {
      return null;
    }

    return {
      'price-list-period-error': {
        value: this.form.value,
      },
    };
  }
}
