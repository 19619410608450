import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { EditBillFormComponent } from '../../../../components/edit-bill-form/edit-bill-form.component';
import {
  AddBillModalAccommodation,
  ChildrenRange,
  PlaceVatQuote,
} from '../../../../models';
import { EditBillsRequest } from '../../../../models/requests/edit-bills.request';

@Component({
  selector: 'by-add-bill-modal-editing-drawer',
  templateUrl: './add-bill-modal-editing-drawer.component.html',
  styleUrls: ['./add-bill-modal-editing-drawer.component.scss'],
})
export class AddBillModalEditingDrawerComponent implements OnInit {
  @Input() selectedReservationAccommodation: AddBillModalAccommodation;

  @Input() vatQuotes: PlaceVatQuote[] = [];

  @Input() childrenRanges: ChildrenRange[] = [];

  @Input() currencySymbol: string;

  @Output() editAddon = new EventEmitter<EditBillsRequest>();

  @ViewChild(EditBillFormComponent)
  formComponent: EditBillFormComponent;

  visible = false;

  billId: number;

  saving: boolean;

  constructor() {}

  ngOnInit() {}

  onSave() {
    this.editAddon.emit(this.formComponent.value);
  }

  open() {
    this.visible = true;
  }

  close() {
    if (this.saving) {
      return;
    }

    this.formComponent.reset();
    this.billId = null;
    this.visible = false;
  }

  setBillId(billId: number) {
    this.billId = billId;
  }

  setSaving(saving: boolean) {
    this.saving = saving;
  }
}
