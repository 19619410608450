import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { OrdinationProperty } from '../../models/objects/ordination-property.model';
import { BillsOrdinationService } from '../../services/bills-ordination.service';

import * as fromActions from './actions';

@Injectable()
export class BillsOrdinationStoreEffects {
  constructor(
    private dataService: BillsOrdinationService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPropertyRequest),
      switchMap(({ propery_id }) =>
        this.dataService.loadProperty(propery_id).pipe(
          map((property: OrdinationProperty) =>
            fromActions.loadPropertySuccess({ property }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadPropertyFailure(error));
          }),
        ),
      ),
    ),
  );

  saveOrdination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveOrdinationRequest),
      switchMap(
        ({ reservationAccommodationId, bill_id, ordinationMedia, onSuccess }) =>
          this.dataService
            .saveOrdination(
              reservationAccommodationId,
              bill_id,
              ordinationMedia,
            )
            .pipe(
              map(() => {
                if (onSuccess) {
                  onSuccess();
                }

                return fromActions.saveOrdinationSuccess();
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.saveOrdinationFailure(error));
              }),
            ),
      ),
    ),
  );

  loadTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadTemplateRequest),
      switchMap(({ payload }) =>
        this.dataService.getTemplate(payload).pipe(
          map((response) =>
            fromActions.loadTemplateSuccess({ template: response }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadTemplateFailure(error));
          }),
        ),
      ),
    ),
  );
}
