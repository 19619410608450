<div
  class="add-bill-modal__editing-drawer-overlay"
  [ngClass]="{ 'add-bill-modal__editing-drawer-overlay--visible': visible }"
  (click)="close()"
></div>

<div
  class="add-bill-modal__editing-drawer"
  [ngClass]="{ 'add-bill-modal__editing-drawer--open': visible }"
>
  <by-spin [nzSpinning]="formComponent?.loading">
    <by-page-header [title]="formComponent?.bill?.label"></by-page-header>

    <by-edit-bill-form
      [billId]="billId"
      [guests]="selectedReservationAccommodation?.guests"
      [currencySymbol]="currencySymbol"
      [vatQuotes]="vatQuotes"
      [arrivalDate]="selectedReservationAccommodation?.arrival_date"
      [departureDate]="selectedReservationAccommodation?.departure_date"
      [childrenRanges]="childrenRanges"
    ></by-edit-bill-form>

    <div class="add-bill-modal__editing-drawer-footer">
      <button nz-button (click)="close()" class="by-mr-10">
        {{ 'cancel' | translate | upperfirst }}
      </button>
      <button
        nz-button
        nzType="primary"
        [nzLoading]="saving"
        [disabled]="!formComponent?.valid"
        (click)="onSave()"
      >
        {{ 'confirm' | translate | upperfirst }}
      </button>
    </div>
  </by-spin>
</div>
