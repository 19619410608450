import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { intersectionWith } from 'lodash';

import { openInNewTab } from '../../../../helpers';
import { AddBillModalAccommodation, IBillsRef } from '../../../../models';
import { NotificationService } from '../../../../ui/services/notification.service';

@Component({
  selector: 'by-add-bill-modal-accommodation-bills',
  templateUrl: './add-bill-modal-accommodation-bills.component.html',
  styleUrls: ['./add-bill-modal-accommodation-bills.component.scss'],
})
export class AddBillModalAccommodationBillsComponent
  implements OnInit, OnChanges {
  @Input() selectedAccommodation: AddBillModalAccommodation;

  @Input() accommodationBills: IBillsRef[];

  @Input() currencySymbol: string;

  @Input() createdBillsId: number[];

  @Output() deleteBills = new EventEmitter<number[]>();

  @Output() createOrdination = new EventEmitter<IBillsRef[]>();

  @Output() editAddon = new EventEmitter<number>();

  checkedMap: { [billId: number]: boolean } = {};
  someAddonsChecked = false;

  constructor(private notification: NotificationService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const { createdBillsId } = changes;

    if (createdBillsId && this.createdBillsId) {
      this.checkedMap = this.createdBillsId.reduce(
        (checkedMap, billId) =>
          (checkedMap = { ...checkedMap, [billId]: true }),
        {},
      );

      this.someAddonsChecked = Object.values(this.checkedMap).find(
        (isChecked) => isChecked,
      );
    }
  }

  onCheckBill(billId: number, checked: boolean) {
    this.checkedMap = {
      ...this.checkedMap,
      [billId]: checked,
    };

    this.someAddonsChecked = Object.values(this.checkedMap).find(
      (isChecked) => isChecked,
    );
  }

  onDeleteSelectedAddons() {
    const billsIdsToDelete = Object.entries(this.checkedMap)
      .filter(([_, isChecked]) => isChecked)
      .map(([billId, _]) => +billId);

    this.deleteBills.emit(billsIdsToDelete);
  }

  onCreateOrdination() {
    const billsIdsSelected = Object.entries(this.checkedMap)
      .filter(([_, isChecked]) => isChecked)
      .map(([billId, _]) => +billId);

    const bills = intersectionWith(
      this.accommodationBills,
      billsIdsSelected,
      (a, b) => a.bill_id === b,
    );

    const someSelectedAddonsHaveOrdination = bills.some((bill) => !!bill.media);

    if (someSelectedAddonsHaveOrdination) {
      this.notification.warning('bills_ordination_exist_warning');
      return;
    }

    this.createOrdination.emit(bills);
  }

  onOpenOrdination(mediaUrl: string) {
    openInNewTab(mediaUrl);
  }
}
