import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadPropertyRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(fromActions.loadPropertySuccess, (state, { property }) => ({
    ...state,
    property,
    isLoading: false,
    error: null,
  })),

  on(fromActions.loadPropertyFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadTemplateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(fromActions.loadTemplateSuccess, (state, { template }) => ({
    ...state,
    isLoading: false,
    error: null,
    templateHtml: template,
  })),

  on(fromActions.loadTemplateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function billsOrdinationReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
