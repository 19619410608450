<div
  class="by-flex by-flex-wrap by-gap-15 by-p-10"
  [class.by-justify-content--start]="bookingEngineLinks.length > 1"
  [class.by-justify-content--center]="bookingEngineLinks.length === 1"
>
  <ng-container *ngFor="let engine of bookingEngineLinks; let i = index">
    <div
      class="by-flex by-flex-direction--column by-align-items--center"
      (click)="goToDns.emit(engine.dns)"
    >
      <nz-tag
        nzColor="processing"
        class="by-fs-15 dns-tag"
        [class.fadeIn]="i < 4 || showAll"
        [class.fadeOut]="!showAll && i >= 4"
      >
        <i class="far fa-external-link color--azure by-pr-5"></i>
        {{ engine.dns }}
      </nz-tag>
    </div>
  </ng-container>
</div>
<div
  class="by-w-100 by-flex by-flex-center by-mb-10 by-pt-15"
  *ngIf="bookingEngineLinks.length > 3 ? true : false"
>
  <div nz-button nzType="link" class="color--azure" (click)="toggleShowAll()">
    {{ (showAll ? 'show_less' : 'more_messages') | translate | upperfirst }}
  </div>
</div>
