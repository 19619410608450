import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'by-external-template-viewer',
  templateUrl: './external-template-viewer.component.html',
  styleUrls: ['./external-template-viewer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ExternalTemplateViewerComponent {
  @Input() bypassSanitizer = false;

  @Input() template: string = '';

  @Input() byStyle: { [klass: string]: any } = null;
}
