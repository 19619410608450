import { Injectable } from '@angular/core';
import { fromEvent, of } from 'rxjs';
import { filter, mapTo, merge } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkHandlerService {
  online$ = of(navigator.onLine).pipe(
    merge(
      fromEvent(window, 'offline').pipe(mapTo(false)),
      fromEvent(window, 'online').pipe(mapTo(true)),
    ),
  );

  canUseHttp$ = this.online$.pipe(filter((value) => value === true));

  constructor() {}
}
