import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpComponent } from './help.component';
import { UiModule } from '../../ui/ui.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';

@NgModule({
  declarations: [HelpComponent],
  imports: [CommonModule, UiModule, TranslateModule, NgPipesModule],
  exports: [HelpComponent],
})
export class HelpModule {}
