import { Overlay } from '@angular/cdk/overlay';
import { Directive, OnDestroy } from '@angular/core';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { delay } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Directive({
  selector: '[byPopoverScrollBlocker]',
})
export class PopoverScrollBlockerDirective implements OnDestroy {
  private subs = new SubSink();

  constructor(
    private overlay: Overlay,
    private popoverDirective: NzPopoverDirective,
  ) {
    this.subs.add(
      this.popoverDirective.visibleChange
        .pipe(delay(150))
        .subscribe((visible) => {
          if (!visible) {
            return;
          }

          const { overlayRef } = this.popoverDirective.component.overlay;

          overlayRef?.updateScrollStrategy(
            this.overlay.scrollStrategies.block(),
          );
        }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
