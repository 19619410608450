<form nz-form [nzLayout]="'vertical'" [formGroup]="form">
  <div nz-row nzGutter="20">
    <div nz-col nzMd="4">
      <nz-form-item>
        <nz-form-label nzRequired>
          {{ 'seasonal' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-select formControlName="flag_season">
            <nz-option
              [nzValue]="false"
              [nzLabel]="'no' | translate | capitalize"
            ></nz-option>
            <nz-option
              [nzValue]="true"
              [nzLabel]="'yes' | translate | capitalize"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzMd="10">
      <nz-form-item>
        <nz-form-label nzRequired>
          {{ 'from' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control [nzErrorTip]="dateFromTpl">
          <nz-date-picker
            byDisabledMobileKeyboard
            byDisabledDateFixer
            class="by-w-100"
            [ngStyle]="{ width: '100%' }"
            formControlName="date_from"
            [nzFormat]="dateFormat"
            [nzDisabledDate]="disabledStartDate"
          ></nz-date-picker>
        </nz-form-control>
        <ng-template #dateFromTpl let-control>
          <ng-container *ngIf="control.hasError('required')">{{
            'validation_form.required'
              | translate: { param: 'date' | translate }
          }}</ng-container>
        </ng-template>
      </nz-form-item>
    </div>
    <div nz-col nzMd="10">
      <nz-form-item>
        <nz-form-label nzRequired>
          {{ 'date_to' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control [nzErrorTip]="dateToTpl">
          <nz-date-picker
            byDisabledMobileKeyboard
            byDisabledDateFixer
            class="by-w-100"
            [ngStyle]="{ width: '100%' }"
            formControlName="date_to"
            [nzFormat]="dateFormat"
            [nzDisabledDate]="disabledEndDate"
          ></nz-date-picker>
        </nz-form-control>
        <ng-template #dateToTpl let-control>
          <ng-container *ngIf="control.hasError('required')">{{
            'validation_form.required'
              | translate: { param: 'date' | translate }
          }}</ng-container>
        </ng-template>
      </nz-form-item>
    </div>
  </div>
  <div nz-row nzGutter="20">
    <div nz-col nzMd="10">
      <nz-form-item>
        <nz-form-label nzRequired>
          {{ 'price_list' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-select formControlName="price_list_id">
            <nz-option
              *ngFor="let priceList of priceLists"
              [nzValue]="priceList.id"
              [nzLabel]="priceList.label"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzMd="14">
      <nz-form-item>
        <nz-form-label>
          {{ 'discount_types' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <div nz-row nzGutter="20">
            <div nz-col nzXs="12">
              <nz-select
                style="width: 200px"
                formControlName="discount_type_id"
              >
                <ng-container *ngFor="let discountType of discountTypes">
                  <nz-option
                    *ngIf="discountType.id !== 1"
                    [nzValue]="discountType.id"
                    [nzLabel]="discountType.name"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </div>
            <div nz-col nzXs="12">
              <nz-input-number
                style="width: 80px"
                formControlName="discount_value"
                byDecimalOnly
                [nzMin]="0"
                [nzParser]="isPercent ? percentageParser : currencyParser"
                [nzFormatter]="
                  isPercent ? percentageFormatter : currencyFormatter
                "
              ></nz-input-number>
            </div>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
