import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[bySpeechRecognition]',
})
export class SpeechRecognitionDirective {
  @Input() set enabled(enabled: boolean) {
    if (enabled) {
      this.onStart();
    } else {
      this.onStop();
    }
  }

  @Output() result = new EventEmitter<string>();

  private recognition: any;

  private isSpeechRecognitionIsAvailable = true;

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.isSpeechRecognitionIsAvailable = 'webkitSpeechRecognition' in window;

    if (this.isSpeechRecognitionIsAvailable) {
      // @ts-ignore
      this.recognition = new webkitSpeechRecognition();

      this.recognition.continuous = false;
      this.recognition.interimResults = false;

      this.recognition.lang = 'it-IT';
    }
  }

  onStart() {
    if (this.isSpeechRecognitionIsAvailable) {
      this.recognition.start();

      this.recognition.onresult = (e) => {
        this.result.emit(e.results[0][0].transcript);
        this.recognition.stop();
      };

      this.recognition.onerror = (e) => {
        this.recognition.stop();
      };
    }
  }

  onStop() {
    if (this.isSpeechRecognitionIsAvailable) {
      this.recognition.stop();
    }
  }
}
