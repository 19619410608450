import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';

import { AddBillModalResultsOnClose } from '../../../../models/objects/add-bill-modal-results-on-close';
import { AddBillModalConfig } from '../../../../models/objects/add-bill-modal.config';
import { AddBillModalStoreActions } from '../../../../root-store/add-bill-modal-store';
import { RootState } from '../../../../root-store/root-state';
import { AddBillModalContainerComponent } from '../add-bill-modal-container/add-bill-modal-container.component';

@Injectable({
  providedIn: 'root',
})
export class AddBillModalService {
  public modalClose$ = new Subject<AddBillModalResultsOnClose>();

  constructor(
    private modalService: NzModalService,
    private store: Store<RootState>,
  ) {}

  open(config?: AddBillModalConfig): Observable<AddBillModalResultsOnClose> {
    this.store.dispatch(AddBillModalStoreActions.setConfig({ config }));

    this.store.dispatch(AddBillModalStoreActions.loadReservationsRequest());

    return this.createModal();
  }

  openWithReservationId(
    reservationId: number,
    referenceDate = new Date(),
  ): Observable<AddBillModalResultsOnClose> {
    this.store.dispatch(
      AddBillModalStoreActions.setConfig({
        config: { noDateSelection: true },
      }),
    );
    this.store.dispatch(
      AddBillModalStoreActions.setReferenceDate({ referenceDate }),
    );
    this.store.dispatch(
      AddBillModalStoreActions.loadReservationRequest({ reservationId }),
    );
    return this.createModal();
  }

  openWithReservationAccommodationId(
    reservationId: number,
    reservationAccommodationId: number,
    referenceDate = new Date(),
  ): Observable<AddBillModalResultsOnClose> {
    this.store.dispatch(
      AddBillModalStoreActions.setConfig({
        config: { autoselected: true, noDateSelection: true },
      }),
    );
    this.store.dispatch(
      AddBillModalStoreActions.setReferenceDate({
        referenceDate,
      }),
    );
    this.store.dispatch(
      AddBillModalStoreActions.loadReservationRequest({
        reservationId,
        autoSelectedReservationAccommodationId: reservationAccommodationId,
      }),
    );

    return this.createModal();
  }

  private createModal(): Observable<AddBillModalResultsOnClose> {
    const modal = this.modalService.create<
      AddBillModalContainerComponent,
      Partial<AddBillModalContainerComponent>
    >({
      nzContent: AddBillModalContainerComponent,
      nzData: { modalServiceRef: this },
      nzClassName: 'bill-modal',
      nzFooter: null,
      nzWidth: '100%',
      nzStyle: {
        top: '16px',
        paddingBottom: '0',
        borderRadius: '4px',
        overflowX: 'hidden',
        position: 'relative',
      },
      nzBodyStyle: {
        padding: '0px',
        height: 'calc(100vh - 32px)',
        overflow: 'auto',
      },
      nzClosable: false,
      nzMaskClosable: false,
    });

    return modal.afterClose.asObservable();
  }
}
