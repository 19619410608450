import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import { Media } from '../models';
import { OrdinationProperty } from '../models/objects/ordination-property.model';

import { MediasService } from './medias.service';
import { BillOrdinationTemplateRequest } from '../models/requests/bills-ordination-request';

@Injectable({
  providedIn: 'root',
})
export class BillsOrdinationService {
  constructor(
    private http: HttpClient,
    private mediasService: MediasService,
  ) {}

  loadProperty(propertyId: number): Observable<OrdinationProperty> {
    return this.http.get(`property/${propertyId}`).pipe(
      switchMap(({ data }: IResponseSuccess) => {
        const property = data[0];
        return this.http.get(`media/property/${propertyId}?all_size=1`).pipe(
          map(({ data: medias }: IResponseSuccess) => ({
            id: property.id,
            name: property.name,
            address: property.attributes.address,
            zip_code: property.attributes.zip_code,
            city_name: property.city_name,
            county_iso_code: property.county_name,
            country_name: property.country_name,
            vat_code: property.invoice_layout_default?.vat_code,
            logo: medias.find(({ genre }) => genre === 'logo')?.media,
          })),
        );
      }),
    );
  }

  saveOrdination(
    reservationAccommodationId: number,
    bill_id: number[],
    ordinationMedia: File,
  ) {
    return this.mediasService
      .create(
        'reservation_accommodation',
        reservationAccommodationId,
        ordinationMedia,
        'bills_ordinations',
      )
      .pipe(
        switchMap(({ data: media }: IResponseSuccess<Media>) =>
          this.http.post('bills/attach_media', {
            bill_id,
            media_id: media.id,
          }),
        ),
      );
  }

  getTemplate(payload: BillOrdinationTemplateRequest) {
    return this.http.post(
      `bills/ordination/view`,
      { ...payload },
      {
        responseType: 'text',
      },
    );
  }
}
