import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { capitalize, upperFirst } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';

import { SelectGroup, SelectProperty } from '../+state/core.actions';
import { ICoreState } from '../+state/core.reducer';
import { AuthStoreActions } from '../../root-store/auth-store';
import { PARTNER_API_STORAGE_KEY } from '../../shared/properties-select/properties-select.component';
import { AfterLoginModalComponent } from '../app-shell/after-login-modal/after-login-modal.component';

import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AfterLoginService {
  constructor(
    private _modalService: NzModalService,
    private _router: Router,
    private _translate: TranslateService,
    private _store: Store<ICoreState>,
    private _tokenService: TokenService,
    private activatedRoute: ActivatedRoute,
  ) {}

  askForWorkspace(redirect?: string) {
    const modal = this._modalService.create({
      nzTitle: upperFirst(this._translate.instant('attach_properties_select')),
      nzContent: AfterLoginModalComponent,
      nzClosable: false,
      nzMaskClosable: false,
      nzFooter: [
        {
          label: capitalize(this._translate.instant('cancel')),
          danger: true,
          type: 'primary',
          onClick: () => {
            const isAdmin = this._tokenService.isAdmin();

            if (!isAdmin) {
              this._store.dispatch(AuthStoreActions.logoutRequest({}));
              modal.close();
              this._router.navigate(['/login']);
            } else {
              this._tokenService.removeImpersonate();
              modal.close();
            }
          },
        },
        {
          label: capitalize(this._translate.instant('confirm')),
          type: 'primary',
          onClick: ({ groupId, propertyId }) => {
            if (propertyId) {
              this._store.dispatch(new SelectProperty({ propertyId }));
            }
            if (groupId) {
              this._store.dispatch(new SelectGroup({ groupId }));
            }

            localStorage.removeItem(PARTNER_API_STORAGE_KEY);

            const redirectUrlFromQyeryParams =
              this.activatedRoute?.snapshot?.queryParams?.redirect;

            if (!redirectUrlFromQyeryParams) {
              this._router.navigateByUrl(redirect ?? '/');
              modal.close();
            } else {
              window.location = redirectUrlFromQyeryParams;
            }
          },
        },
      ],
    });
  }
}
