import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IResponse } from '../models/response.model';
import { IResponseSuccess } from '../models/response-sucess.model';
import { PaymentMethod } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsService {
  constructor(private _http: HttpClient) {}

  public loadPaymentMethods() {
    return this._http.get<IResponseSuccess<PaymentMethod[]>>(
      `general/payment_methods`,
    );
  }

  public loadGeneralPaymentMethods(fe_module?: boolean): Observable<IResponse> {
    let url = `general/payment_methods/generic`;
    if (fe_module) {
      url = `general/payment_methods/generic?fe_module=1`;
    }
    return this._http.get(url) as Observable<IResponse>;
  }

  public loadPaymentMethodsProperty(propertyId): Observable<IResponse> {
    return this._http.get(
      `property/${propertyId}/payment_methods`,
    ) as Observable<IResponse>;
  }
}
