import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLink } from '../../../../models/objects/custom-link';
import { RouterModule } from '@angular/router';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';

@Component({
  selector: 'by-custom-link-mobile-bar',
  standalone: true,
  imports: [CommonModule, RouterModule, NzGridModule, NgUpperFirstPipeModule],
  templateUrl: './custom-link-mobile-bar.component.html',
  styleUrls: ['./custom-link-mobile-bar.component.scss'],
})
export class CustomLinkMobileBarComponent {
  @Input() customLinks: CustomLink[];

  @Output() closeBottomSheet = new EventEmitter();

  constructor() {}
}
