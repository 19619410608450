import { PriceQuotation } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getItem = (state: State): PriceQuotation => state.item;

export const selectPriceQuotationState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('priceQuotation');

export const selectPriceQuotationError: MemoizedSelector<object, any> =
  createSelector(selectPriceQuotationState, getError);

export const selectPriceQuotationIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectPriceQuotationState, getIsLoading);

export const selectPriceQuotationItem: MemoizedSelector<
  object,
  PriceQuotation
> = createSelector(selectPriceQuotationState, getItem);
