<div nz-row [nzGutter]="[12, 24]" nzJustify="center">
  <ng-container *ngFor="let customLink of customLinks">
    <div
      nz-col
      nzXs="8"
      class="by-flex by-flex-direction--column by-align-items--center"
      [routerLink]="[customLink?.link]"
      (click)="closeBottomSheet.emit()"
    >
      <span
        [style.color]="customLink.color"
        class="{{ customLink.icon }} by-fs-27 "
      ></span>
      <span
        [style.color]="customLink.color"
        class="by-pt-5 by-fs-15 custom-text by-line-height-normal"
      >
        {{ customLink.label | upperfirst }}
      </span>
    </div>
  </ng-container>
</div>
