import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PopoverScrollBlockerDirective } from './popover-scroll-blocker.directive';

@NgModule({
  declarations: [PopoverScrollBlockerDirective],
  imports: [CommonModule],
  exports: [PopoverScrollBlockerDirective],
})
export class PopoverScrollBlockerModule {}
