import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getTemplate = (state: State) => state.templateHtml;

export const selectState = createFeatureSelector<State>('billsOrdination');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectTemplate = createSelector(selectState, getTemplate);

export const selectProperty = createSelector(
  selectState,
  (state: State) => state.property,
);
