import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { generateSearchQuery } from '../helpers/params-generator';
import { IResponse } from '../models/response.model';

import { IAvailableRoomRequest } from './../models/api/generics/available-room/available-room.model';

@Injectable({
  providedIn: 'root',
})
export class AvailableRoomService {
  constructor(private http: HttpClient) {}

  loadAvailableRoom(data: IAvailableRoomRequest) {
    const { accommodation_id, ...params } = data;

    return this.http.get(
      `accommodation/number/available/${accommodation_id}?${generateSearchQuery(
        params,
      )}`,
    ) as Observable<IResponse>;
  }
}
