import { Injectable } from '@angular/core';
import { CustomMenuFormModalComponent } from '@app/shared/custom-menu-form-modal/custom-menu-form-modal.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Injectable({ providedIn: 'root' })
export class CustomMenuFormService {
  modal: NzModalRef;
  constructor(private _modalService: NzModalService) {}

  createComponentModal(data?: any): void {
    this.modal = this._modalService.create<
      CustomMenuFormModalComponent,
      Partial<CustomMenuFormModalComponent>
    >({
      nzContent: CustomMenuFormModalComponent,
      nzData: {
        data: data,
      },
      nzFooter: null,
      nzOnCancel: () => {},
      nzMaskClosable: false,
    });
    this.modal.afterClose.subscribe(() => {});
  }
}
