import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SubSink } from 'subsink';
import { HelpStoreSelectors } from '../../root-store/help-store';
import { RootState } from '../../root-store/root-state';
import { openInNewTab } from '../../helpers';
import { GUIDE_SECTIONS } from '../../config';

@Component({
  selector: 'by-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit, OnDestroy {
  openInNewTab = openInNewTab;

  visible = false;

  sectionsToView$ = this.store.pipe(
    select(HelpStoreSelectors.selectGuideSectionsToView),
  );

  allSections = GUIDE_SECTIONS;

  private subs = new SubSink();

  sectionsToView: string[] = [];

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  constructor(private store: Store<RootState>) {}

  ngOnInit(): void {
    this.subs.add(
      this.sectionsToView$.subscribe((sectionsToView) => {
        this.sectionsToView = sectionsToView;
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
