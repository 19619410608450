import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BillsOrdinationStoreEffects } from './effects';
import { billsOrdinationReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('billsOrdination', billsOrdinationReducer),
    EffectsModule.forFeature([BillsOrdinationStoreEffects]),
  ],
})
export class BillsOrdinationStoreModule {}
