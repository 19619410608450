import { Component, Input, OnInit, inject } from '@angular/core';

import { AddBillModalAddon } from '../../../../models/objects/add-bill-modal-addon';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-add-bill-modal-addon-warnings',
  templateUrl: './add-bill-modal-addon-warnings.component.html',
  styleUrls: ['./add-bill-modal-addon-warnings.component.scss'],
})
export class AddBillModalAddonWarningsComponent implements OnInit {
  readonly nzDataModal: Partial<AddBillModalAddonWarningsComponent> =
    inject(NZ_MODAL_DATA);

  @Input() addon: AddBillModalAddon = this.nzDataModal.addon;

  @Input() warnings: string[] = this.nzDataModal?.warnings || [];

  constructor() {}

  ngOnInit() {}
}
