import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';

import { UpdateRoomOnSwapRequest } from '../+state/tableau.actions';
import { ITableauState } from '../+state/tableau.reducer';
import { DraggedPriceQuotation } from '../models/dragged-price-quotation';
import { DraggedReservation } from '../models/dragged-reservation';
import { TableauReservationSwap } from '../models/tableau-reservation-swap.model';

@Injectable({ providedIn: 'root' })
export class TableauDragToSelectService {
  endDragToSelectIndex: Subject<number> = new Subject();

  draggedReservations: any = {};
  draggedQuote: any = {};

  endDragToSelectOnSwapIndex: Subject<boolean> = new Subject();

  swapDrop = false;
  swapEndedDrag = false;

  resetDragToSelect: Subject<boolean> = new Subject();

  reservationsOnSwap: number[] = [];

  dragSource: DraggedReservation = {
    typeDrag: null,
    accommodationName: '',
    roomLabel: '',
    dateDisableClick: '',
    dayId: '',
    tdClassHoverShow: {},
    mapping: null,
    property_id: null,
    property_id_to: null,
    data: null,
    typeAccommodation: { not_room: false, type_id: null, type_name: '' },
    name: null,
    surname: null,
  };

  dragQuoteSource: DraggedPriceQuotation = {
    accommodationName: '',
    roomLabel: '',
    accommodationId: null,
    roomId: null,
    dateDisableClick: {},
    dayId: '',
    tdClassHoverShow: {},
    mapping: null,
    property_id: null,
    property_id_to: null,
    data: null,
    title: '',
  };

  constructor(
    private _store: Store<ITableauState>,
    private translate: TranslateService,
    private message: NzMessageService,
  ) {}

  reset() {
    this.draggedReservations = {};
    this.draggedQuote = {};
    this.dragSource.tdClassHoverShow = {};
    this.swapEndedDrag = false;
  }

  onDragEndSwapQuote() {
    this.reset();
    this.createMessage('warning', 'message_drag_quote');
    return;
  }

  createMessage(type: string, messageLabel = 'message_drag_not_room'): void {
    const message = this.translate.instant(messageLabel);
    this.message.create(type, message);
  }

  onDragEndSwap() {
    if (this.dragSource && this.dragSource.typeDrag === 'quote') {
      this.onDragEndSwapQuote();
      return;
    }

    const {
      accommodationName,
      roomLabel,
      mapping,
      data,
      dayId,
      property_id,
      typeAccommodation,
    } = this.dragSource;
    if (mapping.on_swap && this.swapEndedDrag && this.swapDrop) {
      return;
    }
    const reservationSwap: TableauReservationSwap = {
      reservation_id: data.reservation_id,
      property_id,
      dayId,
      mapping,
      reservation_accommodation_id: mapping.reservation_accommodation_id,
      accommodation_id: mapping.accommodation_id,
      reservation_accommodation_room_id:
        mapping.reservation_accommodation_room_id,
      accommodation_tableau_number_id: mapping.accommodation_tableau_number_id,
      arrival_date: mapping.arrival_date,
      departure_date: mapping.departure_date,
      label: roomLabel,
      accommodation_name: accommodationName,
      typeAccommodation,
    };
    const dataSwap = {
      reservation_accommodation_id:
        reservationSwap.reservation_accommodation_id,
      on_swap: true,
    };
    this._store.dispatch(
      new UpdateRoomOnSwapRequest({
        onSwapData: { ...reservationSwap },
        data: dataSwap,
      }),
    );
    this.reset();
  }

  addDraggedReservations(accommodationId, roomId, dayId) {
    this.draggedReservations = {
      ...this.draggedReservations,
      [accommodationId]: {
        ...this.draggedReservations[accommodationId],
        [roomId]: { [dayId]: true },
      },
    };
  }
  addDraggedQuotes(accommodationId, roomId, dayId) {
    this.draggedQuote = {
      ...this.draggedReservations,
      [accommodationId]: {
        ...this.draggedQuote[accommodationId],
        [roomId]: { [dayId]: true },
      },
    };
  }
}
