import {
  AddBillModalAccommodation,
  AddBillModalAccommodationGuest,
  BillDepartment,
  ChildrenRange,
  Dictionary,
  IBillsRef,
  PlaceVatQuote,
  VatQuotesSettings,
} from '../../models';
import { AddBillModalAddon } from '../../models/objects/add-bill-modal-addon';
import { AddBillModalConfig } from '../../models/objects/add-bill-modal.config';
import { PayingCustomer } from '../../models/responses/paying-customer.response';

export interface State {
  reservationAccommodations: AddBillModalAccommodation[];
  selectedReservationAccommodation: AddBillModalAccommodation;
  selectedGuest: AddBillModalAccommodationGuest;
  availableAddons: AddBillModalAddon[];
  accommodationBills: IBillsRef[];
  vatQuotesSettings: Dictionary<VatQuotesSettings>;
  placeVatQuotes: PlaceVatQuote[];
  billsDepartments: BillDepartment[];
  childrenRanges: ChildrenRange[];
  payingCustomerForVariousCategory: PayingCustomer;
  referenceDate: Date;
  config: AddBillModalConfig;
  createdBillsId: number[];
  somethingIsChanged: boolean;
  isLoading?: boolean;
  accommodationsLoading?: boolean;
  createLoading?: { [addonId: number]: boolean };
  error?: any;
}

export const initialState: State = {
  reservationAccommodations: null,
  selectedReservationAccommodation: null,
  selectedGuest: null,
  availableAddons: null,
  accommodationBills: null,
  vatQuotesSettings: null,
  placeVatQuotes: null,
  billsDepartments: null,
  childrenRanges: null,
  payingCustomerForVariousCategory: null,
  referenceDate: new Date(),
  config: {
    autoselected: false,
  },
  createdBillsId: [],
  somethingIsChanged: false,
  isLoading: false,
  accommodationsLoading: false,
  createLoading: null,
  error: null,
};
