import { Action, createReducer, on } from '@ngrx/store';
import { difference, orderBy } from 'lodash';

import * as fromActions from './actions';
import {
  availableAddonsToModalAddonsFormat,
  getAccommodationsFromReservations,
  getBillsRefsFromCategories,
  getReferenceDateFromAccommodation,
  updateAddonsBuyer,
} from './helpers';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  // Load Reservations
  on(fromActions.loadReservationsRequest, (state) => ({
    ...state,
    reservationAccommodations: null,
    accommodationsLoading: true,
    error: null,
  })),
  on(fromActions.loadReservationsSuccess, (state, { reservations }) => {
    const reservationAccommodations = getAccommodationsFromReservations(
      reservations,
      state.referenceDate,
    );

    // Quando arrivano le nuove camera lascio selezionata la vecchia camera
    // se questa è ancora presente tra le nuove camere
    const selectedReservationAccommodation =
      reservationAccommodations.find(
        ({ reservation_accommodation_id }) =>
          state.selectedReservationAccommodation
            ?.reservation_accommodation_id === reservation_accommodation_id,
      ) || null;

    // Se non c'è nessuna camera selezionata svuoto gli addon disponibili
    const availableAddons = selectedReservationAccommodation
      ? state.availableAddons
      : null;

    // Se non c'è nessuna camera selezionata svuoto accommodation bills
    const accommodationBills = selectedReservationAccommodation
      ? state.accommodationBills
      : null;

    // Se non c'è nessuna camera selezionata svuoto il customer pagante per la categoria "Varie"
    const payingCustomerForVariousCategory = selectedReservationAccommodation
      ? state.payingCustomerForVariousCategory
      : null;

    // Se la camera è rimasta selezionata a seguito di load delle camere il guest selezionato
    // sarà quello che era già selezionato
    const selectedGuest = selectedReservationAccommodation
      ? state.selectedGuest
      : null;

    return {
      ...state,
      reservationAccommodations,
      selectedReservationAccommodation,
      selectedGuest,
      availableAddons,
      accommodationBills,
      payingCustomerForVariousCategory,
      accommodationsLoading: false,
      error: null,
    };
  }),
  on(fromActions.loadReservationsFailure, (state, { error }) => ({
    ...state,
    accommodationsLoading: false,
    error,
  })),

  // Load Reservation
  on(fromActions.loadReservationRequest, (state) => ({
    ...state,
    reservationAccommodations: null,
    accommodationsLoading: true,
    error: null,
  })),
  on(
    fromActions.loadReservationSuccess,
    (state, { reservation, autoSelectedReservationAccommodationId }) => {
      const reservationAccommodations = getAccommodationsFromReservations(
        [reservation],
        state.referenceDate,
      );

      const selectedReservationAccommodation =
        reservationAccommodations.find(
          ({ all_reservation_accommodations_id }) =>
            all_reservation_accommodations_id.includes(
              autoSelectedReservationAccommodationId,
            ),
        ) || reservationAccommodations[0];

      const selectedGuest = selectedReservationAccommodation
        ? selectedReservationAccommodation.guests[0]
        : null;

      const referenceDate =
        getReferenceDateFromAccommodation(
          state.referenceDate,
          selectedReservationAccommodation,
        ) || null;

      return {
        ...state,
        reservationAccommodations,
        selectedReservationAccommodation,
        selectedGuest,
        referenceDate,
        accommodationsLoading: false,
        error: null,
      };
    },
  ),
  on(fromActions.loadReservationFailure, (state, { error }) => ({
    ...state,
    accommodationsLoading: false,
    error,
  })),

  // Load Available Addons
  on(fromActions.loadAvailableAddonsRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(fromActions.loadAvailableAddonsSuccess, (state, { availableAddons }) => ({
    ...state,
    availableAddons: availableAddonsToModalAddonsFormat(
      availableAddons,
      state.selectedReservationAccommodation,
      state.selectedGuest,
      state.referenceDate,
    ),
    error: null,
  })),
  on(fromActions.loadReservationFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Load Accommodation Bills
  on(fromActions.loadAccommodationBillsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadAccommodationBillsSuccess,
    (state, { accommodationBills }) => ({
      ...state,
      accommodationBills: getBillsRefsFromCategories(accommodationBills).filter(
        ({ is_trashed }) => !is_trashed,
      ),
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadAccommodationBillsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  // Add addon
  on(fromActions.createAddonRequest, (state, { addon }) => ({
    ...state,
    createLoading: { ...state.createLoading, [addon.addon_id || 'new']: true },
    error: null,
  })),
  on(fromActions.createAddonSuccess, (state, { addonId, createdBillsId }) => ({
    ...state,
    somethingIsChanged: true,
    createLoading: { ...state.createLoading, [addonId || 'new']: false },
    createdBillsId: [...state.createdBillsId, ...createdBillsId],
    error: null,
  })),
  on(fromActions.createAddonFailure, (state, { error, addonId }) => ({
    ...state,
    createLoading: { ...state.createLoading, [addonId || 'new']: false },
    error,
  })),

  // Update addon
  on(fromActions.updateAddonRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(fromActions.updateAddonSuccess, (state) => ({
    ...state,
    somethingIsChanged: true,
    error: null,
  })),
  on(fromActions.updateAddonFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Delete bills
  on(fromActions.deleteBillsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteBillsSuccess, (state, { billsIds }) => {
    const createdBillsId = difference(state.createdBillsId, billsIds);

    return {
      ...state,
      createdBillsId,
      somethingIsChanged: true,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.deleteBillsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  // Load Vat Quotes Settings
  on(fromActions.loadVatQuotesSettingsRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(
    fromActions.loadVatQuotesSettingsSuccess,
    (state, { vatQuotesSettings }) => ({
      ...state,
      vatQuotesSettings,
      error: null,
    }),
  ),
  on(fromActions.loadVatQuotesSettingsFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Load Place Vat Quotes
  on(fromActions.loadPlaceVatQuotesRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(fromActions.loadPlaceVatQuotesSuccess, (state, { placeVatQuotes }) => ({
    ...state,
    placeVatQuotes,
    error: null,
  })),
  on(fromActions.loadPlaceVatQuotesFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Load Bills Departments
  on(fromActions.loadBillsDepartmentsRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(
    fromActions.loadBillsDepartmentsSuccess,
    (state, { billsDepartments }) => ({
      ...state,
      billsDepartments,
      error: null,
    }),
  ),
  on(fromActions.loadBillsDepartmentsFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Load Children Ranges
  on(fromActions.loadChildrenRangesRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(fromActions.loadChildrenRangesSuccess, (state, { childrenRanges }) => ({
    ...state,
    childrenRanges: orderBy(
      childrenRanges,
      ['combination.from', 'combination.to'],
      'asc',
    ),
    error: null,
  })),
  on(fromActions.loadChildrenRangesFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Load Paying Customer For Variuos Category
  on(fromActions.loadPayingCustomerForVariousCategoryRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(
    fromActions.loadPayingCustomerForVariousCategorySuccess,
    (state, { payingCustomerForVariousCategory }) => ({
      ...state,
      payingCustomerForVariousCategory,
      error: null,
    }),
  ),
  on(fromActions.loadChildrenRangesFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Set Config
  on(fromActions.setConfig, (state, { config }) => ({
    ...state,
    config: { ...state.config, ...config },
  })),

  // Set Selected Reservation Accommodation
  on(
    fromActions.setSelectedReservationAccommodation,
    (state, { selectedReservationAccommodation }) => ({
      ...state,
      selectedGuest: selectedReservationAccommodation.guests[0],
      referenceDate: getReferenceDateFromAccommodation(
        state.referenceDate,
        selectedReservationAccommodation,
      ),
      selectedReservationAccommodation,
    }),
  ),

  // Set Selected Guest
  on(fromActions.setSelectedGuest, (state, { selectedGuest }) => ({
    ...state,
    selectedGuest,
    availableAddons: updateAddonsBuyer(state.availableAddons, selectedGuest),
  })),

  // Set Reference Date
  on(fromActions.setReferenceDate, (state, { referenceDate }) => ({
    ...state,
    referenceDate,
  })),

  // Set Something Is Changed
  on(fromActions.setSomethingIsChanged, (state, { somethingIsChanged }) => ({
    ...state,
    somethingIsChanged,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function addBillModalReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
