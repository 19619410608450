<div class="by-mt-20">
  <by-spin [nzSpinning]="loading$ | async">
    <div *ngIf="templateHtml$ | async">
      <by-external-template-viewer
        [template]="templateHtml$ | async"
        [bypassSanitizer]="true"
      ></by-external-template-viewer>
    </div>
  </by-spin>
</div>
