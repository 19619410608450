import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'by-multi-property-menu',
  templateUrl: './multi-property-menu.component.html',
  styleUrls: ['./multi-property-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MuliPropertyMenuComponent implements OnInit {
  constructor() {}

  @Input() groupPropertyName: string;
  @Input() userFullName: string;
  @Input() isGroupSelectDisabled: boolean;
  @Input() allGroups: Array<{ id: number; name: string }>;
  @Input() allProperties: Array<{ id: number; name: string }>;
  @Input() groupLogo: any;
  @Input() selectedProperty: { id: number; name: string };
  @Input() selectedGroup: { id: number; name: string };
  @Input() logoProperty: any;

  @Output() changePropertyEvent: EventEmitter<number> = new EventEmitter();
  @Output() changeGroupEvent: EventEmitter<number> = new EventEmitter();

  ngOnInit() {}

  onChangePropertySelected(propertyId) {
    this.changePropertyEvent.emit(propertyId);
  }
  onChangeGroupSelected(groupId) {
    this.changeGroupEvent.emit(groupId);
  }
}
