import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import SignaturePad from 'signature_pad';

import { SignatureOption } from './models/siganture-options';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements OnInit, AfterViewInit {
  nzModalData: Partial<SignaturePadComponent> = inject(NZ_MODAL_DATA);

  @Input()
  signature: string = null;

  @Input()
  signatureOptions: SignatureOption[] =
    this.nzModalData?.signatureOptions || [];

  @ViewChild('signaturePad')
  signaturePadCanvas: ElementRef;

  @ViewChild('signaturePadContainer')
  signaturePadContainer: ElementRef;

  private pad: SignaturePad;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    const canvas = this.signaturePadCanvas.nativeElement;

    this.calcCanvasSize();

    this.pad = new SignaturePad(canvas);

    if (this.signature) {
      this.pad.fromDataURL(this.signature);
    }
  }

  getSignature() {
    return this.pad.toDataURL();
  }

  resetSignature() {
    this.signature = null;
    this.pad.clear();
  }

  resetSignatureOptions() {
    this.signatureOptions = (this.signatureOptions || []).map((option) => ({
      ...option,
      value: null,
    }));
  }

  updateSignatureOption(optionIndex: number, value: any) {
    this.signatureOptions = this.signatureOptions.map((option, index) => {
      if (optionIndex === index) {
        return { ...option, value };
      }
      return { ...option };
    });
  }

  @HostListener('window:resize', ['$event'])
  calcCanvasSize() {
    const { clientWidth, clientHeight } =
      this.signaturePadContainer.nativeElement;

    if (!clientWidth || !clientHeight) {
      setTimeout(() => this.calcCanvasSize(), 300);
      return;
    }

    this.signaturePadCanvas.nativeElement.width = clientWidth;
    this.signaturePadCanvas.nativeElement.height = clientHeight;
  }

  trackByFn(index, item) {
    return index;
  }
}
