import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'cypress/types/lodash';
import { get, upperFirst } from 'lodash';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { SubSink } from 'subsink';

import { BillsOrdinationComponent } from '../../../../components/bills-ordination/bills-ordination.component';
import {
  selectAllProperties,
  selectCurrencyProperty,
} from '../../../../core/+state/core.reducer';
import {
  AddBillModalAccommodation,
  AddBillModalAccommodationGuest,
  EstimateAddonPriceRequest,
  IBillsRef,
} from '../../../../models';
import { AddBillModalAddon } from '../../../../models/objects/add-bill-modal-addon';
import { AddBillModalResultsOnClose } from '../../../../models/objects/add-bill-modal-results-on-close';
import { OrdinationProperty } from '../../../../models/objects/ordination-property.model';
import { EditBillsRequest } from '../../../../models/requests/edit-bills.request';
import {
  AddBillModalStoreActions,
  AddBillModalStoreSelectors,
} from '../../../../root-store/add-bill-modal-store';
import { getPayloadFromAddons } from '../../../../root-store/add-bill-modal-store/helpers';
import {
  BillsOrdinationStoreActions,
  BillsOrdinationStoreSelectors,
} from '../../../../root-store/bills-ordination';
import {
  EstimateAddonPriceStoreActions,
  EstimateAddonPriceStoreSelectors,
} from '../../../../root-store/estimate-addon-price-store';
import { RootState } from '../../../../root-store/root-state';
import { AddBillModalAccommodationBillsComponent } from '../add-bill-modal-accommodation-bills/add-bill-modal-accommodation-bills.component';
import { AddBillModalEditingDrawerComponent } from '../add-bill-modal-editing-drawer/add-bill-modal-editing-drawer.component';
import { AddBillModalService } from '../services/add-bill-modal.service';

@Component({
  selector: 'by-add-bill-modal-container',
  templateUrl: './add-bill-modal-container.component.html',
  styleUrls: ['./add-bill-modal-container.component.scss'],
})
export class AddBillModalContainerComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<AddBillModalContainerComponent> =
    inject(NZ_MODAL_DATA);

  @ViewChild(AddBillModalEditingDrawerComponent)
  editingDrawerComponent: AddBillModalEditingDrawerComponent;

  @ViewChild(AddBillModalAccommodationBillsComponent)
  accommodationAddonsComponent: AddBillModalAccommodationBillsComponent;

  modalServiceRef: AddBillModalService = this.nzDataModal.modalServiceRef;

  reservationAccommodations$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectReservationAccommodations),
  );

  referenceDate$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectReferenceDate),
  );

  selectedGuest$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectGuestSelected),
  );

  availableAddons$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectAvailableAddons),
  );

  config$ = this.store.pipe(select(AddBillModalStoreSelectors.selectConfig));

  estimates$ = this.store.pipe(
    select(EstimateAddonPriceStoreSelectors.selectTotalAddons),
  );

  vatQuotes$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectPlaceVatQuotes),
  );

  vatQuotesSettings$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectVatQuotesSettings),
  );

  billsDepartments$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectBillsDepartmentsByVatCode),
  );

  childrenRanges$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectChildrenRanges),
  );

  loading$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectIsLoading),
  );

  creatingLoading$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectIsCreating),
  );

  accommodationsLoading$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectAccommodationsLoading),
  );

  payingCustomerForVariousCategory$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectPayingCustomerForVariousCategory),
  );

  createdBillsId$ = this.store.pipe(
    select(AddBillModalStoreSelectors.selectCreatedBillsId),
  );

  billsOrdinationProperty: OrdinationProperty;

  accommodationBills: IBillsRef[];

  selectedReservationAccommodation: AddBillModalAccommodation;

  selectedGuest: AddBillModalAccommodationGuest;

  currencySymbol: string;

  private somethingIsChanged = false;

  private subs = new SubSink();

  constructor(
    private store: Store<RootState>,
    private actions$: Actions,
    private modalRef: NzModalRef<any, AddBillModalResultsOnClose>,
    private modalService: NzModalService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.subs.add(
      this.selectedGuest$.subscribe((guest) => {
        this.selectedGuest = guest;

        this.loadReservationAccommodationAvailableAddons(
          this.selectedReservationAccommodation?.reservation_accommodation_id,
        );
      }),
    );

    this.subs.add(
      this.store.pipe(select(selectAllProperties)).subscribe((properties) => {
        const allPropertiesIds: number[] = (properties || []).map(
          ({ id }) => id,
        );
        this.loadVatQuotes(get(properties, '[0].country_id', 1));
        this.loadVatQuotesSettings(allPropertiesIds);
        this.loadBillsDepartments(allPropertiesIds);
      }),
    );

    this.subs.add(
      this.store
        .pipe(select(AddBillModalStoreSelectors.selectSomethingIsChanged))
        .subscribe(
          (somethingIsChanged) =>
            (this.somethingIsChanged = somethingIsChanged),
        ),
    );

    this.subs.add(
      this.store
        .pipe(select(AddBillModalStoreSelectors.selectAccommodationBills))
        .subscribe(
          (accommodationBills) =>
            (this.accommodationBills = accommodationBills),
        ),
    );

    this.subs.add(
      this.store
        .pipe(select(BillsOrdinationStoreSelectors.selectProperty))
        .subscribe((billsOrdinationProperty) => {
          this.billsOrdinationProperty = billsOrdinationProperty;
        }),
    );

    this.subs.add(
      this.store
        .pipe(select(selectCurrencyProperty))
        .subscribe((currency) => (this.currencySymbol = currency?.symbol)),
    );

    this.subs.add(
      this.actions$
        .pipe(ofType(BillsOrdinationStoreActions.saveOrdinationSuccess))
        .subscribe(() => {
          const { reservation_id, all_reservation_accommodations_id } =
            this.selectedReservationAccommodation;

          this.accommodationAddonsComponent.checkedMap = {};

          this.loadReservationAccommodationBills(
            reservation_id,
            all_reservation_accommodations_id,
          );

          this.store.dispatch(
            AddBillModalStoreActions.setSomethingIsChanged({
              somethingIsChanged: true,
            }),
          );
        }),
    );

    this.subs.add(
      this.store
        .pipe(
          select(
            AddBillModalStoreSelectors.selectReservationAccommodationSelected,
          ),
        )
        .subscribe((reservationAccommodation) => {
          this.loadReservationAccommodationAvailableAddons(
            reservationAccommodation?.reservation_accommodation_id,
          );
          this.loadReservationAccommodationBills(
            reservationAccommodation?.reservation_id,
            reservationAccommodation?.all_reservation_accommodations_id,
          );
          this.loadPayingCustomerForVariousCategory(
            reservationAccommodation?.reservation_id,
            reservationAccommodation?.reservation_accommodation_id,
          );
          this.loadChildrenRanges(reservationAccommodation?.property_id);
          this.loadBillsOrdinationProperty(
            reservationAccommodation?.property_id,
          );

          this.selectedReservationAccommodation = reservationAccommodation;
        }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(AddBillModalStoreActions.resetState());
    this.store.dispatch(EstimateAddonPriceStoreActions.resetState());
  }

  onCloseModal() {
    const result: AddBillModalResultsOnClose = {
      someChanged: this.somethingIsChanged,
    };
    this.modalServiceRef.modalClose$.next(result);
    this.modalRef.close(result);
  }

  onSelectReservationAccommodation(
    selectedReservationAccommodation: AddBillModalAccommodation,
  ) {
    this.store.dispatch(
      AddBillModalStoreActions.setSelectedReservationAccommodation({
        selectedReservationAccommodation,
      }),
    );
  }

  onSelectGuest(selectedGuest: AddBillModalAccommodationGuest) {
    this.store.dispatch(
      AddBillModalStoreActions.setSelectedGuest({
        selectedGuest,
      }),
    );
  }

  onChangeReferenceDate(referenceDate: Date) {
    this.store.dispatch(
      AddBillModalStoreActions.setReferenceDate({ referenceDate }),
    );

    this.store.dispatch(AddBillModalStoreActions.loadReservationsRequest());
  }

  onEstimateAddon(payload: EstimateAddonPriceRequest) {
    this.store.dispatch(EstimateAddonPriceStoreActions.loadRequest(payload));
  }

  onAddAddon(addon: AddBillModalAddon) {
    //const mergableAddon = findMergableAddon(this.accommodationAddons, addon);
    const mergableAddon = null;

    if (mergableAddon) {
      this.store.dispatch(
        AddBillModalStoreActions.updateAddonRequest({
          addon: getPayloadFromAddons(
            [mergableAddon],
            this.selectedReservationAccommodation,
          ),
          isCreationWithMerge: true,
        }),
      );
    } else {
      this.store.dispatch(
        AddBillModalStoreActions.verifyAddonRequest({ addon }),
      );
    }
  }

  onCreateAddon(addon: AddBillModalAddon) {
    const onSuccess = addon.book_only
      ? null
      : () =>
          this.loadReservationAccommodationAvailableAddons(
            this.selectedReservationAccommodation.reservation_accommodation_id,
          );

    this.store.dispatch(
      AddBillModalStoreActions.createAddonRequest({ addon, onSuccess }),
    );
  }

  onEditAddon(addon: EditBillsRequest) {
    this.editingDrawerComponent.setSaving(true);
    this.store.dispatch(
      AddBillModalStoreActions.updateAddonRequest({
        addon,
        onSuccess: () => {
          this.editingDrawerComponent.setSaving(false);
          this.editingDrawerComponent.close();
        },
      }),
    );
  }

  onDeleteBills(billsIds: number[]) {
    this.store.dispatch(
      AddBillModalStoreActions.deleteBillsRequest({ billsIds }),
    );
  }

  onOpenEditingDrawer(billId: number) {
    this.editingDrawerComponent.open();
    this.editingDrawerComponent.setBillId(billId);
  }

  onCreateOrdination(bills: IBillsRef[]) {
    const { tableau_label, reservation_accommodation_id } =
      this.selectedReservationAccommodation;

    this.modalService.create<
      BillsOrdinationComponent,
      Partial<BillsOrdinationComponent>
    >({
      nzContent: BillsOrdinationComponent,
      nzData: {
        property: this.billsOrdinationProperty,
        currencySymbol: this.currencySymbol,
        accommodation: {
          tableau_label,
          reservation_accommodation_id,
        },
        bills: bills.map((bill) => {
          const priceWithDiscount = +bill.total_price - +bill.discounted_price;

          return {
            id: bill.bill_id,
            label: bill.name,
            qty: bill.qty,
            old_total_price:
              priceWithDiscount !== +bill.total_price
                ? +bill.total_price
                : null,
            total_price: priceWithDiscount,
          };
        }),
      },
      nzWidth: 600,
      nzFooter: [
        {
          label: upperFirst(this.translate.instant('sign')),
          type: 'primary',
          onClick: (component) => component.onSign(),
        },
        {
          label: upperFirst(this.translate.instant('print')),
          type: 'primary',
          loading: (component) => component.loading === 'printing',
          onClick: (component) => component.onPrint(),
        },
        {
          label: upperFirst(this.translate.instant('save')),
          type: 'primary',
          disabled: (component) => !component.signatureBase64,
          loading: (component) => component.loading === 'saving',
          onClick: (component) => component.onSave(),
        },
      ],
    });
  }

  get contentWidth() {
    return document.querySelector('.add-bill-modal__content')?.clientWidth;
  }

  private loadReservationAccommodationAvailableAddons(
    reservationAccommodationId: number,
  ) {
    if (!reservationAccommodationId || !this.selectedGuest) {
      return;
    }

    this.store.dispatch(
      AddBillModalStoreActions.loadAvailableAddonsRequest({
        reservationAccommodationId,
        customerId: this.selectedGuest.id,
      }),
    );
  }

  private loadReservationAccommodationBills(
    reservationId: number,
    reservationAccommodationsId: number[],
  ) {
    if (!reservationId || !reservationAccommodationsId) {
      return;
    }

    this.store.dispatch(
      AddBillModalStoreActions.loadAccommodationBillsRequest({
        reservationId,
        reservationAccommodationsId,
      }),
    );
  }

  private loadPayingCustomerForVariousCategory(
    reservationId: number,
    reservationAccommodationId: number,
  ) {
    if (!reservationId || !reservationAccommodationId) {
      return;
    }

    this.store.dispatch(
      AddBillModalStoreActions.loadPayingCustomerForVariousCategoryRequest({
        reservationId,
        reservationAccommodationId,
      }),
    );
  }

  private loadVatQuotes(place_id: number) {
    this.store.dispatch(
      AddBillModalStoreActions.loadPlaceVatQuotesRequest({
        place_id,
        pagination: false,
      }),
    );
  }

  private loadVatQuotesSettings(propertiesIds: number[]) {
    this.store.dispatch(
      AddBillModalStoreActions.loadVatQuotesSettingsRequest({ propertiesIds }),
    );
  }

  private loadBillsDepartments(propertiesIds: number[]) {
    this.store.dispatch(
      AddBillModalStoreActions.loadBillsDepartmentsRequest({ propertiesIds }),
    );
  }

  private loadChildrenRanges(propertyId: number) {
    if (!propertyId) {
      return;
    }

    this.store.dispatch(
      AddBillModalStoreActions.loadChildrenRangesRequest({
        propertiesIds: [propertyId],
      }),
    );
  }

  private loadBillsOrdinationProperty(propery_id: number) {
    if (!propery_id) {
      return;
    }

    this.store.dispatch(
      BillsOrdinationStoreActions.loadPropertyRequest({
        propery_id,
      }),
    );
  }
}
