import { OrdinationProperty } from '../../models/objects/ordination-property.model';

export interface State {
  property: OrdinationProperty;
  isLoading?: boolean;
  error?: any;
  templateHtml: string;
}

export const initialState: State = {
  property: null,
  isLoading: false,
  error: null,
  templateHtml: null,
};
