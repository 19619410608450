import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
@Directive({
  selector: '[byAppShellMenuResponsive]',
})
export class AppShellMenuDirective {
  @Output()
  public appShellMenuResponsiveEvent: EventEmitter<
    'desktop' | 'tablet' | 'mobile'
  > = new EventEmitter<'desktop' | 'tablet' | 'mobile'>();
  constructor() {}

  @HostListener('window:resize', ['$event'])
  public onResize(event: { target: { innerWidth: any } }) {
    const { innerWidth } = event.target;
    if (innerWidth <= 765) {
      this.appShellMenuResponsiveEvent.emit('mobile');
    }
    if (innerWidth > 765 && innerWidth <= 1024) {
      this.appShellMenuResponsiveEvent.emit('tablet');
    }
    if (innerWidth > 1024) {
      this.appShellMenuResponsiveEvent.emit('desktop');
    }
  }
}
