import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { showQuantityAndRateConditionMapConstant } from '../../../../helpers/bills-helpers';
import {
  AddBillModalAccommodation,
  ChildrenRange,
  EstimateAddonPriceRequest,
  PlaceVatQuote,
} from '../../../../models';
import { AddBillModalAddon } from '../../../../models/objects/add-bill-modal-addon';
import { AddBillModalFormComponent } from '../add-bill-modal-form/add-bill-modal-form.component';

@Component({
  selector: 'by-add-bill-modal-available-addon',
  templateUrl: './add-bill-modal-available-addon.component.html',
  styleUrls: ['./add-bill-modal-available-addon.component.scss'],
})
export class AddBillModalAvailableAddonComponent implements OnInit {
  @Input() addon: AddBillModalAddon;

  @Input() currencySymbol: string;

  @Input() estimate: { total: number; unit_price: number; quantity: number };

  @Input() selectedReservationAccommodation: AddBillModalAccommodation;

  @Input() vatQuotes: PlaceVatQuote[] = [];

  @Input() childrenRanges: ChildrenRange[] = [];

  @Input() creatingLoading: { [addonId: number]: boolean };

  @Output() expandStatusChange = new EventEmitter<{
    status: boolean;
    addon_id: number;
  }>();

  @Output() estimateAddon = new EventEmitter<EstimateAddonPriceRequest>();

  @Output() addAddon = new EventEmitter<AddBillModalAddon>();

  @ViewChild(AddBillModalFormComponent)
  formComponent: AddBillModalFormComponent;

  expanded = false;

  showQuantityAndRateConditionMapConstant = showQuantityAndRateConditionMapConstant;

  constructor() {}

  ngOnInit() {}

  onAddAddon() {
    this.addAddon.emit(this.formComponent.value);
  }

  onOpenStatusChange(isOpen: boolean) {
    if (isOpen) {
      this.estimateAddon.emit(this.formComponent.getEstimatePayload());
    }
  }
}
