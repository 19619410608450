<ng-container *ngIf="(allGroups$ | async)?.length > 1; else propertySelect">
  <span class="by-mr-2">{{ 'select_group' | translate }}</span>
  <nz-select
    nzSize="small"
    style="min-width: 72%;"
    [(ngModel)]="groupId"
    [nzDropdownMatchSelectWidth]="false"
  >
    <nz-option
      *ngFor="let group of allGroups$ | async"
      [nzValue]="group.id"
      [nzLabel]="group.name"
    >
    </nz-option>
  </nz-select>
</ng-container>

<ng-template #propertySelect>
  <ng-container *ngIf="(allProperties$ | async)?.length > 1">
    <span class="by-mr-2">{{ 'select_property' | translate }}</span>
    <nz-select
      nzSize="small"
      style="min-width: 72%;"
      [nzDropdownMatchSelectWidth]="false"
      [(ngModel)]="propertyId"
    >
      <nz-option
        *ngFor="let property of allProperties$ | async"
        [nzValue]="property.id"
        [nzLabel]="property.name"
      >
      </nz-option>
    </nz-select>
  </ng-container>
</ng-template>
