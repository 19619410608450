import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IResponse } from '../models/response.model';

@Injectable({
  providedIn: 'root',
})
export class DiscountTypesService {
  constructor(private _http: HttpClient) {}

  public load(): Observable<IResponse> {
    return this._http.get('general/discounts_types') as Observable<IResponse>;
  }
}
