<div class="add-bill-modal__date" *ngIf="!config?.noDateSelection">
  <button
    nz-button
    nzSize="small"
    nzType="primary"
    nz-popover
    [nzPopoverContent]="calendar"
    [(nzPopoverVisible)]="calendarIsOpen"
    nzPopoverTrigger="click"
    nzPopoverPlacement="rightBottom"
  >
    <i class="fal fa-calendar-alt"></i>
  </button>
  <button nz-button nzSize="small" nzType="primary" (click)="onToday()">
    {{ 'today' | translate | upperfirst }}
  </button>
  <button
    nz-button
    class="ant-btn-icon-only"
    nzSize="small"
    nzType="primary"
    (click)="onPrevDay()"
  >
    <i class="far fa-angle-left"></i>
  </button>
  <button
    nz-button
    class="ant-btn-icon-only"
    nzSize="small"
    nzType="primary"
    (click)="onNextDay()"
  >
    <i class="far fa-angle-right"></i>
  </button>
  <strong class="by-fs-14">
    {{ selectedDate | date : 'EEEE' | lowercase | translate | upperfirst }},
    {{ selectedDate | date : 'dd' }}
    {{ selectedDate | date : 'MMMM' | lowercase | translate | upperfirst }}
    {{ selectedDate | date : 'yyyy' }}
  </strong>
</div>

<ng-template #calendar>
  <div class="calendar-popover">
    <nz-calendar
      [nzFullscreen]="false"
      [ngModel]="selectedDate"
      (ngModelChange)="updateReferenceDate($event)"
    ></nz-calendar>
  </div>
</ng-template>
